import React, { FC, useState } from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Helmet } from 'react-helmet';
import { Button, Stack, Text, TextAndButtonGroup } from 'shared-components';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import { OwnerFields } from '@components/Apply/OwnersMoreDetailsAboutYou/OwnerFields';
import { getNumberOfOwners } from '@components/Apply/OwnersMoreDetailsAboutYou/getNumberOfOwners';
import { ApplyWizardSteps, STEP_BUSINESS_OWNERS } from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

type FurtherBusinessDetailsProps = {
  loanType: string;
  handleSubmit: () => void;
  invalid: boolean;
  ownersCountStart?: number;
  saving?: boolean;
};

const OwnersMoreDetailsAboutYouWrapper2: FC<FurtherBusinessDetailsProps> = ({
  handleSubmit,
  invalid,
  ownersCountStart,
  saving: savingOnSubmit,
}) => {
  const { saving } = useApplyWizardContext();
  const form = useForm();
  const [ownersCount, setOwnersCount] = useState<number>(ownersCountStart ?? 1);
  const dispatch = useDispatch();

  return (
    <div>
      <Helmet>
        <title>More Details About You</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="More Details About You" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <Stack gapPx="50px" justifyItems="center" templateColumns="1fr" fullWidth>
                  {Array.from({ length: ownersCount }, (_, index) => index + 1).map((ownerNr) => (
                    <div>
                      {ownerNr > 1 && (
                        <Stack
                          className="mb-[10px]"
                          flow="column"
                          gapPx="50px"
                          justifyItems="center"
                          templateColumns="1fr"
                          justifyContent="space-between"
                          fullWidth
                        >
                          <div className="border-charcoal w-full">
                            <Text variant="subheadline-sm">Owner #{ownerNr}</Text>
                            <span></span>
                          </div>
                          {ownerNr === ownersCount && (
                            <TextAndButtonGroup
                              buttonContent="-"
                              text="Remove"
                              buttonProps={{
                                type: 'button',
                              }}
                              onClick={() => {
                                dispatch(change('application', `owner_${ownersCount}_id`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_title`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_ownership`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_ssn_masked`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_dob`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_dob_day`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_dob_month`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_dob_year`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_ssn`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_street`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_city`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_zip`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_suite`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_state`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_first`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_last`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_mobile`, undefined));
                                dispatch(change('application', `owner_${ownersCount}_email`, undefined));
                                setOwnersCount(ownersCount - 1);
                              }}
                            />
                          )}
                        </Stack>
                      )}
                      <OwnerFields withAllFields={ownerNr > 1} ownerNr={ownerNr} />
                    </div>
                  ))}
                </Stack>
                <TextAndButtonGroup
                  buttonContent="+"
                  text="Add additional owners?"
                  onClick={() => setOwnersCount(ownersCount + 1)}
                  buttonProps={{
                    type: 'button',
                  }}
                  stackProps={{
                    className: 'max-w-[368px]',
                  }}
                />
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={invalid}
                  saving={saving || savingOnSubmit}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const OwnersMoreDetailsAboutYouWrapper1 = reduxForm<FurtherBusinessDetailsProps, FurtherBusinessDetailsProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_BUSINESS_OWNERS].zodSchema),
})(OwnersMoreDetailsAboutYouWrapper2);

const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    ownersCountStart: getNumberOfOwners(state),
  };
};

export const OwnersMoreDetailsAboutYou = withOnSubmit(
  connect(mapStateToProps)(OwnersMoreDetailsAboutYouWrapper1),
  STEP_BUSINESS_OWNERS
);
