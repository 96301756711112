import { useState, useEffect, useCallback } from 'react';
import useAxios, { RefetchFunction, RefetchOptions, UseAxios, UseAxiosResult } from 'axios-hooks';
import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenSource } from 'axios';

export type RefetchSimplified = (
  config?: AxiosRequestConfig<any> | string,
  options?: RefetchOptions
) => AxiosPromise<any>;

/**
 * Custom hook to handle Axios requests with automatic cancellation.
 */
export const useCancelableAxios = (
  axiosConfig: AxiosRequestConfig<any> | string,
  options = { manual: true }
): UseAxiosResult => {
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

  const [{ data, loading, error }, execute] = useAxios(axiosConfig, { ...options, manual: true });

  const refetch: RefetchFunction<any, any> = useCallback<RefetchFunction<any, any>>(
    (params): AxiosPromise<any> => {
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);
      try {
        return execute({
          ...(params instanceof String ? {} : (params as AxiosRequestConfig<any>)),
          cancelToken: source.token,
        }).catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request was cancelled', error.message);
          } else {
            console.error('Axios error happened:', error);
          }
          return Promise.resolve({} as any);
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request was cancelled', error.message);
        } else {
          console.error('Axios error happened:', error);
        }
        return Promise.resolve({} as any);
      }
    },
    [execute]
  );

  useEffect(() => {
    // Cleanup function that cancels the request when component unmounts or refetch is called again
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Request cancelled due to component unmount or refetch.');
      }
    };
  }, [cancelTokenSource]);

  return [{ data, loading, error }, refetch, () => {}];
};
