import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Business from './Business';
import devicer from '../../../../../external-libs/devicer.min.js';
import { platformName } from '../../../utils/platformBasedInfo';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { formValueSelector } from 'redux-form';
import { currencyToDecimal } from '../../../utils/currencyToDecimal';

const BusinessContainer = ({
  match,
  nextRoute,
  user,
  dataLayerPush,
  partnerCustomFields,
  callApi,
  vendor_name,
  loanType,
  setSaving,
  loan_id,
  ...other
}) => {
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();

  useEffect(() => {
    if (dealUpdateResponse) {
      nextRoute();
    }
  }, [dealUpdateResponse]);

  useEffect(() => {
    if (process.env.STORYBOOK_TEST) return;

    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        endpoint: process.env.SOCURE_URL,
        publicKey: SOCURE_PUBLIC_KEY,
        endpoint: SOCURE_URL,
        userConsent: true,
        context: 'transaction',
      };
      devicer.run(deviceFPOptions, function (response) {});
    }
  }, []);

  const saveBusiness = async (form) => {
    const { property } = match.params;

    // do not send home based property to sugar, only used to skip address
    if (property === 'based') {
      setSaving(false);
      nextRoute();
      return;
    }

    const post = {
      property: property,
      [property]: form[`business_${property}`],
      type: user.type,
    };

    if (property === 'accountId' || property === 'account_id') {
      if (!post.business) {
        post.business = {};
      }
      post.business.id = form[`business_${property}`];
    }
    if (property === 'years') {
      if (!post.business) {
        post.business = {};
      }
      if (!post.loanRequest) {
        post.loanRequest = {};
      }
      post.loanRequest.id = loan_id;
      post.business.businessYears = form[`business_${property}`];
    }
    if (property === 'revenue') {
      if (!post.business) {
        post.business = {};
      }
      post.business.annualRevenue = currencyToDecimal(form[`business_${property}`]);
    }
    if (property === 'phone') {
      if (!post.business) {
        post.business = {};
      }
      post.business.phone = form[`business_${property}`];
    }
    if (property === 'ein') {
      if (!post.business) {
        post.business = {};
      }
      post.business.taxIdNumber = form[`business_${property}`];
    }
    if (property === 'website') {
      if (!post.business) {
        post.business = {};
      }
      post.business.website = form[`business_${property}`];
    }
    if (property === 'naics') {
      if (!post.business) {
        post.business = {};
      }
      post.business.naicsCode = form[`business_${property}`]?.value;
    }
    if (property === 'structure') {
      if (!post.business) {
        post.business = {};
      }
      post.business.entityType = form[`business_${property}`];
    }
    if (property === 'type') {
      if (!post.business) {
        post.business = {};
      }
      post.business.entityType = form[`business_${property}`];
    }

    // remove any non-numeric data from years input
    if (property === 'years') {
      dataLayerPush('time-in-business', {
        account_id: post.account_id,
        time_in_business: form[`business_years`],
      });
    }

    if (property === 'revenue') {
      dataLayerPush('user-reported-revenue', {
        account_id: post.account_id,
        time_in_business: form[`business_revenue`],
      });
    }

    if (property === 'address') {
      if (!post.business) {
        post.business = {};
      }
      post.business.address = {
        address1: form.business_street || null,
        address2: form.business_suite || null,
        city: form.business_city || null,
        state: form.business_state || null,
        postalCode: form.business_zip || null,
      };
      if (!post.source) {
        post.source = {};
      }
      post.source.customFields = partnerCustomFields;
    }

    await updateOpportunity({ data: post });
    analytics.track(`Apply Form - Business ${property}`, post);
  };

  return (
    <>
      {dealUpdateError && (
        <Alert severity="error">
          <AlertTitle>Failed to save</AlertTitle>
          <DataError msg="Unfortunately, we couldn't save your update." error={dealUpdateError} />
        </Alert>
      )}
      <Business
        onSubmit={saveBusiness}
        loanType={loanType}
        {...{ match, nextRoute, user, dataLayerPush, partnerCustomFields, callApi, vendor_name, ...other }}
        saving={dealUpdateLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    user: state.user,
    loan_id: formValueSelector('application')(state, 'loan_id'),
    partnerCustomFields: state.partnerCustomFields,
    vendor_name: state.brand?.data?.vendor_name,
  };
};

const BusinessWrapped = connect(mapStateToProps)(withRouter(BusinessContainer));

export default BusinessWrapped;
