import './Footer.scss';
import React from 'react';
import { connect } from 'react-redux';
import { contactEmail } from '../../utils/platformBasedInfo.js';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';

export const FOOTER_HEIGHT = 65;
const FOOTER_LOGO_HEIGHT = 64;
let Footer = (props) => {
  return null; // No footer in new green design
  const copyrightDate = new Date().getFullYear();
  const privacyLink = `${props.vendor_url}/privacy-policy`;
  const termsLink = `${props.vendor_url}/terms-of-use`;
  const userLoggedIn = props.user && props.user?.email;
  return (
    <footer className={`bg-white`} style={{ minHeight: `${FOOTER_HEIGHT}px` }}>
      <div
        className={`container md:px-[20px] px-[10px] py-[20px] h-full md:py-0`}
        style={{ minHeight: `${FOOTER_HEIGHT}px` }}
      >
        {/* <div className="container flex flex-col items-center justify-between gap-4 py-10 md:h-24 md:flex-row md:py-0">
        <div className="flex flex-col items-center gap-4 px-8 md:flex-row md:gap-2 md:px-0">
          <p className="text-sm leading-loose text-center md:text-left">
            {!userLoggedIn && <>Copyright {copyrightDate}. All rights reserved.</>}
            <a
              href={privacyLink}
              target="_blank"
              rel="noopener noreferrer"
              className={!userLoggedIn ? 'bl hover:underline' : 'hover:underline'}
            >
              Privacy Policy
            </a>
            <a href={termsLink} target="_blank" rel="noopener noreferrer" className="bl hover:underline">
              Terms of Use
            </a>
            {userLoggedIn && (
              <span className="bl">
                User:&nbsp; <strong>{props.user?.email}</strong>&nbsp; Id:&nbsp;<strong>{props.user?.id}</strong>
                &nbsp;
              </span>
            )}
          </p>
        </div>
      </div> */}
        <div className="flex flex-col flex-wrap justify-center space-between align-center md:flex-row">
          <div className={`h-full md:mr-auto md:ml-0 mx-auto object-center my-auto max-w-[179px]`}>
            <img
              src="https://s3.amazonaws.com/media.tento.co/apply/img/bankrate.svg"
              className={`h-full md:mr-auto md:ml-0 mx-auto object-center`}
              style={{ maxHeight: `48px`, height: `48px` }}
            />
          </div>
          <div className={`h-full mx-auto my-auto max-w-[256px]`}>
            <img
              src="https://s3.amazonaws.com/media.tento.co/apply/img/nerdwallet.svg"
              className={`h-full mx-auto`}
              style={{ maxHeight: `48px`, height: `48px` }}
            />
          </div>
          <div className={`h-full md:ml-auto md:mr-0 mx-auto my-auto max-w-[129px]`}>
            <img
              src="https://s3.amazonaws.com/media.tento.co/apply/img/googlereviews.png"
              className={`h-full max-h-[${FOOTER_LOGO_HEIGHT}px] md:ml-auto md:mr-0 sm:mx-auto`}
              style={{ maxHeight: `${FOOTER_LOGO_HEIGHT}px` }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer = connect((state) => {
  return {
    config: state.config,
    vendor_url: state.brand?.data?.vendor_url,
    images: state.brand?.data?.media,
    user: state.user,
  };
})(Footer);

export default Footer;
