import { ArrowLeft as ArrowLeftIcon } from 'shared-components';
import { ArrowRight as ArrowRightIcon } from 'shared-components';
import { ArrowDown as ArrowDownIcon } from 'shared-components';
import { ArrowUp as ArrowUpIcon } from 'shared-components';
import { Build as BuildIcon } from 'shared-components';
import { Complete as CompleteIcon } from 'shared-components';
import { Conduit as ConduitIcon } from 'shared-components';
import { Embed as EmbedIcon } from 'shared-components';
import { Growth as GrowthIcon } from 'shared-components';
import { Merchant as MerchantIcon } from 'shared-components';
import { Momentum as MomentumIcon } from 'shared-components';
import { Shift as ShiftIcon } from 'shared-components';
import { CheckboxChecked as CheckboxCheckedIcon } from 'shared-components';
import { App as Page } from '../../App';

export default {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Icons',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
};

export const ArrowLeft = () => <ArrowLeftIcon />;
export const ArrowRight = () => <ArrowRightIcon />;
export const ArrowUp = () => <ArrowUpIcon />;
export const ArrowDown = () => <ArrowDownIcon />;
export const Build = () => <BuildIcon />;
export const Complete = () => <CompleteIcon />;
export const Conduit = () => <ConduitIcon />;
export const Embed = () => <EmbedIcon />;
export const Growth = () => <GrowthIcon />;
export const Merchant = () => <MerchantIcon />;
export const Momentum = () => <MomentumIcon />;
export const Shift = () => <ShiftIcon />;
export const CheckboxChecked = () => <CheckboxCheckedIcon />;
