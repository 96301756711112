import './Decision.scss';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Process from './Process';
import PersonalLoanWithEvenFinancial from '../../../components/_shared/EvenFinancial/PersonalLoan';
import OffersOfPersonalLoanByEvenFinancial from '../../../components/_shared/EvenFinancial/Offers';
import DeclineForPersonalLoanByEvenFinancial from '../../../components/_shared/EvenFinancial/Decline';
import Frozen from './Frozen';
import Unable from './Unable';
import Letter from './Letter';

let Decision = (props) => {
  switch (props.match.params.property) {
    // Tento Loan
    case 'process':
      return <Process {...props} />;
    case 'frozen':
      return <Frozen {...props} />;
    case 'unable':
      return <Unable {...props} />;
    case 'denial-letter':
      return <Letter {...props} />;
    // Personal Loan (Even Financial)
    case 'personal':
      return <PersonalLoanWithEvenFinancial {...props} />;
    case 'offers': // personal-offers
      return <OffersOfPersonalLoanByEvenFinancial {...props} />;
    case 'decline': // personal-decline
      return <DeclineForPersonalLoanByEvenFinancial {...props} />;
    default:
      return null;
  }
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    fico: selector(state, 'owner_1_fico'),
    theme: state.theme,
  };
};

const DecisionForm = connect(mapStateToProps)(withRouter(Decision));
export default DecisionForm;
