import React from 'react';
import { Helmet } from 'react-helmet';
import { Button, Text } from 'shared-components';

const Loan = (props) => {
  return (
    <div className="app-section slide">
      <Helmet>
        <title>Select Loan Type - Your Loan Application</title>
      </Helmet>
      <Text variant="headline-lg">What type of loan are you looking for?</Text>
      <Button variant="primary-white" data-testid="equipment-loan-btn" onClick={props.setEq}>
        Equipment Loans
      </Button>

      <Button variant="primary-white" onClick={props.setWc}>
        Working Capital
      </Button>
    </div>
  );
};

export default Loan;
