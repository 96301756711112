import StorybookReduxWrapper from '../../../../../.storybook/storybook-utils/StorybookWrapper';
import { App as Page } from '../../../App';
import { getTemp } from '../../../api/mocks/temp';

const meta = {
  title: '001-Apply Frontend/Entire App/steps/Proceed',
  component: Page,
  argTypes: {},
  decorators: [
    StorybookReduxWrapper({
      routerProps: { initialEntries: ['/app/contact-soon'] },
      loanType: 'WC',

      initialReduxState: {
        temp: {
          ...getTemp(),
          offer: {
            ...getTemp().offer,
            loanType: 'EQ',
          },
          signingResult: 'something_else_than_signing_complete',
          haveStatements: false,
        },
        form: {
          application: {
            values: {
              owner_1_first: 'Mateusz',
              loan_stage: 'ApplyStarted',
              business_ein: '123456789',
              loan_amount: 100000,
              loan_terms: 60,
              loan_type: ['EQ'],
              business_years: 2,
              business_name: 'Test Capital LLC',
              business_revenue: '123456789',
            },
            registeredFields: {
              business_years: {
                name: 'business_years',
                type: 'Field',
                count: 2,
              },
            },
            fields: {
              business_years: {
                visited: true,
                touched: true,
              },
            },
            anyTouched: true,
          },
        },
      },
      user: {
        user: {
          loggedIn: true,
          id: '0037600000Pr6SdAAJ',
          accountId: '0017600000XFslZAAT',
          token:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDE3NjAwMDAwWEZzbFpBQVQiLCJpYXQiOjE2ODI5NzIwMjAsImV4cCI6MTY4MzE0NDgyMH0.A1Ckqe6kT3D1iLLbYosbmZieD--LYcYZr9t1UvNpB94',
          expires: 33303137868000,
        },
        owners: [
          { mobile: '917-517-7576', last: 'Akdbn', id: '0037600000Pr6SdAAJ', first: 'Aksdb', email: 'test@test.com' },
        ],
        loan: false,
        error: false,
        business: {
          vendor_url: '',
          lead_source: '',
          business_years: 8,
          business_suite: '',
          business_revenue: '0',
          business_phone: '2345654323',
          business_name: 'Akdbs',
          business_id: '0017600000XFslZAAT',
        },
      },
    }),
  ],
};

export default meta;

export const ContactSoon = {
  args: {},
};
