import './Modals.scss';
import React from 'react';
import { Modal, ModalManager, Effect } from 'react-dynamic-modal';
import { currency } from '_fields/normalizers';
import { PaymentSelected } from '@components/_shared/Payment';
import { Outro } from '../../Apply/Done/Fragments';
import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import ContinueButton from '@components/_shared/Continue/Button';
import { Button, Checkbox } from 'shared-components';
import { Text } from 'shared-components';

export const BasicModal = (props) => {
  const { title, body, effect, onRequestClose } = props;
  return (
    <Modal
      style={{
        overlay: {
          zIndex: '99',
          overflow: 'auto',
        },
        content: {
          top: '40vh',
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          border: 'none',
          margin: '0 auto',
          marginTop: '35px',
          padding: '32px',
          width: '100%',
        },
      }}
      onRequestClose={onRequestClose}
      effect={effect}
    >
      <Text variant="subheadline-lg">{title}</Text>
      <div>{body}</div>
    </Modal>
  );
};

export const AccountBody = (props) => {
  return (
    <div className="accountList grid align-center justify-center items-center">
      {props.accounts.map((account, idx) => (
        <Checkbox name={`account-${idx}`} key={account.id} onClick={() => props.itemClick(account.id)}>
          {account.name} (ending {account.mask})
        </Checkbox>
      ))}
    </div>
  );
};

export const DoneModal = (props) => {
  const { logout } = useAuth0();
  return (
    <Modal
      onRequestClose={() => false}
      effect={Effect.Fall}
      style={{
        overlay: {
          top: '64px',
          zIndex: '99',
          backgroundColor: '#FFFFFF',
          overflow: 'auto',
        },
        content: {
          boxShadow: 'none',
          background: 'transparent',
          border: 'none',
          margin: '0 auto',
          marginTop: '35px',
          width: '100%',
        },
      }}
    >
      <div className={`done-modal inverse ${props.theme?.name}`}>
        <Stack gap={ELEMENTS_GAP}>
          <Text variant="headline-lg">Congratulations !!</Text>
          <h5 data-testid="loading-title">We are checking a few things. Hold tight!</h5>
          <hr className="m-auto divider" />
          <p className="darkText bold">We have received your equipment financing application</p>
          <p>{props.message}</p>
          <Outro {...props} />
          <Button
            variant="primary-green"
            data-testid="done-modal-logout"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Logout
          </Button>
        </Stack>
      </div>
    </Modal>
  );
};

export const ProcessingModal = (props) => {
  const { title, msg, images } = props;
  const message = msg ? msg : "This shouldn't take long. We appreciate your patience.";

  return (
    <Modal
      effect={Effect.Fall}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: { backgroundColor: '#FFFFFF' },
        content: {
          boxShadow: 'none',
          background: 'transparent',
          border: 'none',
          margin: '131px auto',
          width: '100%',
          overflow: 'none',
        },
      }}
    >
      <div className="processing-modal">
        <Stack gap={ELEMENTS_GAP}>
          <img src={images.icon_url} alt="icon" className="mx-auto icon" />

          <h1 className="my-0 title">{title}</h1>
          <h5>{message}</h5>
        </Stack>

        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Modal>
  );
};

export const ConfirmModal = (props) => {
  return (
    <Modal
      effect={Effect.Fall}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: { backgroundColor: 'white' },
        content: {
          boxShadow: 'none',
          background: 'transparent',
          border: 'none',
          margin: '35px auto',
          width: '100%',
        },
      }}
    >
      <div className="confirm-modal">
        <Stack gap={ELEMENTS_GAP}>
          {props.media?.icon_white_url && <img src={props.media?.icon_white_url} alt="icon" className="icon-white" />}

          <h1 className="title">
            You're moments
            <br />
            away from funding!
          </h1>

          <h4 className="blue tiny">Please confirm your loan selection:</h4>

          <div className="loanOverview group">
            <div className="loanAmountBox">
              <p>Loan amount</p>
              <p className="big">{currency(props.amount)}</p>
            </div>
            <div className="termLengthBox">
              <p>Term length</p>
              <p className="big">{props.terms} months</p>
            </div>
          </div>
          <PaymentSelected payment={props.payment} interest={props.interest} offer={props.offer} />

          <div className="wrap">
            <ContinueButton data-testid="terms_modal_continue" onClick={props.confirmTerms} />
          </div>
          <div className="closeConfirmModal" onClick={ModalManager.close}>{`< Go back and edit`}</div>
        </Stack>
      </div>
    </Modal>
  );
};
