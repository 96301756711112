import React from 'react';
import BackArrowIcon from '_icons/BackArrowIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const BackArrow = ({ hide = false, showText = false, onClick, color }) => {
  const flags = useFlags();
  if (hide) return;

  return flags.applyShowStepNavigationOnTop ? null : (
    <div onClick={onClick} className="back-arrow">
      <BackArrowIcon fill={color} />
      {showText && <span>Back</span>}
    </div>
  );
};
