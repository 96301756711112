import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from '@store/reducers';
import React, { ComponentType, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

const ensureLoggedIn = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const mapStateToProps = (state: RootState) => {
    return {
      user: state.user,
    };
  };

  const connector = connect(mapStateToProps);
  type PropsFromRedux = ConnectedProps<typeof connector>;
  interface EnsureLoggedInProps extends PropsFromRedux {
    WrappedComponent: ComponentType<any>;
  }

  const EnsureLoggedIn: React.FC<EnsureLoggedInProps> = (props) => {
    const { user, ...rest } = props;
    const auth0 = useAuth0();

    useEffect(() => {
      if (!user.loggedIn) {
        auth0.loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
    }, [user, auth0]);

    return user.loggedIn ? <WrappedComponent {...(rest as P)} /> : null;
  };

  return connector(EnsureLoggedIn);
};

export default ensureLoggedIn;
