import './Equipment.scss';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { required } from '_fields/validation';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from 'shared-components';

let Equipment = (props) => {
  const { handleSubmit, invalid, saving } = props;
  const form = useForm();

  return (
    <div className="app-section">
      <Helmet>
        <title>Type of Equipment - Your Loan Application</title>
      </Helmet>

      <Stack gapVariant="head-section-to-content" className="decision">
        <FormPageTopPart headingText="What type of equipment do you need financing for?" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant="content-to-submit-button">
              <Stack gapVariant="input-to-input" justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  name="equipment_description"
                  component={newThemeInput}
                  type="text"
                  validate={[required]}
                  placeholder="Enter equipment name"
                  labelText="Equipment name"
                  labelClassName="infoFormCustomLabel"
                  data-testid="equipment_description"
                  className="equipment-description"
                />
              </Stack>

              <ContinueButton invalid={invalid} saving={saving} data-testid="equipment_continue" />
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

Equipment = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Equipment);

export default Equipment;
