import { useGetApplicationIds } from './useGetApplicationIds';
import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';
import { UseAxiosResult } from 'axios-hooks';

export const getDecisionBusiness = (): UseAxiosResult => {
  const extraIds = useGetApplicationIds();

  return useAxios(
    {
      url: `${backendBaseUrl}/v1/deals/${extraIds.loan_id}/decision-business`,
      withCredentials: true,
      method: 'GET',
    },
    { manual: true }
  );
};
