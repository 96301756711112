import React, { useEffect, ComponentType } from 'react';
import devicer from '../../../../../external-libs/devicer.min';
import { platformName } from '../../../utils/platformBasedInfo';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import {
  ContactFormattedResponse,
  DecisionBusiness,
  DecisionPersonal,
  EntireDealFormattedResponse,
} from '../../../types';
import { connect, useDispatch } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { RootState } from '@store/reducers';
import { MAX_SANE_NUMBER_OF_OWNERS } from '@components/Apply/OwnersMoreDetailsAboutYou/getNumberOfOwners';
import { Dispatch } from 'redux';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { sendPersonalLoanForm } from '../../../api/sendPersonalLoanForm';
import { LoanOffer, setTemp, TempType } from '@store/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';

const appSelector = formValueSelector('application');

const enrichOwnersWithIds = (state: RootState) => (dispatch: Dispatch, contacts: ContactFormattedResponse[]) => {
  const maxOwners = MAX_SANE_NUMBER_OF_OWNERS;
  for (let i = 1; i <= maxOwners; i++) {
    if (
      appSelector(state, `owner_${i}_id`) ||
      appSelector(state, `owner_${i}_street`) ||
      appSelector(state, `owner_${i}_suite`) ||
      appSelector(state, `owner_${i}_city`) ||
      appSelector(state, `owner_${i}_state`) ||
      appSelector(state, `owner_${i}_zip`) ||
      appSelector(state, `owner_${i}_first`) ||
      appSelector(state, `owner_${i}_last`) ||
      appSelector(state, `owner_${i}_ssn`) ||
      appSelector(state, `owner_${i}_mobile`) ||
      appSelector(state, `owner_${i}_dob_day`) ||
      appSelector(state, `owner_${i}_dob_month`) ||
      appSelector(state, `owner_${i}_dob_year`) ||
      appSelector(state, `owner_${i}_title`) ||
      appSelector(state, `owner_${i}_ownership`) ||
      appSelector(state, `owner_${i}_email`)
    ) {
      // Owner [i] exists, so we can enrich it with ID if it exists in backend response.
      // Matching by email.
      const owner = contacts.find((c) => c.email === appSelector(state, `owner_${i}_email`));
      if (owner && owner.id) {
        dispatch(change('application', `owner_${i}_id`, owner.id));
      }
    }
  }
};

interface WithOnPersonalLoanSubmitProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

type ExpectedProps = {
  partnerCustomFields?: any;
  loan_id: string;
  loan_type: string;
  loan_amount: string;
  type_of_statements: 'email' | 'upload' | 'plaid';
  enrichOwners: ReturnType<typeof enrichOwnersWithIds>;
};

const withOnPersonalLoanSubmit = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const HOC: React.FC<Omit<P, keyof WithOnPersonalLoanSubmitProps> & ExpectedProps> = (props) => {
    const history = useHistory();
    const [{ data: sendFormResponse, loading: sendFormLoading, error: sendFormError }, sendForm] = sendPersonalLoanForm(
      {
        loan_id: props.loan_id,
      }
    );
    const dispatch = useDispatch();
    const { nextRoute, dataLayerPush, closeAlert } = useApplyWizardContext();
    const flags = useFlags();

    useEffect(() => {
      if (props.loan_id === undefined) {
        history.push('/app/select');
      }
    }, [props.loan_id]);

    const routeDecision = ({
      decision,
      uuid,
      fico,
      pendingResponses,
      loanOffers,
    }: {
      uuid: string;
      decision: string;
      fico: number;
      pendingResponses: {
        partner: {
          uuid: string;
          imageUrl: string;
        };
      }[];
      loanOffers: LoanOffer[];
    }) => {
      // close any existing alerts
      if (closeAlert) {
        closeAlert();
      }

      // send a GTM event
      if (dataLayerPush) {
        dataLayerPush('credit-decision', {
          decision,
        });
        analytics.track('Apply Form - Credit Decision', {
          decision,
        });
      }

      // use history.replace so that process cannot be back browsed
      switch (decision) {
        case DecisionBusiness.ProceedEQ:
        case DecisionBusiness.ProceedWC:
        case DecisionPersonal.ProceedEQ:
        case DecisionPersonal.ProceedWC:
        case 'proceed':
        case 'offers':
          if (loanOffers.length === 0) {
            history.replace('/app/decision/declined');
          } else {
            dispatch(
              setTemp({
                offersLoaded: true,
                offers: { loanOffers },
                // pendingOffers: { pendingResponses }, // Refactored to "blocking" call so we always get offers.
              })
            );
            history.replace('/app/decision/offers');
          }
          break;
        case DecisionBusiness.DeclinedError:
        case DecisionBusiness.DeclinedTimeInBusiness:
        case DecisionBusiness.DeclinedFico:
        case DecisionBusiness.DeclinedRevenue:
        case DecisionBusiness.DeclinedGeneral:
        case DecisionPersonal.DeclinedError:
        case DecisionPersonal.DeclinedTimeInBusiness:
        case DecisionPersonal.DeclinedFico:
        case DecisionPersonal.DeclinedRevenue:
        case DecisionPersonal.DeclinedGeneral:
          change('application', 'owner_1_fico', fico);
          history.replace('/app/decision/decline');
          break;
        case DecisionBusiness.DeclinedFrozen:
        case DecisionPersonal.DeclinedFrozen:
          history.replace('/app/decision/frozen');
          break;
        default:
          history.replace('/app/decision/declined');
          break;
      }
    };

    useEffect(() => {
      if (sendFormResponse) {
        routeDecision(sendFormResponse.data);
      }
    }, [sendFormResponse]);

    useEffect(() => {
      if (process.env.STORYBOOK_TEST) return;

      if (platformName === 'tento') {
        var deviceFPOptions = {
          publicKey: process.env.SOCURE_PUBLIC_KEY,
          endpoint: process.env.SOCURE_URL,
          userConsent: true,
          context: 'transaction',
        };
        devicer.run(deviceFPOptions, function (response: any) {});
      }
    }, []);

    const onSubmitFunction = async (form: any) => {
      sendForm({
        data: {
          dealId: props.loan_id,
          dealType: Array.isArray(props.loan_type)
            ? props.loan_type.length > 0
              ? props.loan_type[0].toString().toUpperCase()
              : 'WC'
            : props.loan_type?.toString().toUpperCase() ?? 'WC',
          amount: props.loan_amount,
          ownRent: form.own_rent,
          employment: form.employment_status,
          payFrequency: form.pay_frequency,
          annualIncome: Number(form.annual_income),
          educationLevel: form.education,
          creditRange: form.credit_range,
        },
      });
    };

    return (
      <>
        <WrappedComponent {...(props as P)} onSubmit={onSubmitFunction} saving={sendFormLoading} />
        {sendFormError && (
          <Alert severity="error">
            <AlertTitle>Failed to send</AlertTitle>
            <DataError
              msg="Unfortunately, we couldn't send your personal loan application. Please try again."
              error={sendFormError}
            />
          </Alert>
        )}
      </>
    );
  };

  HOC.displayName = `withOnPersonalLoanSubmit(${getDisplayName(WrappedComponent)})`;

  const mapStateToProps = (state: RootState) => {
    return {
      enrichOwners: enrichOwnersWithIds(state),
      partnerCustomFields: state.partnerCustomFields,
      type_of_statements: appSelector(state, 'type_of_statements'),
      contactId: appSelector(state, 'owner_1_id'),
      loan_id: appSelector(state, 'loan_id'),
      loan_type: appSelector(state, 'loan_type'),
      loan_amount: appSelector(state, 'loan_amount'),
    };
  };

  return connect(mapStateToProps)(HOC as any);
};

const getDisplayName = (WrappedComponent: ComponentType<any>): string => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default withOnPersonalLoanSubmit;
