import './AppBar.scss';
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import AuthMenu from '../Auth/Menu';
import { Stack, StepsList, useResponsiveBreakpoints } from 'shared-components';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { STEP_HOMEPAGE } from '@components/ApplyWizard/ApplyWizardSteps';

const prefixZero = (num) => (num < 10 ? `0${num}` : `${num}`);

const StepsNavigation = ({ formValues }) => {
  const { steps, currentStep, changeStepTo, currentStepIndex, stepDirection, setStepDirection, farthestStep } =
    useApplyWizardContext();
  const farthestStepIndex = steps.findIndex((s) => s.id === farthestStep.id);
  const navigationSteps = steps
    .map((step, index) => {
      return {
        id: step.id,
        stepType:
          farthestStepIndex < index && farthestStepIndex > -1
            ? 'incomplete'
            : step.id === currentStep.id
            ? 'current'
            : step.isComplete(formValues)
            ? 'completed'
            : 'error',
        isShowingInNavigation: step.isShowingInNavigation,
      };
    })
    .filter((step) => step.isShowingInNavigation)
    .map((step, index) => ({
      ...step,
      children: prefixZero(index + 1),
    }));
  return (
    <StepsList
      onStepChange={(step) => {
        const futureStepIndex = steps.findIndex((s) => s.id === step.id);
        setStepDirection(futureStepIndex > currentStepIndex ? 'next' : 'prev');
        changeStepTo(step.id);
      }}
      steps={navigationSteps}
    />
  );
};

let AppBarMain = ({
  theme,
  vendor_url,
  logo,
  logoWhite = 'https://s3.amazonaws.com/media.tento.co/apply/partners/brands/z695yustk8h8/logo-white.svg',
  // logoWhite = 'https://media.tento.co/apply/partners/brands/z695yustk8h8/logo-white.svg',
  formValues,
}) => {
  const { isInWizard, showStepNavigation, currentPath } = useApplyWizardContext();
  const flags = useFlags();
  const responsiveBreakpoints = useResponsiveBreakpoints();

  const url = window.location.href;

  const isWoo = url.includes('woo.tento.co'); // Remove height constraint on the logo for woo.tento.co

  return (
    <header className="sticky top-0 z-40 app-bar">
      <div className="nav-background">
        <Toolbar className="appHeader shadow-none">
          <div>
            <a href={vendor_url} className={theme.logoPosition === 'center' ? 'logo-center-wrap ' + 'logo-wrap' : ''}>
              <img
                src={currentPath === STEP_HOMEPAGE ? logoWhite : logo}
                alt=""
                className={`transition-all duration-500 ease-in-out logo ${isWoo ? '' : 'h-[28px]'}`}
              />
            </a>
          </div>
          {(responsiveBreakpoints.isSmallDesktop || responsiveBreakpoints.isDesktop) &&
            (flags.applyShowStepNavigationOnTop || process.env.NODE_ENV === 'test' || window.IS_STORYBOOK === '1') &&
            isInWizard &&
            showStepNavigation && <StepsNavigation formValues={formValues} />}
          <AuthMenu />
        </Toolbar>
      </div>
      {/* <Stack flow="row" alignItems="center" justifyContent="center">
        {responsiveBreakpoints.isMobile &&
          (flags.applyShowStepNavigationOnTop || process.env.NODE_ENV === 'test' || window.IS_STORYBOOK === '1') &&
          isInWizard &&
          showStepNavigation && <StepsNavigation formValues={formValues} />}
      </Stack> */}
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    logo: state.brand.data.media.logo_url,
    logoWhite: state.brand.data.media.logo_white_url,
    vendor_url: state.brand?.data?.vendor_url,
    formValues: state.form?.application?.values ?? {},
  };
};
const AppBarMainConnected = connect(mapStateToProps)(AppBarMain);

export default AppBarMainConnected;
