export const detectEnvironment = () => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;

    if (url.includes('.stage.tento.co')) {
      return 'stage';
    } else if (url.includes('.dev.tento.co')) {
      return 'dev';
    } else if (url.includes('.tento.co') && !url.includes('local')) {
      return 'production';
    } else if (url.includes('localhost')) {
      return 'local';
    }
  }
  return 'unknown';
};
