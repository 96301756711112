import React, { Component } from 'react';
import classNames from 'classnames';

const SkipStep = ({ invalid, skipText, nextRoute }) => {
  const skipStep = () => {
    if (invalid) {
      nextRoute();
    }
  };

  let linkClass = classNames('faux-link flex flex-1 justify-center align-center', { muted: invalid ? false : true });

  if (skipText) {
    return (
      <div className={linkClass} onClick={skipStep}>
        {skipText}
      </div>
    );
  } else {
    return null;
  }
};

export default SkipStep;
