import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Loan from './Loan';
import devicer from '../../../../../external-libs/devicer.min.js';
import { changePriceToNumber } from '../../../utils/changePriceToNumber';
import { platformName } from '../../../utils/platformBasedInfo';

const LoanContainer = ({ match, nextRoute, dispatch, config }) => {
  const selectLoanType = (type) => {
    dispatch(change('application', 'loan_type', [type]));
    dispatch(change('application', 'loan_terms', config[type?.toUpperCase()].selectedTerm));
    dispatch(change('application', 'loan_amount', changePriceToNumber(config[type?.toUpperCase()].amount)));

    window.setTimeout(() => nextRoute(type), 0);
  };
  useEffect(() => {
    const { type } = match.params;

    if (type === 'EQ' || type === 'WC' || type === 'LOC' || type === 'BCC' || type === 'PL') {
      selectLoanType(type);
    }

    if (process.env.STORYBOOK_TEST) return;

    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        endpoint: process.env.SOCURE_URL,
        userConsent: true,
        context: 'transaction',
      };

      devicer.run(deviceFPOptions, function (response) {
        console.log('socure', response);
      });
    }
  }, []);

  const setEq = () => {
    selectLoanType('EQ');
  };

  const setWc = () => {
    selectLoanType('WC');
  };

  return <Loan setEq={setEq} setWc={setWc} />;
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default connect(mapStateToProps)(withRouter(LoanContainer));
