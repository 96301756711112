/* eslint-disable react/no-unescaped-entities */
import React, { PropsWithChildren } from 'react';
import { Text } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Typography',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
};

export default meta;

export const HeadlineLarge = () => <Text variant="headline-lg">Plaid is now connected</Text>;
export const SubheadlineLarge = () => (
  <Text variant="subheadline-lg">
    Our mission is to save you time and give you the resources to grow your business with confidence.
  </Text>
);
export const HeadlineSmall = () => <Text variant="headline-sm">Tell us how we can help</Text>;
export const SubheadlineSmall = () => (
  <Text variant="subheadline-sm">
    To give you the quickest pre-qualification, we'll need the last 4 months of bank statements from your business.
  </Text>
);
export const BodyGeneral = () => (
  <Text variant="body-general">Securely connect your bank account with our trusted partner Plaid.</Text>
);
export const DetailNumbers = () => <Text variant="detail-numbers">$4,678/mo*</Text>;
export const LabelInfo = () => <Text variant="label-info">Amount</Text>;
export const BodyFormActive = () => <Text variant="body-form-active">$185,000</Text>;
export const CtaGeneral = () => (
  <>
    <Text variant="cta-general">Continue</Text>
    {` `}
    <Text variant="cta-general" className="uppercase text-primary text-[18px]">
      Continue
    </Text>
  </>
);
export const BodyFormInactive = () => <Text variant="body-form-inactive">Inactive Form Field</Text>;
export const LabelNumbers = () => (
  <>
    <Text variant="label-numbers">01</Text>
    {` `}
    <Text variant="label-numbers">02</Text>
    {` `}
    <Text variant="label-numbers">03</Text>
  </>
);
export const CtaTextNavCard = () => (
  <>
    <Text variant="cta-nav-card" className="pr-8 uppercase">
      Log In
    </Text>
    {` `}
    <Text variant="cta-nav-card" className="underline uppercase">
      Skip For Now
    </Text>
  </>
);
export const Error = () => <Text variant="error">* Required</Text>;
