import { z } from 'zod';

export const reduxFormZodValidate = (schema: z.ZodSchema) => (values: any) => {
  const errors = {};
  try {
    schema.parse(values);
  } catch (e) {
    if (e instanceof z.ZodError) {
      e.errors.forEach((error) => {
        if (error.path.length > 0) {
          // Assume the path has the field name
          const fieldName = error.path[0];
          (errors as any)[fieldName] = error.message;
        }
      });
    }
  }

  return errors;
};
