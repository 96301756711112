export const setCssThemeVariables = (theme) => {
  const rootElement = process.env.STORYBOOK_TEST
    ? document.getElementById('storybook-root')
    : document.getElementById('root');
  console.log(rootElement, process.env.STORYBOOK_TEST);
  rootElement.style.setProperty('--default-primary-color', theme?.primary_color);
  rootElement.style.setProperty('--default-primary-rgba', theme?.primary_rgba);
  rootElement.style.setProperty('--default-hover-color', theme?.hover_color);
  if (theme?.logo.scale_percent) {
    rootElement.style.setProperty('--logoScalePercent', '100'); // theme?.logo.scale_percent
  }
  if (theme?.logo.position) {
    rootElement.style.setProperty('--logoPosition', theme?.logo.position);
  }
  if (theme?.font_sizes.t1) {
    rootElement.style.setProperty('--t1', theme?.font_sizes.t1);
  }
  if (theme?.font_sizes.t2) {
    rootElement.style.setProperty('--t2', theme?.font_sizes.t2);
  }
  if (theme?.font_sizes.t2LineH) {
    rootElement.style.setProperty('--t2LineH', theme?.font_sizes.t2LineH);
  }
  if (theme?.font_sizes.t3) {
    rootElement.style.setProperty('--t3', theme?.font_sizes.t3);
  }
  if (theme?.font_sizes.t4) {
    rootElement.style.setProperty('--t4', theme?.font_sizes.t4);
  }
  if (theme?.font_sizes.t5) {
    rootElement.style.setProperty('--t5', theme?.font_sizes.t5);
  }
  if (theme?.font_sizes.t5LineH) {
    rootElement.style.setProperty('--t5LineH', theme?.font_sizes.t5LineH);
  }
  if (theme?.font_sizes.s0) {
    rootElement.style.setProperty('--s0', theme?.font_sizes.s0);
  }
  if (theme?.font_sizes.s1) {
    rootElement.style.setProperty('--s1', theme?.font_sizes.s1);
  }
  if (theme?.font_sizes.s2) {
    rootElement.style.setProperty('--s2', theme?.font_sizes.s2);
  }
  if (theme?.font_sizes.s3) {
    rootElement.style.setProperty('--s3', theme?.font_sizes.s3);
  }
  if (theme?.font_sizes.s4) {
    rootElement.style.setProperty('--s4', theme?.font_sizes.s4);
  }
  if (theme?.font_sizes.inputPlaceholderSize) {
    rootElement.style.setProperty('--inputPlaceholderSize', theme?.font_sizes.inputPlaceholderSize);
  }
  if (theme?.font_sizes.inputErrorSize) {
    rootElement.style.setProperty('--inputErrorSize', theme.font_sizes.inputErrorSize);
  }
  if (theme?.font_family) {
    rootElement.style.setProperty('--mainFont', theme?.font_family);
  } else {
    rootElement.style.setProperty('--mainFont', 'Inter');
  }
};
