import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_POST_VALIDATE_USER = `${backendBaseUrl}/user/register/validate`;

export const validateUserByEmail = () =>
  useAxios(
    {
      url: ENDPOINT_POST_VALIDATE_USER,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );
