import React, { createRef, useEffect, useState } from 'react';
import Geosuggest from 'react-geosuggest';
import ChevronIcon from '_icons/ChevronIcon';
import SearchIcon from '_icons/SearchIcon';
import CloseIcon from '_icons/CloseIcon';
import AddressManual from './Manual';
import CustomItem from './CustomItem';
import './Address.scss';
import { Stack, cn } from 'shared-components';

const Address = ({ showDefaultManualAddress, address: _address, change, ownerIdx, autoFocus = true }) => {
  const idx = ownerIdx || false;
  const addressKey = idx ? `owner_${idx}` : `business`;
  const addressType = idx ? 'Home' : 'Business';
  const [manualAddress, setManualAddress] = useState(showDefaultManualAddress || false); //existingAddress()
  const [addressFound, setAddressFound] = useState(false);
  const [address, setAddress] = useState(_address);
  const showAddressLookup = manualAddress ? 'hide' : 'show';
  const forceShow = addressFound ? 'force-show' : '';
  const _geoSuggest = createRef();

  useEffect(() => {
    if (!window.google) {
      setManualAddress(true);
    }
    // set the input box if we've preloaded an address
    const { street, city, state } = _address;
    let streetCheck = street || '';

    try {
      if (streetCheck.length > 0 && _geoSuggest) {
        const preloadAddress = `${street}, ${city}, ${state}, USA`;
        _geoSuggest.current.update(preloadAddress);
      }
    } catch (error) {
      // console.log('geo-suggest',error);
    }
  }, []);

  const onSuggestSelect = (suggest) => {
    if (!suggest?.gmaps) {
      return;
    }

    const components = suggest.gmaps.address_components;

    const address = {
      street: (
        findAddressComponent(components, 'street_number', 'short_name') +
        ' ' +
        findAddressComponent(components, 'route', 'short_name')
      ).trim(),
      city: findAddressComponent(components, 'locality', 'short_name').trim(),
      state: findAddressComponent(components, 'administrative_area_level_1', 'short_name').trim(),
      zip: findAddressComponent(components, 'postal_code', 'short_name').trim(),
    };
    setAddressFound(1);
    setAddress(address);

    // update redux-form store with address info
    change(`${addressKey}_street`, address.street);
    change(`${addressKey}_suite`, address.suite);
    change(`${addressKey}_city`, address.city);
    change(`${addressKey}_state`, address.state);
    change(`${addressKey}_zip`, address.zip);
  };

  const findAddressComponent = (components, type, version) => {
    // this address throws error: 5675 Broadway, Bronx, NY, USA
    try {
      const address = components.find(function (component) {
        return component.types.indexOf(type) !== -1;
      });

      return address[version];
    } catch (error) {
      if (type === 'locality') {
        return findAddressComponent(components, 'sublocality', version);
      }
      if (type === 'administrative_area_level_2' && addressType === 'Business') {
        return ''; // need to trigger requried validation for country on biz addresses
      }
      return '';
    }
  };

  const toggleManualAddress = () => {
    setManualAddress(!manualAddress);
  };

  const existingAddress = () => {
    if (_address.street.length > 0 && _address.city.length > 0 && _address.state.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const clearSearch = () => {
    //reset addressFound
    setAddressFound(false);

    // clear manual address fields
    change(`${addressKey}_street`, '');
    change(`${addressKey}_suite`, '');
    change(`${addressKey}_city`, '');
    change(`${addressKey}_state`, '');
    change(`${addressKey}_zip`, '');

    // clear geosuggest box and then focus it
    _geoSuggest.current?.clear();
    _geoSuggest.current?.focus();
  };

  const customSuggestItem = (suggest) => {
    const address = suggest.label.split(',');

    return <CustomItem street={address[0]} city={address[1]} state={address[2]} />;
  };
  const baseClasses =
    'w-full border-[1px] rounded-[10px] border-charcoal text-charcoal placeholder-charcoal text-left bg-spark font-normal text-[18px] px-[20px] leading-normal h-[50px] font-condensed italic focus:text-charcoal focus:font-standard focus:bg-dawn focus:rounded-[100px] focus:not-italic invalid:placeholder-zest invalid:focus:placeholder-charcoal invalid:text-zest invalid:bg-dawn transition-border-radius duration-500 ease-in-out';
  const activeClasses = 'font-standard bg-dawn rounded-[100px] not-italic';
  const completedClasses = 'font-standard bg-dawn rounded-[10px] not-italic';

  return (
    <>
      <Geosuggest
        data-testid="address-search"
        ref={_geoSuggest}
        value=""
        placeholder={`${addressType} Address*`}
        onSuggestSelect={onSuggestSelect}
        readOnly={manualAddress}
        className={`${showAddressLookup} w-full max-w-[368px] m-0 col-span-full`}
        inputClassName={cn(baseClasses, {
          [activeClasses]: false,
          [completedClasses]: address && address.street && address.city && address.state && address.zip,
        })}
        country="us"
        renderSuggestItem={customSuggestItem}
        autoFocus={autoFocus}
      />
      <AddressManual
        address={address}
        addressKey={addressKey}
        addressType={addressType}
        manualAddress={manualAddress}
        toggleManualAddress={toggleManualAddress}
      />
    </>
  );
};

export default Address;
