import type { Meta } from '@storybook/react';
import { StepCard } from 'shared-components';
import { Conduit as ConduitIcon } from 'shared-components';
import { Embed as EmbedIcon } from 'shared-components';
import { Text } from 'shared-components';
import { Button } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Compositions/StepCard',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const Apply = () => (
  <StepCard
    type="current"
    headline="Apply"
    number="02"
    textBody="Ready to grow? Get instant access to funding."
    icon={<ConduitIcon />}
    button="Primary"
  />
);
export const ApplyUpcomming = () => (
  <StepCard
    type="upcomming"
    headline="Apply"
    number="02"
    textBody="Ready to grow? Get instant access to funding."
    icon={<ConduitIcon fill="#D5D973" />}
    button="Primary"
  />
);
export const ApplyCompleted = () => (
  <StepCard
    type="completed"
    headline="Apply"
    number="02"
    textBody="Ready to grow? Get instant access to funding."
    icon={<ConduitIcon fill="#D5D973" />}
    button="Primary"
  />
);
export const Engage = () => (
  <StepCard
    type="current"
    headline="Engage"
    number="01"
    textBody="Funding tailored directly to your business, within platform."
    icon={<EmbedIcon />}
    cardProps={{ size: 'small' }}
  />
);
export const EngageUpcomming = () => (
  <StepCard
    type="upcomming"
    headline="Engage"
    number="01"
    textBody="Funding tailored directly to your business, within platform."
    icon={<EmbedIcon fill="#D5D973" />}
    cardProps={{ size: 'small' }}
  />
);
export const EngageCompleted = () => (
  <StepCard
    type="completed"
    headline="Engage"
    number="01"
    textBody="Funding tailored directly to your business, within platform."
    icon={<EmbedIcon fill="#D5D973" />}
    cardProps={{ size: 'small' }}
  />
);
export const PlaidCurrent = () => (
  <StepCard
    type="current"
    headline="Connect with Plaid"
    textBody="Securely connect your bank account with our trusted partner Plaid."
    button="primary"
    cardProps={{ className: 'justify-between' }}
  />
);
export const PlaidUpcomming = () => (
  <StepCard
    type="upcomming"
    headline="Connect with Plaid"
    textBody="Securely connect your bank account with our trusted partner Plaid."
    button="primary"
    cardProps={{ className: 'justify-between' }}
  />
);
export const PlaidCompleted = () => (
  <StepCard
    type="completed"
    headline="Connect with Plaid"
    textBody="Securely connect your bank account with our trusted partner Plaid."
    button="primary"
    cardProps={{ className: 'justify-between' }}
  />
);
export const PlaidSecondaryText = () => (
  <StepCard
    type="upcomming"
    headline="Connect with Plaid"
    textBody="Securely connect your bank account with our trusted partner Plaid."
    button="primary"
    cardProps={{ className: 'justify-between' }}
    additionalComponent={
      <Button variant="text" className="uppercase">
        Secondary
      </Button>
    }
  />
);
