import { backendBaseUrl, backendUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';
import { UseAxiosResult } from 'axios-hooks';

export const getCountryByIP = (): UseAxiosResult => {
  return useAxios(
    {
      url: `${backendBaseUrl}/v1/ip/country`,
      withCredentials: true,
      method: 'GET',
    },
    { manual: true }
  );
};
