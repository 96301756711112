import { App as Page } from '../../App';
import React, { useLayoutEffect } from 'react';

// !!!!! IMPORTANT:
// Embeded apply is loaded from production server at:
// <script src="https://scripts.tento.co/apply/embedded.js"></script>
// see .storybook/preview-head.html
const meta = {
  title: '001-Apply Frontend/Embedded/Iframe',
  component: Page,
  argTypes: {},
  decorators: [],
  parameters: {
    viewport: {
      defaultViewport: 'ipad',
    },
  },
};

export default meta;

export const Iframe = () => {
  useLayoutEffect(() => {
    window.EmbeddedTento({
      type: 'iframe',
      mountId: 'tento-widget',
      brandId: '1234567890',
      type: 'iframe',
      userId: '1234567890',
    });
  });
  return (
    <div style={{ margin: '10px' }}>
      <h1>This is some website of our partner</h1>

      <div style={{ display: 'grid', gridGap: '10px', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ border: '1px solid red', minHeight: '100vh' }}>
          <h2>This is some private partner website content</h2>
        </div>
        <div style={{ border: '1px solid green', minHeight: '100vh' }}>
          <h2>In this box partner mounts embedded script</h2>
          <div id="tento-widget" />
        </div>
      </div>
    </div>
  );
};
