import React from 'react';
import Script from 'react-load-script';
import ErrorBoundary from '@components/_shared/Error/Error';
import AppBarMain from '@components/_shared/AppBar/Main';
import Application from '@components/Apply/Application';
import { useDispatch } from 'react-redux';
import { ContentCard } from '@components/ContentCard/ContentCard';
import Footer from '@components/Home/Footer';
import { useLocation } from 'react-router-dom';
import { googleMapsApi } from '@utils/platformBasedInfo';
import { ApplyWizardProvider } from '../../context/ApplyWizardContext';

const Apply = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return (window as any).IS_STORYBOOK === '1' ? (
    <ErrorBoundary>
      <ApplyWizardProvider>
        {queryParams.get('eHideHeader') === 'true' ? null : <AppBarMain />}
        <ContentCard
          variant={location.pathname.startsWith('/app/decision/') ? 'wide' : 'standard'}
          hideBackArrow={location.pathname === '/app/verify/login' || location.pathname === '/app/select'}
        >
          <div className="flex flex-col space-y-6 h-fit self-safe-center m-auto justify-center">
            <Application dispatch={dispatch} />
          </div>
        </ContentCard>
        {queryParams.get('eHideFooter') === 'true' ? null : <Footer />}
        <Script url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}&libraries=places`} />
      </ApplyWizardProvider>
    </ErrorBoundary>
  ) : (
    <>
      {queryParams.get('eHideHeader') === 'true' ? null : <AppBarMain />}
      <ContentCard
        variant={location.pathname.startsWith('/app/decision/') ? 'wide' : 'standard'}
        hideBackArrow={location.pathname === '/app/verify/login' || location.pathname === '/app/select'}
      >
        <div className="flex flex-col space-y-6 h-fit self-safe-center m-auto justify-center">
          <Application dispatch={dispatch} />
        </div>
      </ContentCard>
      {queryParams.get('eHideFooter') === 'true' ? null : <Footer />}
      <Script url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}&libraries=places`} />
    </>
  );
};

export default Apply;
