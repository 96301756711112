import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_GET_LOANEE_DEALS = `${backendBaseUrl}/deals/loanee`;

export const getLoaneeDeals = () =>
  useAxios(
    {
      url: ENDPOINT_GET_LOANEE_DEALS,
      withCredentials: true,
      method: 'GET',
    },
    { manual: true }
  );
