import React from 'react';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Helmet } from 'react-helmet';
import ContinueButton from '@components/_shared/Continue/Button';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { reduxForm } from 'redux-form';
import { Button } from 'shared-components';

const AddOwner = (props) => {
  const { handleSubmit, match, invalid, saving, ownerIdx, history } = props;
  const nextOwnerIndex = Number(ownerIdx) + 1;
  const continueButton =
    match.params.property === 'add' ? null : (
      <ContinueButton invalid={invalid} saving={saving} data-testid="question-submit-button" />
    );

  const form = useForm();

  return (
    <div className="app-section slide">
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <Stack gap={ELEMENTS_GAP} className="slideWidth">
            <Stack gap={ELEMENTS_GAP} className="slideWidth">
              <Helmet>
                <title>More owners? - Your Loan Application</title>
              </Helmet>
              <FormPageTopPart headingText="Are there any other owners?" subHeadingText="" />
              <div className="flex justify-center gap-[8px] align-center">
                <Button
                  data-testid="owner-yes"
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    history.push(`/app/owners/${nextOwnerIndex}/info`);
                  }}
                >
                  Yes
                </Button>

                <Button type="submit" variant="secondary">
                  No
                </Button>
              </div>
            </Stack>
            {continueButton}
          </Stack>
        </form>
      </Form>
    </div>
  );
};

const AddOwnerWrapped = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AddOwner);

export default AddOwnerWrapped;
