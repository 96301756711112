import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { ModalManager } from 'react-dynamic-modal';
import { handleSignOut } from '@utils/reduxUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { Heading2 } from '@components/typography/Heading2';
import { ContentCard } from '@components/ContentCard/ContentCard';
import Footer from '@components/Home/Footer';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

let Expired = (props) => {
  const auth0 = useAuth0();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // close any lingering processing modals after a redirect to expired page
  ModalManager.close();

  handleSignOut(props.dispatch);

  return (
    <>
      <Helmet>
        <title>Login Expired - Your Loan Application</title>
      </Helmet>
      <ContentCard>
        <div className="app-section">
          <Heading2>Login Expired</Heading2>

          <p>You are now signed out. We have automatically cleared sensitive data from your browser.</p>

          <p>
            If for any reason you need to sign back in you can do so here.{' '}
            <Link
              onClick={() =>
                auth0.loginWithRedirect({
                  appState: {
                    returnTo: '/app/verify/login',
                  },
                })
              }
            >
              Sign in
            </Link>
          </p>
        </div>
      </ContentCard>
      {queryParams.get('eHideFooter') === 'true' ? null : <Footer />}
    </>
  );
};

Expired = reduxForm({
  form: 'application',
})(Expired);

export const UserExpiredRoute = connect()(Expired);

export default UserExpiredRoute;
