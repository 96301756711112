import * as React from 'react';

import { cn } from '@utils/cn';
import { TextField, TextFieldProps } from '@material-ui/core';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-testid': string;
}

const MuiInputStandard = React.forwardRef<HTMLInputElement, InputProps & TextFieldProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <TextField
        label="Test"
        variant="outlined"
        type={type}
        className={cn(
          'flex min-h-14 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
        InputProps={{
          className: 'w-full',
          inputProps: {
            'data-testid': props['data-testid'],
          },
          ...(props.InputProps ?? {}),
        }}
        {...{ 'data-testid': `wrapper-${props['data-testid']}` }}
        color="primary"
        size="medium"
      />
    );
  }
);
MuiInputStandard.displayName = 'MuiInputStandard';

export { MuiInputStandard };
