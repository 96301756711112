import React, { useMemo } from 'react';
import { Select, SelectDynamic, Slider, Stack, Text, ToggleGroup } from 'shared-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import './inputs.scss';

import {
  FormDescription,
  FormItem,
  FormControl as FormControlUI,
  FormMessage,
} from '@components/shadcn/components/ui/form';
import { cn, Checkbox, Input } from 'shared-components';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { NumberFormatBase, NumericFormat, PatternFormat } from 'react-number-format';
import { cleanSsnToDigitsAndStars, maskSsn } from '@components/_fields/normalizers';

export const Wrapper = (props) => {
  return <div className={classNames(...props.classes)}>{props.children}</div>;
};

export const Label = (props) => {
  if (props.text) {
    return (
      <label className={props.className} htmlFor={props.name}>
        {props.text}
      </label>
    );
  } else return null;
};

export const Error = ({ meta: { error, touched, active, pristine }, hide, className }) => {
  const shouldShowError = error && touched && !active && !pristine && !hide;
  if (!shouldShowError) {
    return null;
  }

  return (
    <div
      className={cn(
        'error-text max-w-[368px] mx-auto text-center w-full align-center justify-center justify-items-center',
        className
      )}
    >
      <Text variant="error">{error}</Text>
    </div>
  );
};

export const newThemeInput = ({
  meta,
  label,
  labelText,
  labelClassName,
  input,
  className,
  formItemClassName,
  type,
  focusRef,
  placeholder,
  autoFocus,
  autoComplete,
  tabIndex,
  hideError,
  smallInfoText,
  half,
  defaultValue,
  formatAsNumber,
  formatAsPhone,
  formatAsFederalTaxId,
  formatAsSsn,
  ...otherProps
}) => {
  // default to on
  const autoCompleteValue = autoComplete ? autoComplete : 'on';

  const inputBaseProps = {
    ...input,
    ...otherProps,
    id: input.name,
    type: type,
    ref: focusRef,
    placeholder: placeholder || labelText || label,
    autoFocus: autoFocus,
    tabIndex: tabIndex,
    autoComplete: autoCompleteValue,
    'data-testid': otherProps['data-testid'],
    'data-hj-whitelist': true,
    className: className,
    label: labelText || label,
  };
  return (
    <FormItem
      className={cn('grid grid-cols-1 w-full align-center justify-center justify-items-center', formItemClassName)}
      {...(half ? { style: { width: '50%' } } : {})}
    >
      <FormControlUI>
        {formatAsNumber ? (
          <NumericFormat customInput={Input} thousandSeparator={true} prefix={'$ '} {...inputBaseProps} />
        ) : formatAsPhone ? (
          <PatternFormat customInput={Input} format="###-###-####" patternChar="#" mask="_" {...inputBaseProps} />
        ) : formatAsFederalTaxId ? (
          <PatternFormat customInput={Input} format="XX-XXXXXXX" patternChar="X" mask="_" {...inputBaseProps} />
        ) : formatAsSsn ? (
          // DOESNT WORK WITH A MASK ****** if using NumberFormatBase.
          // Input only will keep the jumping carret but from redux-form but is better than a broken mask.
          <Input {...inputBaseProps} />
        ) : (
          <NumberFormatBase
            type="text"
            displayType="input"
            format={(value) => (value ? value : '')}
            removeFormatting={(value) => value}
            valueIsNumericString={false}
            customInput={Input}
            {...inputBaseProps}
            inputMode={type}
            getCaretBoundary={(formattedValue, caretPos) => {
              return Array.from({ length: formattedValue.length + 1 }).map(function () {
                return true;
              });
            }}
          />
        )}
      </FormControlUI>
      {smallInfoText && <FormDescription>{smallInfoText}</FormDescription>}
      <Error meta={meta} hide={hideError} />
      <FormMessage />
    </FormItem>
  );
};
export const basicInput = ({
  meta,
  label,
  labelText,
  input,
  className,
  type,
  focusRef,
  placeholder,
  autoFocus,
  tabIndex,
  hideError,
  ...otherProps
}) => {
  return (
    <React.Fragment>
      <input
        {...input}
        id={input.name}
        type={type}
        ref={focusRef}
        placeholder={placeholder || labelText || label}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        data-hj-whitelist
        data-testid={otherProps['data-testid']}
      />
      <Error meta={meta} hide={hideError} />
    </React.Fragment>
  );
};

export const rangeInput = (props) => {
  const { input, min, max, ...otherProps } = props;

  console.log('rangeInput', props);
  return (
    <Slider
      // {...input}
      value={[Number(input.value) ?? 50000]}
      onValueChange={(value) => {
        input.onChange(value ? value[0] : 0);
      }}
      id={input.name}
      data-testid={otherProps['data-testid']}
      defaultValue={[Number(input.value) ?? 50000]}
      max={Number(max) ?? 250000}
      min={Number(min) ?? 0}
      step={1000}
    />
  );
};

export const selectDynamicInput = (props) => {
  const { input, label, className, meta, ...otherProps } = props;
  const component = useMemo(
    () => (
      <SelectDynamic
        {...otherProps}
        commandItemProps={{
          ...(otherProps.commandItemProps ?? {}),
          onSelect: (item) => {
            input.onChange(item);
            if (otherProps.commandItemProps?.onSelect) {
              otherProps.commandItemProps.onSelect(item);
            }
          },
        }}
      />
    ),
    []
  );
  return component;
};

export const radioButton = (props) => {
  const { type, input, id, ...otherProps } = props;

  return <input {...input} id={id} type={type} data-testid={otherProps['data-testid']} />;
};

export const checkBox = (props) => {
  const { type, input, id, children, ...otherProps } = props;
  return (
    <>
      <Checkbox
        className="tento-checkbox"
        {...input}
        onBlur={(e) => {
          // Bug that makes true become "true". To keep boolean we need to add this onBlur.
        }}
        onClick={input.onChange}
        type={'button'}
        checked={input.value === 'true' || (input.value && input.value === true)}
        id={id}
        data-testid={otherProps['data-testid']}
        stackProps={{
          className: 'w-full',
        }}
        {...otherProps}
      >
        {children}
      </Checkbox>
      <Error meta={otherProps.meta} hide={otherProps.hideError} />
    </>
  );
};

const validityClass = (meta) => {
  if (meta.active) {
    return;
  }
  if (meta.touched && meta.invalid) {
    return 'invalid';
  }
  if (meta.touched && meta.valid) {
    return 'valid';
  }
};

export const datePicker = ({ dateValue, handleDateChange, meta, hideError, label, placeholder, ...props }) => {
  return (
    <Stack clssName={[validityClass(meta)]} templateColumns="1fr" alignItems="center" justifyItems="center">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          value={dateValue}
          onChange={handleDateChange}
          className="max-w-[368px] flex w-full px-3 py-2 text-sm border border-charcoal rounded-md mui-date-picker border-input bg-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          format="MM/dd/yyyy"
          openTo="year"
          maxDate={new Date()}
          autoOk
          autoFocus
          placeholder={placeholder}
          label={label}
          InputProps={{
            className: 'px-3 py-2 md:min-w-80 h-14 max-w-[368px]',
            inputProps: {
              'data-testid': props['data-testid'],
            },
            ...(props.InputProps ?? {}),
          }}
        />
      </MuiPickersUtilsProvider>
    </Stack>
  );
};

export const muiCustomSelect = (props) => {
  const { input, label, className, valueAsNumber, ...otherProps } = props;

  // check for 0 value meaning filled in, somewhere??
  const defaultValue = valueAsNumber
    ? input.value || input.value === 0
      ? Number(input.value)
      : undefined
    : input.defaultValue
    ? Number(input.defaultValue)
    : input.defaultValue ?? input.value;
  console.log('muiCustomSelect', defaultValue, input, props);
  return (
    <Select
      {...input}
      {...otherProps}
      defaultValue={defaultValue?.toString()}
      label={label}
      onValueChange={(value) => {
        if (valueAsNumber) {
          input.onChange(Number(value));
        } else {
          input.onChange(value);
        }
      }}
      displayEmpty
      data-testid={`select-${otherProps['data-testid']}`}
      inputProps={{ 'data-testid': otherProps['data-testid'] }}
    >
      {props.children}
    </Select>
  );
};

export const Toggle = (props) => {
  const { input, valueAsNumber, ...otherProps } = props;
  const dispatch = useDispatch();
  return (
    <>
      <ToggleGroup
        type="single"
        {...input}
        {...otherProps}
        onBlur={() => {}}
        defaultValue={
          input.defaultValue
            ? valueAsNumber
              ? Number(input.defaultValue)
              : input.defaultValue
            : otherProps.defaultValue
            ? valueAsNumber
              ? Number(otherProps.defaultValue)
              : otherProps.defaultValue
            : undefined
        }
        className={cn(
          'flex-wrap gap-[3.25px] w-full align-center justify-center justify-items-center mx-auto',
          otherProps.className
        )}
        buttonStackProps={{
          className: 'flex-1 w-full',
        }}
        itemClassName="flex-1"
        value={valueAsNumber ? Number(input.value) : input.value}
        onValueChange={(value) => {
          const valueInProperType = valueAsNumber ? Number(value) : value;
          input.onChange(valueInProperType);
          window.setTimeout(() => dispatch(change('application', input.name, valueInProperType)), 0);
          if (otherProps.onValueChange) otherProps.onValueChange(valueInProperType);
        }}
      />
      <Error meta={otherProps.meta} hide={otherProps.hideError} className="!text-center" />
    </>
  );
};
