import React, { Component, createRef, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { FilePond } from 'react-filepond';
import ContinueButton from '@components/_shared/Continue/Button';
import 'filepond/dist/filepond.min.css';
import { backendUrl } from '../../../utils/platformBasedInfo';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Helmet } from 'react-helmet';

const EnhancedAgreementUpload = ({ openAlert, closeAlert, saving, user, handleSubmit, business_id, contact_id }) => {
  const rhfForm = useForm();
  const pond = createRef();
  const [dlFiles, setDlFiles] = useState([]);
  const [vcFiles, setVcFiles] = useState([]);
  const [invalid, setInvalid] = useState(true);

  const handleError = (error, file) => {
    let msg = error.message || error.body;

    switch (error.code) {
      case 403:
        msg = 'Invalid token account combination';
        break;
      case 413:
        msg = 'File too large. Max file size is 8MB';
        break;
      case 415:
        msg = 'Uploaded files must be .jpg, .jpeg, .png, or .pdf';
        break;
      case 422:
        msg = 'Upload was missing required account number';
        break;
    }

    openAlert(msg, 'info', false, false);
  };

  const onUploadComplete = (error, file) => {
    if (error === null) {
      closeAlert();
      setInvalid(dlFiles.length === 0 || vcFiles.length === 0);
    }
  };

  return (
    <div className="app-section slide agreementUpload">
      <Helmet>
        <title>Agreement - Upload - Your Loan Application</title>
      </Helmet>
      <Stack gap={ELEMENTS_GAP} className="slideWidth">
        <FormPageTopPart
          headingText="Just two more docs"
          subHeadingText="Please upload a photo or scan of your valid driver's license and a voided check."
        />
        <FilePond
          ref={pond}
          files={dlFiles}
          name="docs"
          labelIdle="Drag, Tap, or <span class='filepond--label-action'>Browse</span><br/> to upload <strong>Driver's License</strong>"
          allowMultiple={false}
          server={{
            process: {
              url: `${backendUrl}/agreement/upload`,
              headers: { Authorization: 'Bearer ' + user.token },
            },
            revert: null,
            fetch: null,
            remove: null,
          }}
          onaddfile={(error, file) => {
            file.setMetadata('docType', 'govt-id');
            file.setMetadata('account_id', business_id);
            file.setMetadata('contact_id', contact_id);
          }}
          onerror={(error, file) => {
            handleError(error, file);
          }}
          labelFileProcessingError={(error) => {
            return error.body;
          }}
          onupdatefiles={(fileItems) => {
            setDlFiles(fileItems.map((fileItem) => fileItem.file));
          }}
          onprocessfile={(error, file) => onUploadComplete(error, file)}
        />

        <FilePond
          ref={pond}
          files={vcFiles}
          name="docs"
          labelIdle="Drag, Tap, or <span class='filepond--label-action'>Browse</span><br/> to upload <strong>Voided Check</strong>"
          allowMultiple={false}
          server={{
            process: {
              url: `${backendUrl}/agreement/upload`,
              headers: { Authorization: 'Bearer ' + user.token },
            },
            revert: null,
            fetch: null,
            remove: null,
          }}
          onaddfile={(error, file) => {
            file.setMetadata('docType', 'voided-check');
            file.setMetadata('account_id', business_id);
            file.setMetadata('contact_id', contact_id);
          }}
          onerror={(error, file) => {
            handleError(error, file);
          }}
          labelFileProcessingError={(error) => {
            return error.body;
          }}
          onupdatefiles={(fileItems) => {
            setVcFiles(fileItems.map((fileItem) => fileItem.file));
          }}
          onprocessfile={(error, file) => onUploadComplete(error, file)}
        />
        <div>
          <p className="security-msg">
            We will upload and store your documents using industry standard security practices.
          </p>
        </div>

        <Form {...rhfForm}>
          <form onSubmit={handleSubmit}>
            <ContinueButton invalid={invalid} saving={saving} showLock />
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
    user: state.user,
  };
};

const AgreementUpload = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(EnhancedAgreementUpload);

export default connect(mapStateToProps)(AgreementUpload);
