import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { formValueSelector } from 'redux-form';
import { RootState } from '@store/reducers';
import { Spinner } from '@components/Spinner/Spinner';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { currency } from '@components/_fields/normalizers';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Banknote, Briefcase, Building2, CalendarDays, DollarSign, ImageIcon } from 'lucide-react';
import { CallApiType } from 'src/js/types';
import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

export type EnhancedInfoProps = {
  partnerCustomFields: any;
  vendor_url: string;
  ownerEmail: string;
  loanAmount: string;
  callApi: CallApiType;
  loan_id: string;
  business_id: string;
  loanType: string;
  loanTerms: string;
  businessName: string;
  businessRevenue: string;
  yearsInBusiness: string;
  saving: boolean;
  handleSubmit: () => void;
  invalid: boolean;
  user: RootState['user'];
  vendor_name: string;
};

const SummaryContainer: FC<EnhancedInfoProps> = (props) => {
  const auth0 = useAuth0();

  if (auth0.isLoading) {
    return (
      <div className="app-section slide infoSLide">
        <Spinner />
      </div>
    );
  }

  return <Summary {...props} />;
};

export const Summary: FC<EnhancedInfoProps> = ({
  handleSubmit,
  partnerCustomFields,
  vendor_url,
  ownerEmail,
  business_id,
  loan_id,
  loanAmount,
  loanType,
  loanTerms,
  businessName,
  businessRevenue,
  yearsInBusiness,
  callApi,
  vendor_name,
  user,
}) => {
  const auth0 = useAuth0();
  const history = useHistory();
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';

  return (
    <div className="app-section slide infoSLide">
      <Helmet>
        <title>Summary of your application</title>
      </Helmet>
      <Stack gap={ELEMENTS_GAP} className="decision">
        <FormPageTopPart headingText="Summary" />

        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="!bg-dawn color-charcoal border border-charcoal">
                <DollarSign className="stroke-charcoal" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={currency(loanAmount)} secondary="(Loan Amount)" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="!bg-dawn color-charcoal border border-charcoal">
                <Briefcase className="stroke-charcoal" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={loanTypeSafe === 'EQ' ? 'Equipment Financing' : 'Working Capital'}
              secondary="(Loan Type)"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar className="!bg-dawn color-charcoal border border-charcoal">
                <CalendarDays className="stroke-charcoal" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${loanTerms} months`} secondary="(Loan Term)" />
          </ListItem>
          {businessName && (
            <ListItem>
              <ListItemAvatar>
                <Avatar className="!bg-dawn color-charcoal border border-charcoal">
                  <Building2 className="stroke-charcoal" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={businessName} secondary="(Applying Business)" />
            </ListItem>
          )}
          {businessRevenue && (
            <ListItem>
              <ListItemAvatar>
                <Avatar className="!bg-dawn color-charcoal border border-charcoal">
                  <Banknote className="stroke-charcoal" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={currency(businessRevenue)} secondary="(Business Revenue)" />
            </ListItem>
          )}
          {yearsInBusiness && (
            <ListItem>
              <ListItemAvatar>
                <Avatar className="!bg-dawn color-charcoal border border-charcoal">
                  <CalendarDays className="stroke-charcoal" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${yearsInBusiness} years`} secondary="(Time in Business)" />
            </ListItem>
          )}
        </List>
        {dealUpdateError && (
          <Alert severity="error">
            <AlertTitle>Failed to save</AlertTitle>
            <DataError msg="Unfortunately, we couldn't save your update." error={dealUpdateError} />
          </Alert>
        )}

        <ContinueButton
          showLock
          data-testid="info_continue"
          btnText="Submit Application"
          saving={dealUpdateLoading}
          onClick={async () => {
            const dealFormattedBody = {
              isUserSubmitted: true,
              business: {
                id: business_id,
              },
              loanRequest: {
                id: loan_id,
              },
            };
            const response = await updateOpportunity({ data: dealFormattedBody });

            if (response) {
              history.push('/app/decision/process');
            }
          }}
        />
      </Stack>
    </div>
  );
};

const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    business_id: appSelector(state, 'business_id'),
    loan_id: appSelector(state, 'loan_id'),
    partnerCustomFields: state.partnerCustomFields,
    vendor_url: state.brand?.data?.vendor_url,
    ownerEmail: appSelector(state, 'owner_1_email'),
    loanAmount: appSelector(state, 'loan_amount'),
    loanType: appSelector(state, 'loan_type') || ['WC'],
    loanTerms: appSelector(state, 'loan_terms'),
    businessName: appSelector(state, 'business_name'),
    businessRevenue: appSelector(state, 'business_revenue'),
    yearsInBusiness: appSelector(state, 'business_years'),
    user: state.user,
  };
};

const SummaryContainerWrapped = connect(mapStateToProps)(SummaryContainer);

export default SummaryContainerWrapped;
