import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Helmet } from 'react-helmet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { sendBankStatementsLater } from '../../../api/sendBankStatementsLater';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { Button } from 'shared-components';

const BankEmail = (props) => {
  const { saving, match, loan_id, first, dataLayerPush, setTemp, history } = props;
  const flags = useFlags();
  const dispatch = useDispatch();
  const [{ data: sendLaterResponse, loading: sendLaterLoading, error: sendLaterError }, sendLater] =
    sendBankStatementsLater();

  useEffect(() => {
    if (sendLaterResponse) {
      setTemp({ haveStatements: false });
      dispatch(change('application', 'have_statements', false));
      dispatch(change('application', 'type_of_statements', 'email'));
      history.push('/app/summary');
    }
  }, [sendLaterResponse]);
  return (
    <div className="app-section slide BankEmailSlide">
      <Helmet>
        <title>Email Bank Statements - Your Loan Application</title>
      </Helmet>
      <Stack inline gap={ELEMENTS_GAP} templateColumns="minmax(0, 1fr)">
        <FormPageTopPart
          headingText="Email Statements"
          subHeadingText="We will need your bank statments to complete the loan approval process."
        />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const { property } = match.params;
            const post = {
              loan_id,
              first,
              property,
            };
            sendLater({ data: post });
            dataLayerPush('manual-statements', post);
          }}
        >
          <Stack gap={ELEMENTS_GAP} templateColumns="minmax(0, 1fr)">
            <p className="t5" data-testid="email-statement-content">
              If you prefer to email your statements <b className="med_dark_text">click the Complete button below</b>.
              We'll send you a confirmation email that you can reply to with them attached.
            </p>

            {sendLaterError && (
              <Alert severity="error">
                <AlertTitle>Failed to save</AlertTitle>
                <DataError
                  msg="Unfortunately, we couldn't save your preference. Please try again. Make sure you have filled in all of the previous steps."
                  error={sendLaterError}
                />
              </Alert>
            )}
            <ContinueButton
              btnText="Complete"
              data-testid="btn-complete"
              invalid={false}
              saving={saving || sendLaterLoading}
            />
            {(flags.applyStatementWebUploadEnabled ||
              flags.applyStatementWebUploadEnabled ||
              process.env.NODE_ENV === 'test' ||
              window.IS_STORYBOOK === '1') && (
              <>
                <div className="bank-options-wrap">
                  <p>We recommend the following options:</p>
                  <div className="bank-options-link-wrap">
                    {(flags.plaidConnect || process.env.NODE_ENV === 'test') && (
                      <Link to={'/app/bank/connect'}>
                        <Button variant="primary-white" data-testid="btn-connect-bank-account">
                          CONNECT BANK ACCOUNT
                        </Button>
                      </Link>
                    )}

                    {(flags.applyStatementWebUploadEnabled ||
                      process.env.NODE_ENV === 'test' ||
                      window.IS_STORYBOOK === '1') && (
                      <Link to={'/app/bank/upload'}>
                        <Button variant="primary-white" data-testid="btn-direct-upload">
                          DIRECT UPLOAD
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
                {(flags.applyStatementWebUploadEnabled ||
                  process.env.NODE_ENV === 'test' ||
                  window.IS_STORYBOOK === '1') && (
                  <Link data-testid="link-upload-statement-manually" className="connect-link" to={`/app/bank/upload`}>
                    Upload my statements manually
                  </Link>
                )}
              </>
            )}
          </Stack>
        </form>
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
    user: state.user,
    theme: state.theme,
  };
};

const BankEmailWrapped = connect(mapStateToProps)(BankEmail);

export default BankEmailWrapped;
