import React, { useEffect, useState } from 'react';
import { setTemp } from '@store/actions';
import { Button, Stack, StepCard } from 'shared-components';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { sendBankStatementsLater } from '../../../api/sendBankStatementsLater';

export const SkipConnectCard = ({ asText, match, loan_id, first, dataLayerPush, history, nextRoute }) => {
  const [{ data: sendLaterResponse, loading: sendLaterLoading, error: sendLaterError }, sendLater] =
    sendBankStatementsLater();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sendLaterResponse) {
      setTemp({ haveStatements: false });
      dispatch(change('application', 'have_statements', false));
      // dispatch(change('application', 'type_of_statements', 'email')); // Final confirmation on this on step 8. There we will set that.
      nextRoute();
    }
  }, [sendLaterResponse]);

  const onSkipClick = () => {
    const { property } = match.params;
    const post = {
      loan_id,
      first,
      property,
    };
    sendLater({ data: post });
    dataLayerPush('manual-statements', post);
  };

  if (asText) {
    return (
      <>
        <Button variant="text" onClick={onSkipClick}>
          Skip For Now
        </Button>
        {sendLaterError && (
          <Alert severity="error">
            <AlertTitle>Failed to save</AlertTitle>
            <DataError
              msg="Unfortunately, we couldn't save your preference. Please try again. Make sure you have filled in all of the previous steps."
              error={sendLaterError}
            />
          </Alert>
        )}
      </>
    );
  }

  return (
    <StepCard
      type="current"
      headline="Skip This Step for Now"
      textBody={
        <>
          Not ready yet? No problem. Skip this step for now and we’ll get you connected later.
          {sendLaterError && (
            <Alert severity="error">
              <AlertTitle>Failed to save</AlertTitle>
              <DataError
                msg="Unfortunately, we couldn't save your preference. Please try again. Make sure you have filled in all of the previous steps."
                error={sendLaterError}
              />
            </Alert>
          )}
        </>
      }
      cardProps={{ className: 'w-full justify-between' }}
      buttonProps={{
        onClick: onSkipClick,
        children: 'Skip for Now',
        disabled: !!sendLaterLoading,
        className: 'w-full max-w-[240px] font-condensed',
        isLoading: !!sendLaterLoading,
      }}
      button="Skip for Now"
    />
  );
};
