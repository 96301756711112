import React from 'react';
import Years from './questions/Years';
import Revenue from './questions/Revenue';
import Phone from './questions/Phone';
import AddressBusiness from './questions/AddressBusiness';
import HomeBased from './questions/HomeBased';
import Naics from './questions/Naics';
import Structure from './questions/Structure';
import FedTaxId from './questions/FedTaxId';
import Website from './questions/Website';

let BusinessQuestion = (props) => {
  const { question } = props;

  switch (question) {
    case 'years':
      return <Years {...props} />;

    case 'revenue':
      return <Revenue {...props} />;

    case 'phone':
      return <Phone {...props} />;

    case 'address':
      return <AddressBusiness {...props} />;

    case 'based':
      return <HomeBased {...props} />;

    case 'naics':
      return <Naics {...props} />;

    case 'structure':
      return <Structure {...props} />;

    case 'ein':
      return <FedTaxId {...props} />;

    case 'website':
      return <Website {...props} />;

    default:
      return null;
  }
};

export default BusinessQuestion;
