import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  const auth0 = useAuth0();
  if (!user.loggedIn) {
    auth0.loginWithRedirect({
      appState: {
        returnTo: '/app/verify/login',
      },
    });
  }
  return <Route {...rest} render={(props) => user.loggedIn && <Component {...props} {...rest} />} />;
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
