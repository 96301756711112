import React, { FC } from 'react';
import { Field } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { normalizeSsn, maskSsn } from '_fields/normalizers';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Stack } from 'shared-components';

export const SsnInput: FC<{ change: (e: any, a: string) => void; ownerIdx: string; autoFocus?: boolean }> = ({
  change,
  ownerIdx,
  autoFocus = false,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleChange = (event: any) => {
    change(`owner_${ownerIdx}_ssn_masked`, maskSsn(event.target.value, true));
  };
  const handleClickShowSsn = () => setShowPassword((show) => !show);

  const handleMouseDownSsn = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Stack flow="column" templateColumns="1fr 0px" className="w-full max-w-[368px]" gapPx={'0px'} alignItems="start">
      <Field
        label="Social Security Number*"
        component={newThemeInput}
        type={showPassword ? 'text' : 'password'}
        name={`owner_${ownerIdx}_ssn`}
        onChange={handleChange}
        // Hard to implement: (alters value="" on the input and feeds ***-***-** into redux)
        // format={(ssnNum: string) => maskSsn(ssnNum, showPassword)}
        normalize={normalizeSsn}
        formatAsSsn
        autoFocus={autoFocus}
      />
      <IconButton
        aria-label="toggle ssn visibility"
        onClick={handleClickShowSsn}
        onMouseDown={handleMouseDownSsn}
        edge="end"
        className="absolute right-8 top-0"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </Stack>
  );
};
