import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';
import { UseAxiosResult } from 'axios-hooks';

export const ENDPOINT_SEND_CONTACT_FORM = `${backendBaseUrl}/tools/contact-form`;

export const sendContactForm = (): UseAxiosResult =>
  useAxios(
    {
      url: ENDPOINT_SEND_CONTACT_FORM,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );
