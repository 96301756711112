// src/stories/Auth0MockProvider.js

import React, { createContext, useContext } from 'react';
import { Auth0Context } from '@auth0/auth0-react';

const Auth0MockContext = createContext();

export const useAuth0 = () => useContext(Auth0MockContext);

export const defaultAuth0State = {
  isLoading: false,
  isAuthenticated: false,
  getAccessTokenSilently: () => null,
  getAccessTokenWithPopup: () => null,
  getIdTokenClaims: () => null,
  loginWithRedirect: () => null,
  loginWithPopup: () => null,
  logout: () => null,
  handleRedirectCallback: () => null,
  buildAuthorizeUrl: () => null,
  buildLogoutUrl: () => null,
};

// Mock provider component
export const Auth0MockProvider = ({ children, isAuthenticated, isLoading }) => {
  return (
    <Auth0Context.Provider value={{ ...defaultAuth0State, isAuthenticated, isLoading }}>
      {children}
    </Auth0Context.Provider>
  );
};
