import { PropsWithChildren, useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth0 } from '@auth0/auth0-react';

export const LaunchDarklyWrapper = (props: PropsWithChildren) => {
  const { user } = useAuth0();
  const ldClient = useLDClient();
  useEffect(() => {
    if (ldClient) {
      ldClient.identify({
        kind: 'user',
        key: user ? user.email : 'anonymous',
        name: user ? user.name : undefined,
        email: user ? user.email : undefined,
      });
    }
  }, [user, ldClient]);

  return props?.children ?? null;
};
