import { z } from 'zod';

export const validateReduxForm = (schema: z.ZodSchema) => (values: any) => {
  try {
    schema.parse(values);
    return {}; // return an empty object if validation is successful
  } catch (error: any) {
    if (error instanceof z.ZodError) {
      return error.flatten().fieldErrors;
    }
    return { _error: 'There was an unexpected validation error.' };
  }
};
