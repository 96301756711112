import './ContinueButton.scss';
import React from 'react';
import { Button } from 'shared-components';

const ContinueButton = (props) => {
  const { btnText, invalid, saving, showLock, onClick, buttonProps, ...otherProps } = props;

  const buttonText = btnText || 'Continue';
  const onClickHandler = onClick ? { onClick } : {};

  return (
    <div className="wrap flex align-center justify-center">
      <Button
        variant="cta-form"
        color="primary"
        type="submit"
        disabled={invalid || saving}
        isLoading={saving}
        {...onClickHandler}
        data-testid={otherProps['data-testid']}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ContinueButton;
