import type { Meta } from '@storybook/react';
import { Select } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Form/Select',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const SelectComponent = () => (
  <div style={{ width: 300 }}>
    <Select
      selectValueProps={{ placeholder: 'Business Address' }}
      options={[
        { value: '1', text: 'test 1' },
        { value: '2', text: 'test 2' },
        { value: '1299 Lombard Street, San Francisco, C', text: '1299 Lombard Street, San Francisco, C' },
      ]}
    />
  </div>
);

export const Selected = () => (
  <div style={{ width: 300 }}>
    <Select
      selectValueProps={{ placeholder: 'Business Address' }}
      defaultValue={'1'}
      options={[
        { value: '1', text: 'test 1' },
        { value: '2', text: 'test 2' },
      ]}
    />
  </div>
);
