import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_POST_ACCOUNT = `${backendBaseUrl}/accounts`;

export const postLoaneeAccount = () =>
  useAxios(
    {
      url: ENDPOINT_POST_ACCOUNT,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );
