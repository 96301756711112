import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { handleSignOut } from '@utils/reduxUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { Heading2 } from '@components/typography/Heading2';

let Signout = (props) => {
  const auth0 = useAuth0();

  handleSignOut(props.dispatch);

  return (
    <div className="app-section">
      <Heading2>Thank You!</Heading2>

      <p>You are now signed out. We have automatically cleared sensitive data from your browser.</p>

      <p>
        If for any reason you need to sign back in you can do so here.{' '}
        <Link
          onClick={() =>
            auth0.loginWithRedirect({
              appState: {
                returnTo: '/app/verify/login',
              },
            })
          }
        >
          Sign in
        </Link>
      </p>
    </div>
  );
};

Signout = reduxForm({
  form: 'login',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Signout);

export default connect()(Signout);
