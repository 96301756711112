import React from 'react';
import { Field } from 'redux-form';
import { muiCustomSelect, newThemeInput } from '_fields/inputs';
import { words } from '_fields/normalizers';
import { Button, Stack } from 'shared-components';

const AddressManual = (props) => {
  const { addressKey, addressType, manualAddress, toggleManualAddress } = props;
  const showManualAddressFields = manualAddress ? true : false;
  const manualToggleText = manualAddress ? 'Use Address Search Tool' : 'Enter Address Manually';
  const suiteUnitLabel = addressType === 'Business' ? 'Suite (optional)' : 'Unit (optional)';

  return (
    <React.Fragment>
      {manualAddress && (
        <Stack
          gapVariant="input-to-input"
          flow="row"
          templateColumns="1fr"
          alignItems="center"
          justifyItems="center"
          className={showManualAddressFields ? `grid` : 'hidden'}
        >
          <Field
            name={`${addressKey}_street`}
            component={newThemeInput}
            type="text"
            label="Street"
            normalize={words}
            readOnly={!manualAddress}
            autoFocus
            data-testid="adress_manual_street"
          />
          <Stack
            flow="column"
            templateColumns="1fr 1fr"
            className="gap-2 w-full max-w-[368px]"
            gapVariant="input-to-input"
            alignItems="start"
          >
            <Field
              name={`${addressKey}_suite`}
              component={newThemeInput}
              type="text"
              label={suiteUnitLabel}
              className="mr"
              normalize={words}
              readOnly={!manualAddress}
              data-testid="adress_manual_suite"
            />

            <Field
              name={`${addressKey}_city`}
              component={newThemeInput}
              type="text"
              label="City"
              normalize={words}
              readOnly={!manualAddress}
              data-testid="adress_manual_city"
            />
          </Stack>
          <Stack
            flow="column"
            templateColumns="1fr 1fr"
            className="gap-2 w-full max-w-[368px]"
            gapVariant="input-to-input"
            alignItems="start"
          >
            <Field
              name={`${addressKey}_state`}
              component={muiCustomSelect}
              label="State*"
              readOnly={!manualAddress}
              data-testid="adress_manual_state"
              selectValueProps={{ placeholder: 'Select value' }}
              options={[
                { value: 'AL', text: 'Alabama' },
                { value: 'AK', text: 'Alaska' },
                { value: 'AZ', text: 'Arizona' },
                { value: 'AR', text: 'Arkansas' },
                { value: 'CA', text: 'California' },
                { value: 'CO', text: 'Colorado' },
                { value: 'CT', text: 'Connecticut' },
                { value: 'DE', text: 'Delaware' },
                { value: 'DC', text: 'District Of Columbia' },
                { value: 'FL', text: 'Florida' },
                { value: 'GA', text: 'Georgia' },
                { value: 'HI', text: 'Hawaii' },
                { value: 'ID', text: 'Idaho' },
                { value: 'IL', text: 'Illinois' },
                { value: 'IN', text: 'Indiana' },
                { value: 'IA', text: 'Iowa' },
                { value: 'KS', text: 'Kansas' },
                { value: 'KY', text: 'Kentucky' },
                { value: 'LA', text: 'Louisiana' },
                { value: 'ME', text: 'Maine' },
                { value: 'MD', text: 'Maryland' },
                { value: 'MA', text: 'Massachusetts' },
                { value: 'MI', text: 'Michigan' },
                { value: 'MN', text: 'Minnesota' },
                { value: 'MS', text: 'Mississippi' },
                { value: 'MO', text: 'Missouri' },
                { value: 'MT', text: 'Montana' },
                { value: 'NE', text: 'Nebraska' },
                { value: 'NV', text: 'Nevada' },
                { value: 'NH', text: 'New Hampshire' },
                { value: 'NJ', text: 'New Jersey' },
                { value: 'NM', text: 'New Mexico' },
                { value: 'NY', text: 'New York' },
                { value: 'NC', text: 'North Carolina' },
                { value: 'ND', text: 'North Dakota' },
                { value: 'OH', text: 'Ohio' },
                { value: 'OK', text: 'Oklahoma' },
                { value: 'OR', text: 'Oregon' },
                { value: 'PA', text: 'Pennsylvania' },
                { value: 'RI', text: 'Rhode Island' },
                { value: 'SC', text: 'South Carolina' },
                { value: 'SD', text: 'South Dakota' },
                { value: 'TN', text: 'Tennessee' },
                { value: 'TX', text: 'Texas' },
                { value: 'UT', text: 'Utah' },
                { value: 'VT', text: 'Vermont' },
                { value: 'VA', text: 'Virginia' },
                { value: 'WA', text: 'Washington' },
                { value: 'WV', text: 'West Virginia' },
                { value: 'WI', text: 'Wisconsin' },
                { value: 'WY', text: 'Wyoming' },
              ]}
            />

            <Field
              name={`${addressKey}_zip`}
              component={newThemeInput}
              type="text"
              label="Zipcode"
              normalize={words}
              readOnly={!manualAddress}
              data-testid="adress_manual_zip"
            />
          </Stack>
        </Stack>
      )}

      <Stack templateColumns="1fr" className="max-w-[368px]" fullWidth>
        <Button
          type="button"
          variant="text"
          className="underline p-0 text-[15px] w-full h-[25px]"
          onClick={toggleManualAddress}
          data-testid="toggle_manual_address"
        >
          {manualToggleText}
        </Button>
      </Stack>
    </React.Fragment>
  );
};

export default AddressManual;
