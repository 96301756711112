import React from 'react';
import { withRouter } from 'react-router-dom';
import Terms from './Terms';
import Sign from './Sign';
import AgreementUpload from './Upload';
import AgreementProblem from './Problem';
import './Agreement.scss';

let Agreement = (props) => {
  switch (props.match.params.property) {
    case 'terms':
      return <Terms {...props} />;
    case 'sign':
      return <Sign {...props} />;
    case 'upload':
      return <AgreementUpload {...props} />;
    case 'problem':
      return <AgreementProblem {...props} />;
    default:
      return null;
  }
};

export const AgreementWithRouter = withRouter(Agreement);

export default AgreementWithRouter;
