import type { Meta } from '@storybook/react';
import { Input } from 'shared-components';
import React, { useLayoutEffect, useRef } from 'react';
import { Text } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Form/Input',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const Inactive = () => (
  <div style={{ width: 300 }}>
    <Input placeholder="Inactive Form Field" />
  </div>
);
export const Active = () => (
  <div style={{ width: 300 }}>
    <Input type="active" placeholder="Active Form Field" />
  </div>
);
export const Completed = () => (
  <div style={{ width: 300 }}>
    <Input defaultValue="Completed Form Field" placeholder="Complete Form Field" />
  </div>
);
export const Completed2 = () => (
  <div style={{ width: 300 }}>
    <Input value="$150,000" placeholder="Complete Form Field" />
  </div>
);
export const Error = () => {
  const ref = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    ref.current?.setCustomValidity('invalid value!');
  }, []);

  return (
    <div style={{ width: 300 }}>
      <Input ref={ref} placeholder="Federal Tax ID" />
    </div>
  );
};

export const SmallInactive = () => <Input variant="small" placeholder="SM Form" />;
export const SmallActive = () => <Input type="active" variant="small" placeholder="SM" />;
export const SmallCompleted = () => <Input type="completed" variant="small" placeholder="SM" />;
export const SmallError = () => {
  const ref = useRef<HTMLInputElement | null>(null);

  useLayoutEffect(() => {
    ref.current?.setCustomValidity('invalid value!');
  }, []);

  return <Input ref={ref} variant="small" placeholder="SM" />;
};
export const Responsive = () => (
  <div>
    <Text variant="body-form-active">Small {`(<100px)`}</Text>
    <Input placeholder="SM" className="w-[90px]" />
    <Text variant="body-form-active">Large {`(>100px)`}</Text>
    <Input placeholder="SM" className="w-[300px]" />
  </div>
);
