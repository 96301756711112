import { axiosInstance } from '../api/axiosInstance';
import { backendUrl, backendBaseUrl } from '../utils/platformBasedInfo';

export const loadBrand = async (brand) => {
  const response = await axiosGet('brands/' + brand);

  console.log('response', response);

  return response;
};

const axiosGet = async (path) => {
  const endpoint = `${backendBaseUrl}/${path}`;

  console.log('calling endpoint', endpoint);

  const response = await axiosInstance.get(endpoint);
  return response.data;
};
