import { axiosInstance } from '../api/axiosInstance';
import { backendUrl } from '../utils/platformBasedInfo';

// WARNING!!! IF YOU ARE WRITING NEW CODE PLEASE USE:
// - useAxios from src/js/api/axiosInstance.js
// - example of use in src/js/components/Apply/Auth0/index.js
// Sorry to say this but this useless garbage function needs to stay for now...
// unless you want to refactor entire app to have a different base url, and different behavior.
// Very sad that it was implemented this way as it hides very important information from us,
// such as Response object.
// Will be glad if you get rid of this file.
const call = async (
  route,
  data,
  vendor_name,
  user = {},
  { baseUrl = backendUrl, method = 'post' } = { method: 'post', baseUrl: backendUrl }
) => {
  const headers = user && user.token ? { Authorization: 'Bearer ' + user.token } : {};
  const fullRoute = `${baseUrl}/${route}`;
  if (route !== 'brand') {
    if (typeof data !== 'string' && !Array.isArray(data)) {
      data.siteId = vendor_name;
    }
  }
  try {
    const response = await axiosInstance.request({
      url: fullRoute,
      data,
      method,
      headers: headers,
    });

    let result = Object.assign({ status: response.status }, response.data);

    if (!response.data) {
      result.msg = 'response.data was empty';
    }

    if (result.error) {
      // TODO: show error: `Code 1. serviceApi.call result error. [${result.msg}]`
    }

    return result;
  } catch (error) {
    return {
      status: error?.response?.status ?? error?.status ?? 500,
      error: true,
      level: 'warning',
      feedback: true,
      msg: error.response ? error.response.statusText : 'Unknown - Possible Network Error',
    };
  }
};

export const apiService = {
  call,
};
