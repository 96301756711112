import './Owners.scss';
import React from 'react';
import Question from './Question';

const Owners = (props) => {
  const { match, saving, onSubmit } = props;
  const question = match.params.property;
  const { idx } = match.params;

  return (
    <Question
      theme={props.theme}
      match={match}
      question={question}
      history={props.history}
      ownerIdx={idx}
      change={props.change}
      onSubmit={onSubmit}
      saving={saving}
      nextRoute={props.nextRoute}
      loanType={props.loanType}
    />
  );
};

export default Owners;
