import { BackArrow } from '@components/_shared/AppBar/BackArrow';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';
import { RootState } from '@store/reducers';

const BackButtonBare: FC<
  RouteComponentProps<{
    step: string;
    property: string;
  }> & { hideBackArrow: boolean; finalWizardRoutes: RootState['wizardRouting']['finalWizardRoutes'] }
> = ({ hideBackArrow, match, history, finalWizardRoutes }) => {
  const location = useLocation();

  let navAction = () => {
    if (match.params.step === 'agreement' && match.params.property === 'sign') {
      history.replace('/app/agreement/terms');
    } else {
      const index = finalWizardRoutes.findIndex((r: any) => `/app/${r}` === location.pathname);
      if (index >= 0) {
        if (index === 0) {
          history.replace('/');
        } else {
          const previousRoute = finalWizardRoutes[index - 1];
          if (previousRoute === 'verify/login' || previousRoute === 'app/select') {
            history.replace(`/app/${finalWizardRoutes[0]}`);
          } else {
            history.goBack();
          }
        }
      } else {
        history.goBack();
      }
    }
  };
  const isMobile = useMediaQuery('(max-width:425px)');
  return (
    <BackArrow
      hide={hideBackArrow || match.params.step === 'done'}
      showText={true}
      onClick={() => navAction()}
      color={'var(--color-charcoal)'}
    />
  );
};

export const BackButton = withRouter(BackButtonBare);
