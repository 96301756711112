import StorybookReduxWrapper from '../../../../../.storybook/storybook-utils/StorybookWrapper';
import { App as Page } from '../../../App';

// const meta: Meta<> = {
const meta = {
  title: '001-Apply Frontend/Entire App/steps/Step 2 - Amount',
  component: Page,
  argTypes: {},
  decorators: [StorybookReduxWrapper({ routerProps: { initialEntries: ['/app/amount'] }, loanType: 'EQ' })],
};

export default meta;
// type Story = StoryObj<typeof Page>;

// export const App: Story = {
export const Step2Amount = {
  args: {},
};
