import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import Indicator from '../Indicator';
import Address from '@components/_shared/Address/Address';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import ContinueButton from '@components/_shared/Continue/Button';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from 'shared-components';

let AddressOwner = (props) => {
  const { handleSubmit, match, invalid, saving, ownerIdx, theme } = props;
  const continueButton =
    match.params.property === 'add' ? null : (
      <ContinueButton invalid={invalid} saving={saving} data-testid="question-submit-button" />
    );

  const form = useForm();

  const address = {
    street: props.street,
    suite: props.suite,
    city: props.city,
    state: props.state,
    zip: props.zip,
  };

  return (
    <div className="app-section slide">
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <Stack gapVariant="content-to-submit-button">
            <Stack gapVariant="head-section-to-content">
              <Helmet>
                <title>Owner Address - Your Loan Application</title>
              </Helmet>
              <FormPageTopPart
                headingText="What is your home address?"
                subHeadingText="Look up address or enter manually"
              />
              <Stack gapVariant="input-to-input" justifyItems="center" templateColumns="1fr" fullWidth>
                <Indicator ownerIdx={ownerIdx} />
                <Address
                  address={address}
                  change={props.change}
                  theme={theme}
                  ownerIdx={ownerIdx}
                  showDefaultManualAddress={
                    address.city?.length > 0 && address.street?.length > 0 && address.state?.length > 0
                  }
                />
              </Stack>
            </Stack>
            {continueButton}
          </Stack>
        </form>
      </Form>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state, ownProps) => {
  return {
    street: selector(state, `owner_${ownProps.ownerIdx}_street`),
    suite: selector(state, `owner_${ownProps.ownerIdx}_suite`),
    city: selector(state, `owner_${ownProps.ownerIdx}_city`),
    state: selector(state, `owner_${ownProps.ownerIdx}_state`),
    zip: selector(state, `owner_${ownProps.ownerIdx}_zip`),
  };
};

AddressOwner = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps)(AddressOwner));

export default AddressOwner;
