import './Info.scss';
import React, { FC, useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { checkBox, newThemeInput } from '_fields/inputs';
import { phone, lowerCase, words } from '_fields/normalizers';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { validateUserByEmail } from '../../../api/validateUserByEmail';
import { Link } from '@material-ui/core';
import { formValueSelector } from 'redux-form';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Spinner } from '@components/Spinner/Spinner';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';
import { ApplyWizardSteps, STEP_INFO } from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { Auth0LoggedInSetState } from '../../../Auth0LoggedInSetState';

type EnhancedInfoProps = {
  partnerCustomFields: any;
  vendor_url: string;
  ownerEmail: string;
  loanType: string;
  saving: boolean;
  handleSubmit: () => void;
  invalid: boolean;
  user: RootState['user'];
  owner_1_first?: string;
  owner_1_last?: string;
  owner_1_mobile?: string;
};

const EnhancedInfo: FC<EnhancedInfoProps> = (props) => {
  const auth0 = useAuth0();

  if (auth0.isLoading) {
    return (
      <div className="app-section slide infoSLide">
        <Spinner />
      </div>
    );
  }

  return <EnhancedInfoInner {...props} />;
};
const EnhancedInfoInner: FC<EnhancedInfoProps> = ({
  handleSubmit,
  invalid,
  saving,
  partnerCustomFields,
  vendor_url,
  ownerEmail,
  loanType,
  user,
  owner_1_first,
  owner_1_last,
  owner_1_mobile,
}) => {
  const auth0 = useAuth0();

  const form = useForm();
  const [{ data: validateUserResponse, loading: validateUserLoading, error: validateUserError }, validateUser] =
    validateUserByEmail();
  const privacyLink = `${vendor_url}/privacy-policy`;
  const termsLink = `${vendor_url}/terms`;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (validateUserResponse) {
      if (validateUserResponse.userWithThisEmailExists === false) {
        handleSubmit();
      } else if (validateUserResponse.userWithThisEmailExists === true) {
        setError(true);
      }
    }
  }, [validateUserResponse]);

  return (
    <div>
      <Helmet>
        <title>Basic Info - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <Auth0LoggedInSetState />
        <FormPageTopPart headingText="Let's Start with Some Basic Info" />
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setError(false);
              if (auth0.isAuthenticated && user.email) {
                handleSubmit();
              } else {
                validateUser({ data: { email: ownerEmail } });
              }
            }}
            className="slideWidth"
          >
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  name="business_name"
                  component={newThemeInput}
                  type="text"
                  placeholder="Company Name*"
                  normalize={words}
                  label="Business Name*"
                  data-testid="business_name"
                />

                <Field
                  name="owner_1_first"
                  component={newThemeInput}
                  type="text"
                  label="First Name*"
                  placeholder="First Name*"
                  normalize={words}
                  data-testid="owner_1_first"
                />
                <Field
                  name="owner_1_last"
                  component={newThemeInput}
                  type="text"
                  label="Last Name*"
                  placeholder="Last Name*"
                  normalize={words}
                  data-testid="owner_1_last"
                />

                <Field
                  name="owner_1_mobile"
                  component={newThemeInput}
                  label="Mobile Phone*"
                  placeholder="Mobile Phone*"
                  formatAsPhone
                  data-testid="owner_1_mobile"
                />

                <Field
                  name="owner_1_email"
                  component={newThemeInput}
                  type="email"
                  label={auth0.isAuthenticated && user.email ? 'Email (disabled)' : 'Email*'}
                  placeholder="Email*"
                  normalize={lowerCase}
                  data-testid="owner_1_email"
                  disabled={!!(auth0.isAuthenticated && user.email)}
                />
                {/* {auth0.isAuthenticated && user.email && (
                  <div className="input-caption">You are logged in with this email.</div>
                )} */}
                {error && (
                  <div className="text-[#FF0000] font-bold">
                    This email is already registered with us. Please{' '}
                    <Link
                      onClick={() =>
                        auth0.loginWithRedirect({
                          appState: {
                            returnTo: '/app/verify/login',
                          },
                          authorizationParams: {
                            screen_hint: 'signup',
                            login_hint: ownerEmail,
                            ...(owner_1_first
                              ? {
                                  'user[given_name]': owner_1_first,
                                }
                              : {}),
                            ...(owner_1_last
                              ? {
                                  'user[family_name]': owner_1_last,
                                }
                              : {}),
                            ...(owner_1_mobile
                              ? {
                                  'user[phone_number]': owner_1_mobile,
                                }
                              : {}),
                          },
                        })
                      }
                      className="!text-[#0000EE] !underline"
                    >
                      login
                    </Link>
                  </div>
                )}
                <div className="consent-box">
                  <Field
                    component={checkBox}
                    id="consent"
                    name="consent"
                    data-testid="consent_check"
                    children={
                      <label htmlFor="consent">
                        I agree to the{' '}
                        <a target="_blank" href={termsLink} className="!no-underline !text-moss" tabIndex={-1}>
                          terms and conditions
                        </a>{' '}
                        and{' '}
                        <a target="_blank" href={privacyLink} className="!no-underline !text-moss" tabIndex={-1}>
                          privacy policy
                        </a>
                        .
                      </label>
                    }
                  />
                </div>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={invalid}
                  saving={saving || validateUserLoading}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const Info = reduxForm<EnhancedInfoProps, EnhancedInfoProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_INFO].zodSchema),
})(EnhancedInfo);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    partnerCustomFields: state.partnerCustomFields,
    vendor_url: state.brand?.data?.vendor_url,
    ownerEmail: appSelector(state, 'owner_1_email'),
    user: state.user,
    owner_1_first: appSelector(state, 'owner_1_first'),
    owner_1_last: appSelector(state, 'owner_1_last'),
    owner_1_mobile: appSelector(state, 'owner_1_mobile'),
  };
};

const InfoStepView = connect(mapStateToProps)(Info);

export default InfoStepView;
