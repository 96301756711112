import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter, useLocation } from 'react-router-dom';
import BankUpload from '@components/Apply/Bank/Upload';
import { formValueSelector, getFormValues, change } from 'redux-form';
import { setTemp } from '@store/actions';
import { useDispatch } from 'react-redux';
import CompleteIcon from '_icons/CompleteIcon';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { useAuth0 } from '@auth0/auth0-react';
import { ENDPOINT_SAVE_FILE } from '../../../api/fileSave';
import { Button } from 'shared-components';

const ContinueDirectUploadOnly = ({ loan_type, first, loan_id, loan_amount, setTemp, dataLayerPush, theme }) => {
  const dealId = useParams()?.dealId;
  const accountId = useParams()?.accountId;
  const mainContactId = useParams()?.mainContactId;
  const dispatch = useDispatch();
  const [saved, setSaved] = useState(false);
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    dispatch(change('application', 'business_id', accountId));
    dispatch(change('application', 'loan_id', dealId));
    dispatch(change('application', `owner_1_id`, mainContactId));
  }, []);

  return !saved ? (
    <BankUpload
      {...{ loan_type, first, loan_id, loan_amount }}
      dataLayerPush={dataLayerPush}
      setTemp={setTemp}
      withOtherOptions={false}
      openAlert={() => {}}
      closeAlert={() => {}}
      withDealUpdate={false}
      dataMapper={(files) => {
        return {
          files,
          dealId,
          accountId,
          contactId: mainContactId,
        };
      }}
      customEndpoint={`${ENDPOINT_SAVE_FILE}-continue`}
      customOnSaved={() => {
        setSaved(true);
      }}
    />
  ) : (
    <div className="app-section slide">
      <Stack gap={ELEMENTS_GAP} className="decision">
        <FormPageTopPart headingText="Congratulations!" icon={<CompleteIcon fill={theme.primary} />} />

        <div className="left">
          <p>Files successfully sent.</p>
          <p>We will begin working on your request shortly.</p>
        </div>

        {isAuthenticated && <Button variant="primary-green">Logout</Button>}
      </Stack>
    </div>
  );
};
const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    loan_type: selector(state, 'loan_type'),
    config: state.config,
    route_home: state.brand?.data?.route_home,
    theme: state.theme,
    user: state.user,
    first: selector(state, 'owner_1_first'),
    loan_id: selector(state, 'loan_id'),
    loan_amount: selector(state, 'loan_amount'),
  };
};

export const ContinueDirectUploadOnlyContainer = connect(mapStateToProps, { setTemp })(
  withRouter(ContinueDirectUploadOnly)
);

export default ContinueDirectUploadOnlyContainer;
