import type { Meta } from '@storybook/react';
import { Button } from 'shared-components';
import { ArrowUp } from 'shared-components';
import { ArrowLeft } from 'shared-components';
import { ArrowDown } from 'shared-components';
import { ArrowRight } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Button',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const PrimaryGreen = () => <Button variant="primary-green">Primary Green</Button>;
export const PrimaryWhite = () => <Button variant="primary-white">Primary White</Button>;
export const Secondary = () => <Button variant="secondary">Secondary</Button>;
export const Tertiary = () => <Button variant="tertiary">Tertiary</Button>;
export const Text = () => (
  <Button variant="text" className="uppercase">
    Text
  </Button>
);
export const CTA = () => <Button variant="cta-form">Cta Form</Button>;
export const CTADisabled = () => (
  <Button variant="cta-form" disabled>
    Cta Form Disabled
  </Button>
);
export const CTAActive = () => <Button variant="cta-active">Active</Button>;
export const CTAInactive = () => <Button variant="cta-inactive">Inactive</Button>;
export const CTASmallPrimary = () => (
  <Button variant="cta-small-primary" className="p-0 w-[50px] h-[50px]">
    OR
  </Button>
);
export const CTASmallSecondary = () => (
  <Button variant="cta-small-secondary" className="p-0 w-[50px] h-[50px]">
    OR
  </Button>
);
export const CTASmallPrimaryIcon = () => (
  <div className="flex gap-4 space-2">
    <Button variant="cta-small-primary" className="p-0 w-[50px] h-[50px]">
      <ArrowUp />
    </Button>
    <Button variant="cta-small-primary" className="p-0 w-[50px] h-[50px]">
      <ArrowDown />
    </Button>
    <Button variant="cta-small-primary" className="p-0 w-[50px] h-[50px]">
      <ArrowLeft />
    </Button>
    <Button variant="cta-small-primary" className="p-0 w-[50px] h-[50px]">
      <ArrowRight />
    </Button>
  </div>
);
export const CTASmallSecondaryIcon = () => (
  <div className="flex gap-4 space-2">
    <Button variant="cta-small-secondary" className="p-0 w-[50px] h-[50px]">
      <ArrowUp />
    </Button>
    <Button variant="cta-small-secondary" className="p-0 w-[50px] h-[50px]">
      <ArrowDown />
    </Button>
    <Button variant="cta-small-secondary" className="p-0 w-[50px] h-[50px]">
      <ArrowLeft />
    </Button>
    <Button variant="cta-small-secondary" className="p-0 w-[50px] h-[50px]">
      <ArrowRight />
    </Button>
  </div>
);
