import './Bank.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { PlaidLink } from 'react-plaid-link';
import { Link } from 'react-router-dom';
import { ModalManager, Effect } from 'react-dynamic-modal';
import { BasicModal, AccountBody } from '@components/_shared/Modals/Modals';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { cn } from '@utils/cn';
import { Helmet } from 'react-helmet';
import { Spinner } from '@components/Spinner/Spinner';
import { plaidConnect } from '../../../api/plaidConnect';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { plaidLinkToken } from '../../../api/plaidLinkToken';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BankConnect = ({
  loanType,
  showProcessingModal,
  loan_id,
  loan_amount,
  first,
  setTemp,
  business_id,
  customConnectEndpoint,
  customLinkTokenEndpoint,
  customOnConnect,
  withLinkToOtherMethods = true,
}) => {
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';
  console.log('customLinkTokenEndpoint', customLinkTokenEndpoint);
  console.log('customConnectEndpoint', customConnectEndpoint);
  const history = useHistory();
  const flags = useFlags();
  const [{ data: plaidConnectResponse, loading: plaidConnectLoading, error: plaidConnectError }, triggerPlaidConnect] =
    plaidConnect();
  const [
    { data: plaidLinkTokenResponse, loading: plaidLinkTokenLoading, error: plaidLinkTokenError },
    generatePlaidLinkToken,
  ] = plaidLinkToken();

  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [metadata, setMetadata] = useState();
  const title =
    loanTypeSafe === 'WC'
      ? 'Please connect your bank account for an instant loan decision.'
      : 'Please connect your bank account so that we can review your statements and get you funded.';

  useEffect(() => {
    if (plaidConnectResponse) {
      setTemp({ haveStatements: true });
      dispatch(change('application', 'have_statements', true));
      dispatch(change('application', 'type_of_statements', 'plaid'));

      ModalManager.close();

      if (customOnConnect) {
        customOnConnect(plaidConnectResponse);
      } else {
        history.push('/app/summary');
      }
    }
  }, [plaidConnectResponse]);

  useEffect(() => {
    if (plaidConnectError) {
      ModalManager.close();
    }
  }, [plaidConnectError]);

  const connectBank = async (metadata, mainAccountId, publicToken) => {
    showProcessingModal(
      'We are processing your application',
      'This step can take several minutes, please do not click back on your internet browser or refresh this page.'
    );

    triggerPlaidConnect({
      data: {
        loan_id: loan_id,
        loan_type: loanTypeSafe,
        amount: loan_amount,
        first: first,
        bank_accounts: metadata,
        main_account_id: mainAccountId,
        public_token: publicToken,
      },
      ...(customConnectEndpoint ? { url: customConnectEndpoint } : {}),
    });
  };

  useEffect(() => {
    generatePlaidLinkToken({
      data: {
        accountId: business_id,
      },
      ...(customLinkTokenEndpoint ? { url: customLinkTokenEndpoint } : {}),
    });
  }, []);

  useEffect(() => {
    if (plaidLinkTokenError) {
      console.error('Error generating plaid link token', plaidLinkTokenError);
    }
  }, [plaidLinkTokenError]);

  useEffect(() => {
    if (plaidLinkTokenResponse && plaidLinkTokenResponse.link_token) {
      setToken(plaidLinkTokenResponse.link_token);
    }
  }, [plaidLinkTokenResponse]);

  const handleExit = (error, metadata) => {
    if (error !== null) {
      setError('Error connecting to bank account. Please try again or choose to upload statements manually.');
    }
    if (metadata?.accounts && metadata.accounts.length === 0) {
      setError('No bank accounts connected. Please make sure to give access to at least one checking account.');
    }
  };

  const handleEvent = (event, metadata) => {
    if (event === 'ERROR' || event === 'error') {
      setError('Error connecting to bank account. Please try again.');
    }
  };

  const accountSelect = (mainAccountId) => {
    ModalManager.close();
    connectBank(metadata, mainAccountId);
  };

  const openAccountSelection = (accounts) => {
    const title = 'Please select your primary business checking account:';
    const body = <AccountBody accounts={accounts} itemClick={accountSelect} />;
    ModalManager.open(
      <BasicModal effect={Effect.RotateFromBottom3D} title={title} body={body} onRequestClose={() => false} />
    );
  };

  const handleSuccess = (token, metadata) => {
    if (metadata.accounts) {
      let checkingAccounts = metadata.accounts.filter((account) => {
        return account.subtype === 'checking';
      });

      if (checkingAccounts.length === 0) {
        setError('No checking accounts found. Please connect bank account with a checking account.');
      } else {
        console.log('checkingAccounts', checkingAccounts);
        connectBank(metadata, checkingAccounts[0].id, token);
        setMetadata(metadata);

        // REMOVED because: Remove that other screen we found when there is more than one checking account, just select the one (the 1st done) and continue.
        // openAccountSelection(checkingAccounts);
      }
    } else {
      setError('No accounts found. Please make sure to give access to at least one checking account.');
    }
  };
  return (
    <div className="app-section slide">
      <Helmet>
        <title>Connect Bank Account - Your Loan Application</title>
      </Helmet>
      <Stack gap={ELEMENTS_GAP}>
        <FormPageTopPart headingText="Connect your Bank account" subHeadingText={title} />
        <div className="flex justify-center align-center">
          <img src={'https://s3.amazonaws.com/media.tento.co/apply/img/connect.svg'} />
        </div>
        <p className="security-msg">
          *We use a secure API to transmit your bank statements, it does not provide us access to your account.
        </p>
        {plaidConnectError && (
          <Alert severity="error">
            <AlertTitle>Failed to connect</AlertTitle>
            <DataError msg="Unfortunately, connecting bank account failed." error={plaidConnectError} />
          </Alert>
        )}
        {error && <div style={{ color: '#ed4337' }}>{error}</div>}
        <div className="plaid-button-wrap">
          {token ? (
            <PlaidLink
              token={token}
              className={cn(
                '!inline-flex !items-center !justify-center !whitespace-nowrap !rounded-[22.834px] !text-sm !font-medium !ring-offset-background !transition-colors !focus-visible:outline-none !focus-visible:ring-2 !focus-visible:ring-ring !focus-visible:ring-offset-2 !disabled:pointer-events-none !disabled:opacity-50',
                '!rounded-[22.834px] !pt-[17.5px] !pb-[17.5px]',
                '!px-8',
                '!font-bold',
                '!text-[17px]',
                '!min-w-[80%]',
                'btn',
                !token ? '!disabled' : ''
              )}
              onExit={handleExit}
              onEvent={handleEvent}
              onSuccess={handleSuccess}
            >
              Connect My Account
            </PlaidLink>
          ) : plaidLinkTokenError ? (
            <Alert severity="error">
              <AlertTitle>Connect disabled</AlertTitle>
              <DataError
                msg="Unfortunately, connecting bank account is now disabled. Please choose to upload statements manually. Sorry for the inconvenience."
                error={plaidLinkTokenError}
              />
            </Alert>
          ) : (
            <Spinner />
          )}
        </div>

        {withLinkToOtherMethods && (
          <>
            {flags.applyStatementWebUploadEnabled || process.env.NODE_ENV === 'test' || window.IS_STORYBOOK === '1' ? (
              <Link data-testid="upload-statement-manually" className="connect-link" to={`/app/bank/upload`}>
                Upload my statements manually
              </Link>
            ) : (
              <Link data-testid="btn-bank-email" className="muted" to="/app/bank/email">
                I can't connect right now
              </Link>
            )}
          </>
        )}
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
    loanType: selector(state, 'loan_type'),
    theme: state.theme,
  };
};

const BankConnectWrapped = connect(mapStateToProps)(BankConnect);

export default BankConnectWrapped;
