import numeral from 'numeral';

export const cleanSsnToDigitsAndStars = (input) => {
  return input.replace(/[^0-9*]/g, '');
};

/* ALL CAPS NORMALIZER */
export const allCaps = (value, previousValue) => {
  if (!value) {
    return '';
  }

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    return value.toUpperCase();
  }

  return value.toUpperCase();
};

/* ALL lowercase NORMALIZER */
export const lowerCase = (value, previousValue) => {
  if (!value) {
    return '';
  }

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    return value.toLowerCase();
  }

  return value.toLowerCase();
};

export const words = (value) => {
  return value.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

/* CURRENCY NORMALIZER */
export const currency = (value, previousValue) => {
  if (!value) {
    return '';
  }

  return numeral(value).format('$0,0');
};

/* CURRENCY WITH DECIMAL NORMALIZER */
export const currencyDecimal = (value, previousValue) => {
  if (!value) {
    return '';
  }

  return numeral(value).format('$0,0.00');
};

/* PHONE NUMBER NORMALIZER */
export const phone = (value, previousValue) => {
  if (!value) {
    return '';
  }

  const valueNumeric = value.replace(/[^\d]/g, '');

  if (valueNumeric.length <= 3) {
    return valueNumeric;
  }
  if (valueNumeric.length <= 6) {
    return valueNumeric.slice(0, 3) + '-' + valueNumeric.slice(3, 6);
  }

  return valueNumeric.slice(0, 3) + '-' + valueNumeric.slice(3, 6) + '-' + valueNumeric.slice(6, 10);
};

/* FED TAX ID NORMALIZER */
export const normFedTaxId = (value, previousValue) => {
  if (!value) {
    return '';
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 2) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 9) {
      return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 9);
    }
  }

  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 9);
};

/* DOB NORMALIZER */
export const dob = (value, previousValue) => {
  if (!value) {
    return '';
  }

  const valueNumeric = value.replace(/[^\d]/g, '');

  if (valueNumeric.length <= 2) {
    return valueNumeric;
  }
  if (valueNumeric.length <= 4) {
    return valueNumeric.slice(0, 2) + '/' + valueNumeric.slice(2, 4);
  }

  return valueNumeric.slice(0, 2) + '/' + valueNumeric.slice(2, 4) + '/' + valueNumeric.slice(4, 8);
};

/* VERIFY CODE NORMALIZER */
export const verifyCode = (value, previousValue) => {
  if (!value) {
    return '';
  }
  const onlyNums = value.replace(/[^\d]/g, '');

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 4) {
      return onlyNums.slice(0, 4);
    }
  }

  if (onlyNums.length <= 4) {
    return onlyNums;
  }
};

/* SSN NORMALIZER */
export const normalizeSsn = (value, prevValue) => {
  return maskSsn(value);
};

export const formatOwnershipPercentage = (value) => {
  if (!value) {
    return '';
  }

  value = (value ? parseFloat(value) : '').toString().replace(/[^0-9]/g, '');

  // Check if the value ends with a percentage sign
  if (value && value !== '') {
    // If not, add the percentage sign
    value = '% ' + value;
  }

  return value;
};

export const maskSsn = (ssn, stars = false, showLast4 = true) => {
  // strip non-numeric characters
  const ssnNum = ssn ? cleanSsnToDigitsAndStars(ssn) : '';

  // if stars = true, replace numeric digits with asterisks
  const mask = stars ? ssnNum?.replace(/[\d]/g, '*') : ssnNum;

  // allow dash as input
  if (ssnNum.length === 3 && ssn[3] === '-') {
    return `${mask.slice(0, 3)}-`;
  }

  // return asterisks
  if (mask.length <= 3) {
    return mask;
  }

  // allow dash as input
  if (ssnNum.length === 5 && ssn[6] === '-') {
    return `${mask.slice(0, 3)}-${mask.slice(3, 5)}-`;
  }

  // return dashed asterisks
  if (mask.length <= 5) {
    return `${mask.slice(0, 3)}-${mask.slice(3, 5)}`;
  }

  if (showLast4) {
    return `${mask.slice(0, 3)}-${mask.slice(3, 5)}-${ssnNum.slice(5, 9)}`;
  } else {
    return `${mask.slice(0, 3)}-${mask.slice(3, 5)}-${mask.slice(5, 9)}`;
  }
};
