import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import OffersPending from './OffersPending';
import OffersLoaded from './OffersLoaded';
import { Helmet } from 'react-helmet';

const Offers = ({ theme, temp, contactId, loanType, checkOffers }) => {
  useEffect(() => {
    if (!temp?.offersLoaded || !temp?.offers?.loanOffers || temp?.offers?.loanOffers.length === 0) {
      history.replace('/app/decision/declined');
    }
  }, []);
  useEffect(() => {
    // Obsolete now - we are getting offers loaded always upon submition of the form (/decision/personal)
    if (
      !process.env.STORYBOOK_TEST &&
      temp?.pendingOffers?.pendingResponses &&
      temp?.pendingOffers?.pendingResponses.length > 0 &&
      !temp?.offersLoaded &&
      !temp?.offers?.loanOffers
    ) {
      checkOffers();
    }
  }, []);

  return (
    <div className="app-section slide">
      <Helmet>
        <title>Decision Offers - Your Loan Application</title>
      </Helmet>
      <div className="form-width">
        {temp?.offersLoaded ? (
          <OffersLoaded offers={temp?.offers?.loanOffers} theme={theme} contactId={contactId} loanType={loanType} />
        ) : (
          <OffersPending offers={temp?.pendingOffers?.pendingResponses} theme={theme} loanType={loanType} />
        )}
      </div>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state, ownProps) => {
  return {
    contactId: selector(state, 'owner_1_id'),
    temp: state.temp,
    theme: state.theme,
  };
};

const OffersWrapped = connect(mapStateToProps)(Offers);

export default OffersWrapped;
