import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import CloseIcon from '_icons/CloseIcon';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Paragraph } from '@components/typography/Paragraph';
import { Helmet } from 'react-helmet';
import { Button, Text } from 'shared-components';

const Frozen = (props) => {
  return (
    <div className="app-section slide">
      <Helmet>
        <title>Decision - Credit Frozen - Your Loan Application</title>
      </Helmet>
      <Stack gap={ELEMENTS_GAP} className="decision">
        <FormPageTopPart
          headingText="Credit Freeze"
          subHeadingText="To proceed with your application, we kindly ask you to temporarily lift or remove the freeze on your credit. You can do this by contacting the credit bureau where the freeze was placed."
        />

        {/* {props.first && <Paragraph>{props.first},</Paragraph>} */}
        <Text variant="subheadline-lg">
          Once you have lifted the freeze, please return to the application and resubmit.
        </Text>
        <Link to="/app/funding-is-almost-here">
          <Button variant="primary-white">Return to App</Button>
        </Link>
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    first: selector(state, 'owner_1_first'),
  };
};

export default connect(mapStateToProps)(Frozen);
