import React, { FC } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Toggle, newThemeInput } from '_fields/inputs';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Helmet } from 'react-helmet';
import { Stack, Text } from 'shared-components';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import { ApplyWizardSteps, STEP_TYPE_OF_FUNDING } from '@components/ApplyWizard/ApplyWizardSteps';
import { useForm } from 'react-hook-form';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

type TypeOfFundingProps = {
  handleSubmit: () => void;
  invalid: boolean;
  loanType?: string;
  saving?: boolean;
};

const TypeOfFundingWrapper2: FC<TypeOfFundingProps> = ({ handleSubmit, invalid, loanType, saving: savingOnSubmit }) => {
  const form = useForm();
  const { saving } = useApplyWizardContext();
  const hasEq = Array.isArray(loanType) ? loanType.some((val) => val?.toUpperCase() === 'EQ') : false;

  return (
    <div>
      <Helmet>
        <title>Select Type of Funding</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="What type of funding do you need?" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapPx="30px" justifyItems="center" templateColumns="1fr" fullWidth>
                <Text variant="subheadline-lg" className="text-center">
                  Choose Funding Type
                </Text>
                <Field
                  component={Toggle}
                  className="w1024:grid-cols-5"
                  name="loan_type"
                  defaultValue={loanType}
                  buttons={[
                    { text: `Working Capital`, value: 'WC' },
                    { text: `Line of Credit`, value: 'LOC' },
                    { text: `Equipment Financing`, value: 'EQ' },
                    { text: `Business Credit Card`, value: 'BCC' },
                    { text: `Personal Loan`, value: 'PL' },
                  ]}
                  allowMultiple
                />
                {hasEq && (
                  <Field
                    name="equipment_description"
                    component={newThemeInput}
                    type="text"
                    placeholder="Enter equipment description"
                    labelText="Equipment name"
                    labelClassName="infoFormCustomLabel"
                    data-testid="equipment_description"
                    className="equipment-description"
                  />
                )}
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={invalid}
                  saving={saving || savingOnSubmit}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const TypeOfFundingWrapper1 = reduxForm<TypeOfFundingProps, TypeOfFundingProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_TYPE_OF_FUNDING].zodSchema),
})(TypeOfFundingWrapper2);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    loanType: appSelector(state, 'loan_type'),
  };
};

export const TypeOfFunding = withOnSubmit(connect(mapStateToProps)(TypeOfFundingWrapper1), STEP_TYPE_OF_FUNDING);
