import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';
import { UseAxiosResult } from 'axios-hooks';

export const ENDPOINT_SAVE_FILE = `${backendBaseUrl}/files/save`;

export const fileSave = (): UseAxiosResult => {
  const [{ data, loading, error }, update] = useAxios(
    {
      url: ENDPOINT_SAVE_FILE,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );

  return [{ data, loading, error }, update, () => {}];
};
