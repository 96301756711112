import { SSNSchema } from '@components/validation/ssn';

export const required = (value) => (value ? undefined : 'Required');

export const emailAddress = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid' : undefined;

export const phoneNumber = (value) =>
  value && !/^\d{3}-\d{3}-\d{4}$/i.test(value) ? 'Invalid, must be 10 digits' : undefined;

export const fedTaxId = (value) =>
  value && !/^\d{2}-\d{7}$/i.test(value) ? 'Invalid, must be xx-xxxxxxx format' : undefined;

export const dob = (value) =>
  value && !/^\d{2}\/\d{2}\/\d{4}$/i.test(value) ? 'Invalid, must be mm/dd/yyyy format' : undefined;

export const ssn = (value) => {
  if (value && !/^(\d{3}|\*{3})-(\d{2}|\*{2})-\d{4}$/i.test(value)) {
    return 'Invalid, must be xxx-xx-xxxx format';
  }

  try {
    const result = SSNSchema.parse(value?.toString());
    return undefined;
  } catch (error) {
    return 'Invalid SSN. Please type a real SSN';
  }
};

export const nonEmptyString = (value) => {
  if (!value.trim()) {
    return 'Invalid, cannot be an empty string.';
  }

  return undefined;
};
