import './FormPageTopPart.scss';
import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Text } from 'shared-components';

export const FormPageTopPart: FC<{
  headingText?: string;
  subHeadingText?: string | ReactNode;
  icon?: ReactNode;
  subheadingProps?: Partial<ComponentPropsWithoutRef<typeof Text>>;
}> = ({ headingText, subHeadingText, subheadingProps, icon }) => {
  return (
    <Stack gap={ELEMENTS_GAP} className="text-center">
      {icon && <div className="flex justify-center align-center">{icon}</div>}
      {headingText && <Text variant="headline-lg">{headingText}</Text>}
      {subHeadingText && (
        <Text variant="subheadline-lg" {...(subheadingProps ? { ...subheadingProps } : {})}>
          {subHeadingText}
        </Text>
      )}
    </Stack>
  );
};
