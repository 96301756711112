import axios from 'axios';
import { mockSegment } from './mocks/segment.mock';
import { mockInfoSave } from './mocks/infoSave.mock';
import { mockBusinessDecision } from './mocks/businessDecision.mock';
import { mockVerifyRequest } from './mocks/verifyRequest.mock';
import { mockVerifySubmit } from './mocks/verifySubmit.mock';
import { mockBusiness } from './mocks/business.mock';
import { mockNaicsSearch } from './mocks/naicsSearch.mock';
import { mockBrand } from './mocks/brand.mock';
import { mockIpCountry } from './mocks/ipCountry.mock';
import { mockPlaidConnect } from './mocks/plaidConnect.mock';
import { mockPlaidLinkToken } from './mocks/plaidLinkToken.mock';
import { mockSendStatementsLater } from './mocks/sendStatementsLater.mock';
import { mockSaveFiles } from './mocks/saveFiles.mock';
import { configure, makeUseAxios } from 'axios-hooks';
import MockAdapter from 'axios-mock-adapter';
import { mockUserValidate } from './mocks/userValidate.mock';
import { useStore } from 'react-redux';
import { useCancelableAxios } from './useCancellableAxios';

export const axiosInstance = axios.create({});
axiosInstance.interceptors.response.use(
  (id) => id,
  async (error) => {
    const originalRequest = error.config;
    try {
      error.message =
        error.message +
        `(${
          error.response?.data?.message ||
          error.response?.data?.error ||
          error.request.responseText ||
          JSON.stringify(error.response.data)
        })`;
    } catch (e) {}
    return Promise.reject(error);
  }
);
const useAxiosMocked = makeUseAxios({
  axios: axiosInstance,
});

if (process.env.STORYBOOK_TEST) {
  console.log('MOCKING DATA RETURNED FROM BACKEND FOR STORYBOOK TESTING!');
  const mockAxios = new MockAdapter(axiosInstance);
  mockNaicsSearch(mockAxios);
  mockSegment(mockAxios);
  mockInfoSave(mockAxios);
  mockVerifyRequest(mockAxios);
  mockVerifySubmit(mockAxios);
  mockBusiness(mockAxios);
  mockBrand(mockAxios);
  mockUserValidate(mockAxios);
  mockBusinessDecision(mockAxios);
  mockIpCountry(mockAxios);
  mockPlaidConnect(mockAxios);
  mockPlaidLinkToken(mockAxios);
  mockSendStatementsLater(mockAxios);
  mockSaveFiles(mockAxios);
} else {
  configure({
    axios: axiosInstance,
  });
}
export const useAxios = (...hookProps) => {
  const { getState } = useStore();
  const token = getState()?.user?.token;
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  return process.env.STORYBOOK_TEST ? useAxiosMocked(...hookProps) : useCancelableAxios(...hookProps);
};
