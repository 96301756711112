import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { words } from '_fields/normalizers';
import { newThemeInput } from '_fields/inputs';
import { required } from '_fields/validation';
import Indicator from '../Indicator';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { InputAdornment } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ContinueButton from '@components/_shared/Continue/Button';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { z } from 'zod';
import { validateReduxForm } from '@components/shadcn/components/ui/validate-redux-form';
import { Stack } from 'shared-components';

const getSchema = (idx) => {
  const titleKey = `owner_${idx}_title`;
  const ownershipKey = `owner_${idx}_ownership`;

  return z.object({
    [titleKey]: z.string().min(1, 'Job title is required'),
    [ownershipKey]: z
      .string()
      .regex(/^\d+$/, 'Ownership must be a positive number or zero')
      .transform((str) => Number(str))
      .refine((num) => num >= 0 && num <= 100, {
        message: 'Ownership must be between 0% and 100%',
      }),
  });
};

let Ownership = (props) => {
  const { handleSubmit, match, invalid, saving, ownerIdx } = props;
  const continueButton =
    match.params.property === 'add' ? null : (
      <ContinueButton invalid={invalid} saving={saving} data-testid="question-submit-button" />
    );

  const form = useForm({
    // resolver: zodResolver(getSchema(ownerIdx)),
    // defaultValues: initialValues,
  });

  return (
    <div className="app-section slide">
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <Stack gapVariant="head-section-to-content" className="slideWidth">
            <Helmet>
              <title>Ownership & Title - Your Loan Application</title>
            </Helmet>
            <FormPageTopPart headingText="Whats is your title & ownership" />
            <Stack gapVariant="content-to-submit-button">
              <Stack gapVariant="input-to-input">
                <Indicator ownerIdx={ownerIdx} />
                <Field
                  data-testid={`owner-${ownerIdx}-title`}
                  name={`owner_${ownerIdx}_title`}
                  component={newThemeInput}
                  type="text"
                  normalize={words}
                  validate={[required]}
                  label="What is your job title?"
                  autoFocus
                />

                <Field
                  name={`owner_${ownerIdx}_ownership`}
                  data-testid={`owner-${ownerIdx}-ownership`}
                  component={newThemeInput}
                  // validate={[required]}
                  disabled={false}
                  labelText="What % of the business do you own?"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Stack>
              {continueButton}
            </Stack>
          </Stack>
        </form>
      </Form>
    </div>
  );
};

const OwnershipWrapped = (props) => {
  const Component = useMemo(
    () =>
      reduxForm({
        form: 'application',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        validate: validateReduxForm(getSchema(props.ownerIdx)),
      })(connect()(Ownership)),
    [props.ownerIdx]
  );
  return <Component {...props} />;
};

export default OwnershipWrapped;
