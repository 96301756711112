import React from 'react';
import OwnerInfo from './questions/OwnerInfo';
import Ownership from './questions/Ownership';
import AddressOwner from './questions/AddressOwner';
import Identity from './questions/Identity';
import AddOwner from './questions/AddOwner';

let Question = (props) => {
  const { question } = props;
  switch (question) {
    case 'info':
      return <OwnerInfo {...props} />;

    case 'ownership':
      return <Ownership {...props} />;

    case 'address':
      return <AddressOwner {...props} />;

    case 'identity':
      return <Identity {...props} />;

    case 'add':
      return <AddOwner {...props} />;

    default:
      return null;
  }
};

export default Question;
