import React from 'react';
import packageJson from '../../package.json';
import { connect } from 'react-redux';
import { RootState } from '@store/reducers';

const Version = ({ brand }: { brand: RootState['brand'] }) => {
  return (
    <div className="text-xs text-center text-gray-500">
      <p>
        <b>Version</b>: {packageJson?.version}
      </p>
      <p className="mt-4">
        <b>Brand</b>: {JSON.stringify(brand, null, 3)}
      </p>
      <p className="mt-4">
        <b>package.json engines</b>: {JSON.stringify(packageJson?.engines, null, 3)}
      </p>
      <p className="mt-4">
        <b>package.json dependencies</b>: {JSON.stringify(packageJson?.dependencies, null, 3)}
      </p>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    brand: state.brand,
  };
};

const VersionWrapped = connect(mapStateToProps)(Version);

export default VersionWrapped;
