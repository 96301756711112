import React from 'react';
import { Field } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { required } from '_fields/validation';
import { lowerCase } from '_fields/normalizers';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

const Website = (props) => {
  return (
    <Stack gapVariant="head-section-to-content" className="slideWidth" data-testid="business-website-page">
      <Helmet>
        <title>Business Website - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="What is your business website?" />
      <Field
        data-testid="business-website"
        name="business_website"
        component={newThemeInput}
        type="text"
        placeholder="https://"
        validate={[required]}
        normalize={lowerCase}
        autoFocus
        className=""
      />
    </Stack>
  );
};

export default Website;
