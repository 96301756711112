import StorybookReduxWrapper from '../../../../.storybook/storybook-utils/StorybookWrapper';
import { App as Page } from '../../App';

// const meta: Meta<> = {
const meta = {
  title: '001-Apply Frontend/Entire App/Flow 0 - Initial Flow/Equipment Loan/Not Logged In/AppEquipment',
  component: Page,
  argTypes: {},
  decorators: [StorybookReduxWrapper({ routerProps: { initialEntries: ['/app/equipment'] }, loanType: 'EQ' })],
};

export default meta;
// type Story = StoryObj<typeof Page>;

// export const AppEquipment: Story = {
export const AppEquipment = {
  args: {},
};
