import React from 'react';

export const ArrowRight = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 12.285L12.285 9L9 5.715L8.055 6.66L9.72 8.325H5.625V9.675H9.72L8.055 11.34L9 12.285ZM9 18C7.77 18 6.6075 17.7638 5.5125 17.2913C4.4175 16.8188 3.46125 16.1737 2.64375 15.3563C1.82625 14.5388 1.18125 13.5825 0.70875 12.4875C0.23625 11.3925 0 10.23 0 9C0 7.755 0.23625 6.585 0.70875 5.49C1.18125 4.395 1.82625 3.4425 2.64375 2.6325C3.46125 1.8225 4.4175 1.18125 5.5125 0.70875C6.6075 0.23625 7.77 0 9 0C10.245 0 11.415 0.23625 12.51 0.70875C13.605 1.18125 14.5575 1.8225 15.3675 2.6325C16.1775 3.4425 16.8188 4.395 17.2913 5.49C17.7638 6.585 18 7.755 18 9C18 10.23 17.7638 11.3925 17.2913 12.4875C16.8188 13.5825 16.1775 14.5388 15.3675 15.3563C14.5575 16.1737 13.605 16.8188 12.51 17.2913C11.415 17.7638 10.245 18 9 18Z"
        fill="var(--color-charcoal)"
      />
    </svg>
  );
};

export default ArrowRight;

<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M11.082 12.8906L17.7991 6.94308L11.082 0.995539"
    stroke="white"
    stroke-width="1.14172"
    stroke-linecap="round"
  />
  <path d="M16.9569 6.93945H1.00391" stroke="white" stroke-width="1.14172" stroke-linecap="round" />
</svg>;
