import { store } from '@store/store';
import { setBrand, setBrandFailure } from '../redux/actions';
import { loadBrand } from '../services/backend.service';
import { appEnviroment, platformName } from './platformBasedInfo';

export const fetchBrand = async () => {
  const params = {
    enviroment: appEnviroment,
    brand: platformName,
  };
  const response = await loadBrand(platformName);

  if (!response?.error) {
    store.dispatch(setBrand(response));
  } else {
    store.dispatch(setBrandFailure('FAILED TO LOAD BRAND'));
    console.error('FAILED TO LOAD BRAND');
  }
};
