import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field } from 'redux-form';
import { muiCustomSelect } from '_fields/inputs';
import { required } from '_fields/validation';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

const Years = (props) => {
  const years = props.years && Number(props.years) > 20 ? Number(props.years) : 20;
  return (
    <>
      <Helmet>
        <title>Years in Business - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content" className="slideWidth">
        <FormPageTopPart headingText="More Details on Your Business" />
        <Stack gapVariant="input-to-input" alignItems="center" justifyItems="center" templateColumns="1fr">
          <Field
            name="business_years"
            component={muiCustomSelect}
            label="Years in business*"
            className="w100 mui-input-custom mui-select tib-select"
            validate={[required]}
            autoFocus
            data-testid="business_years"
            selectValueProps={{ placeholder: 'Years in Business' }}
            options={[
              { value: 0, text: 'under 1 year' },
              { value: 1, text: '1 year' },
              { value: 2, text: '2 years' },
              { value: 3, text: '3 years' },
              { value: 4, text: '4 years' },
              { value: 5, text: '5 years' },
              { value: 6, text: '6 years' },
              { value: 7, text: '7 years' },
              { value: 8, text: '8 years' },
              { value: 9, text: '9 years' },
              { value: 10, text: '10 years' },
              { value: 11, text: '11 years' },
              { value: 12, text: '12 years' },
              { value: 13, text: '13 years' },
              { value: 14, text: '14 years' },
              { value: 15, text: '15 years' },
              { value: 16, text: '16 years' },
              { value: 17, text: '17 years' },
              { value: 18, text: '18 years' },
              { value: 19, text: '19 years' },
              { value: 20, text: '20+ years' },
            ]}
          />
        </Stack>
      </Stack>
    </>
  );
};

const selector = formValueSelector('application');
const mapStateToProps = (state, ownProps) => {
  return {
    years: selector(state, 'business_years'),
  };
};

const YearsFieldWrapped = connect(mapStateToProps)(Years);

export default YearsFieldWrapped;
