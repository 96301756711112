import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

const Indicator = (props) => {
  let { ownerIdx, first, last } = props;

  if (ownerIdx > 1) {
    first = typeof first !== 'undefined' ? first : '';
    last = typeof last !== 'undefined' ? last : '';

    const ownerNumber = `Owner #${ownerIdx}`;
    const ownerName = first.length > 0 ? ` (${first} ${last})` : '';

    return (
      <div className="owner-indicator">
        {ownerNumber}
        <span>{ownerName}</span>
      </div>
    );
  } else {
    return null;
  }
};

const selector = formValueSelector('application');

const mapStateToProps = (state, ownProps) => {
  return {
    first: selector(state, `owner_${ownProps.ownerIdx}_first`),
    last: selector(state, `owner_${ownProps.ownerIdx}_last`),
  };
};

const IndicatorWrapped = connect(mapStateToProps)(Indicator);

export default IndicatorWrapped;
