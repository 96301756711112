import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer, { RootState } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);
const composeEnhancer =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as keyof Window] &&
    window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as keyof Window]({
      trace: true,
      traceLimit: 25,
    })) ||
  composeWithDevTools;

export const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)));
export const getStoreForStorybook = (initialState: any) =>
  createStore(persistedReducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export const persistor = persistStore(store);
