import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { setTemp } from '@store/actions';
import Bank from './Bank';
import devicer from '../../../../../external-libs/devicer.min.js';
import { platformName } from '../../../utils/platformBasedInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Spinner } from '@components/Spinner/Spinner';

const BankContainer = ({
  showProcessingModal,
  loan_id,
  loanType,
  loan_amount,
  first,
  vendor_name,
  callApi,
  dataLayerPush,
  setTemp,
  history,
  match,
  user,
  ...other
}) => {
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';
  const flags = useFlags();
  useEffect(() => {
    if (process.env.STORYBOOK_TEST) return;

    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        eendpoint: process.env.SOCURE_URL,
        userConsent: true,
        context: 'transaction',
      };
      devicer.run(deviceFPOptions, function (response) {});
    }
  }, []);

  if (!flags.includeBankStatementsScreen && !process.env.STORYBOOK_TEST) {
    history.push('/app/summary');

    return (
      <div className="app-section slide">
        <Spinner />
      </div>
    );
  }

  return (
    <Bank
      {...{
        showProcessingModal,
        loan_id,
        loan_type: loanTypeSafe,
        loan_amount,
        first,
        vendor_name,
        dataLayerPush,
        setTemp,
        history,
        match,
        user,
        callApi,
        ...other,
      }}
    />
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    first: selector(state, 'owner_1_first'),
    loan_id: selector(state, 'loan_id'),
    loanType: selector(state, 'loan_type'),
    loan_amount: selector(state, 'loan_amount'),
    theme: state.theme,
    user: state.user,
    vendor_name: state.brand?.data?.vendor_name,
  };
};
const BankContainerWrapped = connect(mapStateToProps, { setTemp })(withRouter(BankContainer));
export default BankContainerWrapped;
