import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_POST_OPPORTUNITY = `${backendBaseUrl}/deals/createInTransaction`;

export const postLoaneeOpportunity = () =>
  useAxios(
    {
      url: ENDPOINT_POST_OPPORTUNITY,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );
