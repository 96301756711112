import React from 'react';
import GearIcon from '_icons/GearIcon';
import { FormPageTopPart } from '../FormPageTopPart/FormPageTopPart';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Stack } from '@components/Stack/Stack';
import { LoadingTento } from 'shared-components';

const OffersPending = (props) => {
  const lenderCount = props.offers.length;
  let delayFactor, duration;

  if (lenderCount < 4) {
    delayFactor = 3 / lenderCount;
    duration = 3;
  } else {
    delayFactor = 1;
    duration = lenderCount;
  }

  return (
    // <div className="flex flex-1 items-center">
    //   <LoadingTento width={undefined} height={undefined} />
    // </div>
    <React.Fragment>
      <Stack gap={ELEMENTS_GAP}>
        <FormPageTopPart
          headingText="Processing..."
          subHeadingText="Checking for offers from these lenders:"
          icon={<GearIcon fill={props.theme.primary} />}
        />

        <div className="animation-wrapper">
          {props.offers.map((lender, idx) => {
            let imgStyle = {
              animationDelay: `${idx * delayFactor}s`,
              animationDuration: `${duration}s`,
            };
            return (
              <img key={lender.partner.uuid} style={imgStyle} className="lender-logo" src={lender.partner.imageUrl} />
            );
          })}
        </div>
      </Stack>
    </React.Fragment>
  );
};

export default OffersPending;
