import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Equipment from './Equipment.jsx';
import { formValueSelector } from 'redux-form';

const EquipmentContainer = ({ setSaving, nextRoute, ...other }) => {
  const saveEquipmentDesc = () => {
    setSaving(true);
    nextRoute();
    setSaving(false);
  };

  return <Equipment onSubmit={saveEquipmentDesc} {...{ setSaving, nextRoute, ...other }} />;
};
const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    loanType: selector(state, 'loan_type') || ['WC'],
  };
};
export default connect(mapStateToProps)(withRouter(EquipmentContainer));
