import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { required, ssn } from '_fields/validation';
import { words, normalizeSsn } from '_fields/normalizers';
import CloseIcon from '_icons/CloseIcon';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Paragraph } from '@components/typography/Paragraph';
import { Helmet } from 'react-helmet';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Stack } from 'shared-components';

const Unable = (props) => {
  const { reCheck, theme, invalid, saving } = props;
  const rhfForm = useForm();

  return (
    <Form {...rhfForm}>
      <div className="app-section slide">
        <Helmet>
          <title>Decision - Unable to verify credit - Your Loan Application</title>
        </Helmet>
        <Stack gapVariant="head-section-to-content" className="decision">
          <FormPageTopPart
            headingText="Incomplete Credit Check"
            subHeadingText="Your application is almost complete, but we were not able to check your credit."
          />
          <Stack gapVariant="content-to-submit-button">
            <Stack gapVariant="input-to-input" templateColumns="1fr" alignItems="center" justifyItems="center">
              <Paragraph>
                Remember, this is a soft pull and will not affect your score, but it’s necessary for us to approve your
                application.
              </Paragraph>

              <Paragraph className="font-bold">Please check the following:</Paragraph>
              <Paragraph className="flex items-center text-left">
                <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
                Verify you entered your legal first and last name correctly
              </Paragraph>
              <Paragraph className="flex items-center text-left">
                <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
                Verify you entered your social security number correctly
              </Paragraph>
              <Paragraph className="flex items-center text-left">
                <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
                Make sure you do not have a freeze placed on your credit
              </Paragraph>
              <Field
                name="owner_1_first"
                component={newThemeInput}
                type="text"
                label="First Name"
                validate={[required]}
                normalize={words}
              />

              <Field
                name="owner_1_last"
                component={newThemeInput}
                type="text"
                label="Last Name"
                validate={[required]}
                normalize={words}
              />

              <Field
                name="owner_1_ssn"
                component={newThemeInput}
                type="text"
                label="SSN"
                validate={[required, ssn]}
                normalize={normalizeSsn}
              />
            </Stack>

            <ContinueButton btnText="Try Again" invalid={invalid} saving={saving} showLock onClick={reCheck} />
          </Stack>
        </Stack>
      </div>
    </Form>
  );
};

const UnableWrapped = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Unable);

export default UnableWrapped;
