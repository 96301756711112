import './Business.scss';
import React from 'react';
import { reduxForm } from 'redux-form';
import BusinessQuestion from './Question';
import ContinueButton from '@components/_shared/Continue/Button';
import SkipStep from '@components/_shared/SkipStep';
import { Form } from '@components/shadcn/components/ui/form';
import { useForm } from 'react-hook-form';
import { Stack } from 'shared-components';

const getSkipText = (question) => {
  switch (question) {
    case 'website':
      return "My business doesn't have a website";
    default:
      return false;
  }
};

let Business = (props) => {
  const form = useForm();
  const { handleSubmit, match, invalid, saving } = props;
  const question = match.params.property;

  return (
    <div className="app-section">
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <Stack gapVariant="content-to-submit-button">
            <BusinessQuestion
              theme={props.theme}
              question={question}
              history={props.history}
              change={props.change}
              invalid={invalid}
              handleSubmit={handleSubmit}
              nextRoute={props.nextRoute}
              naicsSearch={props.naicsSearch}
              naicsResults={props.naicsResults}
              clearNaicsResults={props.clearNaicsResults}
              loanType={props.loanType}
              saving={saving}
            />
            {match.params.property === 'based' ? null : (
              <ContinueButton invalid={invalid} saving={saving} data-testid="business_questions_continue" />
            )}

            <SkipStep skipText={getSkipText(question)} invalid={invalid} nextRoute={props.nextRoute} />
          </Stack>
        </form>
      </Form>
    </div>
  );
};

Business = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Business);

export default Business;
