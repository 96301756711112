const snakeToCamel = (str) => {
  return str.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
};

export const convertKeysToCamelCase = (original) =>
  Object.keys(original).reduce((acc, key) => {
    acc[snakeToCamel(key)] = original[key];
    return acc;
  }, {});

export default convertKeysToCamelCase;
