'use client';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@components/shadcn/components/ui/dropdown-menu';
import { UserAvatar } from '@components/shadcn/components/theme-new/user-avatar';
import React from 'react';
import { reset } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'shared-components';
import { useApplyWizardContext } from '../../../../context/ApplyWizardContext';
import { STEP_HOMEPAGE } from '@components/ApplyWizard/ApplyWizardSteps';

interface UserAccountNavProps extends React.HTMLAttributes<HTMLDivElement> {
  user: any | null;
  handleSignOut: () => void;
}

export function UserAccountNav({ user, handleSignOut }: UserAccountNavProps) {
  const dispatch = useDispatch();
  let location = useLocation();
  const { currentPath } = useApplyWizardContext();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button
          variant="text"
          className={`uppercase font-condensed m-0 p-0 transition-all duration-500 ease-in-out ${
            currentPath === STEP_HOMEPAGE ? 'text-[#ffffff]' : 'text-[#000000]'
          }`}
        >
          My Account
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" id="user-dropdown-menu" className="bg-spark text-black">
        {(user.name || user.email) && (
          <>
            <div className="flex items-center justify-start gap-2 p-2">
              <div className="flex flex-col space-y-1 leading-none">
                {user.name && <p className="font-medium text-left">{user.name}</p>}
                {user.email && (
                  <p className="w-[200px] truncate text-sm text-muted-foreground text-left">{user.email}</p>
                )}
              </div>
            </div>
            <DropdownMenuSeparator />
          </>
        )}
        {/* {(user.isAdmin || user.isPartner) && (
          <DropdownMenuItem asChild>
            <a href="https://volt.tento.co/partner/">Partner Dash</a>
          </DropdownMenuItem>
        )} */}
        {user.isAdmin && (
          <DropdownMenuItem asChild>
            <a href="https://volt.tento.co/admin/">Admin Dash</a>
          </DropdownMenuItem>
        )}
        {/* {!user.isPartner && (
          <DropdownMenuItem asChild>
            <a href="https://volt.tento.co/overview">Customer Dash</a>
          </DropdownMenuItem>
        )} */}
        {(user.isAdmin || user.isPartner) && (
          <DropdownMenuItem asChild>
            <a href="https://volt.tento.co/partner/choose-account">Change Partner</a>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem asChild>
          <a href="/app/select">My Applications</a>
        </DropdownMenuItem>
        {/* <DropdownMenuItem asChild>
          <a href="https://volt.tento.co/settings">Settings</a>
        </DropdownMenuItem> */}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer"
          onSelect={(event: any) => {
            event.preventDefault();
            dispatch(reset('application'));
            handleSignOut();
          }}
        >
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
