import React, { useEffect } from 'react';
import { change, formValueSelector } from 'redux-form';
import { useAuth0 } from '@auth0/auth0-react';
import { axiosInstance } from './api/axiosInstance';
import { auth0AuthroizationParams } from './App';
import { jwtDecode } from 'jwt-decode';
import { setUser } from '@store/actions';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '@store/reducers';

const Auth0LoggedInSetStateInner = ({
  owner_1_first,
  owner_1_last,
  owner_1_mobile,
  ownerEmail,
}: {
  owner_1_first?: string;
  owner_1_last?: string;
  owner_1_mobile?: string;
  ownerEmail?: string;
}) => {
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, handleRedirectCallback } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      console.log('Not authenticated in Auth0LoggedInSetState');
      return;
    }
    (async () => {
      const token = await getAccessTokenSilently({ authorizationParams: auth0AuthroizationParams });
      const idToken = await getIdTokenClaims();
      const decodedToken = jwtDecode(token);
      console.log('decodedToken1', decodedToken);
      console.log('idToken1', idToken);
      const data = {
        user: {
          loggedIn: isAuthenticated,
          token,
          image: idToken?.picture || '',
          email: idToken?.email || '',
          name: idToken?.nickname ?? '',
          firstName: idToken?.given_name || '',
          lastName: idToken?.family_name || '',
          id: (decodedToken as any)?.['https://tento.co/user_id'] || null,
          expires: decodedToken && decodedToken.exp ? decodedToken.exp * 1000 : undefined,
        },
      };

      if (data?.user?.id) {
        analytics.identify(data?.user?.id, {
          name: data.user.name ?? (owner_1_first && owner_1_last) ? `${owner_1_first} ${owner_1_last}` : '',
          email: data.user.email,
          firstName: data.user.firstName ?? owner_1_first,
          lastName: data.user.lastName ?? owner_1_last,
          // plan: "premium",
          // logins: 5
        });
      }

      if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        dispatch(setUser(data));
        dispatch(change('application', 'owner_1_email', idToken?.email));
      }
    })();
  }, [isAuthenticated]);

  return null;
};

const appSelector = formValueSelector('application');
export const Auth0LoggedInSetState = connect((state: RootState) => {
  return {
    ownerEmail: appSelector(state, 'owner_1_email'),
    owner_1_first: appSelector(state, 'owner_1_first'),
    owner_1_last: appSelector(state, 'owner_1_last'),
    owner_1_mobile: appSelector(state, 'owner_1_mobile'),
  };
})(Auth0LoggedInSetStateInner);
