import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './Agreement.scss';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { RootState } from '@store/reducers';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Helmet } from 'react-helmet';
import { Button } from 'shared-components';

export type AgreementProblemProps = {
  confirmTerms: () => Promise<void>;
  saveManualSign: () => Promise<void>;
  saveAgreementUpload: () => Promise<void>;
} & { signingResult?: string };

const AgreementProblem = (props: AgreementProblemProps) => {
  const { saveManualSign, signingResult } = props;
  const message = getMessage(signingResult);

  return (
    <div className="app-section slide">
      <Helmet>
        <title>Agreement Problem - Your Loan Application</title>
      </Helmet>
      <Stack gap={ELEMENTS_GAP}>
        <FormPageTopPart headingText="We didn't get your signature" subHeadingText={message} />
        <Link to={'/app/agreement/terms'}>
          <Button variant="primary-white">Review Terms and Sign</Button>
        </Link>

        <p className="agreement-manual-msg">
          If you're having trouble with electronic signing you can opt for manual followup. You will still need to sign
          a loan agreement, but turn around time will be slower.
        </p>
        <span className="faux-link" onClick={() => saveManualSign()}>
          Manual Followup, Please
        </span>
      </Stack>
    </div>
  );
};

const getMessage = (signingResult?: string) => {
  switch (signingResult) {
    case 'cancel':
      return 'It looks like you cancelled signing. We will need your signed loan agreement to provide the fastest funding time. Click the button below to try again.';
    case 'decline':
      return 'It looks like you declined signing. We will need your signed loan agreement to provide the fastest funding time. Click the button below to try again.';
    case 'exception':
      return 'It looks like there was an error during signing. Please click the button below to try again';
    case 'id_check_failed':
      return 'It looks like the knowledge based verification failed. Please click the button below to try again';
    case 'session_timeout':
      return 'It looks like you were idle too long. Please click the button below to try again';
    case 'ttl_expired':
      return 'It looks like you were idle too long or already opened that document. Please click the button below to try again';
    default:
      return 'An unknown problem occurred. You can try again by clicking below. If this problem persists select the manual signing link below.';
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    signingResult: state.temp.signingResult,
  };
};

const ProblemView = connect(mapStateToProps)(AgreementProblem);

export default ProblemView;
