import { ReduxFormValuesType, RootState } from '@store/reducers';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

export type ApplicationIds = {
  account_id?: string;
  contact_id?: string;
  vendor_id?: string;
  loan_id?: string;
  source?: {
    partnerId?: string;
  };
  business?: {
    id?: string;
  };
};

export const useGetApplicationIds = (): ApplicationIds => {
  const reduxHookFormValues = useSelector((state: RootState) => getFormValues('application')(state)) as
    | ReduxFormValuesType
    | undefined;
  const config = useSelector((state: RootState) => state.config);

  if (!reduxHookFormValues) {
    return {};
  }

  return {
    account_id: reduxHookFormValues.business_id,
    contact_id: reduxHookFormValues.owner_1_id,
    loan_id: reduxHookFormValues.loan_id,
    vendor_id: config?.id,
    source: {
      partnerId: config.id,
    },
    business: {
      id: reduxHookFormValues.business_id,
    },
  };
};
