import React, { Component, createRef, useState } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { newThemeInput } from '_fields/inputs';
import { normFedTaxId } from '_fields/normalizers';
import { required, fedTaxId } from '_fields/validation';
import CloseIcon from '_icons/CloseIcon';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const FedTaxId = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const einInputRef = createRef();

  const toggleModal = () => {
    // refocus ein field
    if (!open) {
      einInputRef.current.focus();
    }
    setOpen(!open);
  };

  let linkClass = classNames({
    'faux-link': true,
  });

  return (
    <Stack gapVariant="head-section-to-content">
      <Helmet>
        <title>Federal Tax ID - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="What is your Federal Tax ID?" />
      <Stack gapVariant="input-to-input" alignItems="center" justifyItems="center" templateColumns="1fr">
        <Field
          data-testid="fed-tax-id"
          name="business_ein"
          component={newThemeInput}
          type="tel"
          label="Tax ID"
          placeholder="XX-XXXXXXX"
          formatAsFederalTaxId
          validate={[required, fedTaxId]}
          focusRef={einInputRef}
          autoFocus
        />

        <div className={linkClass} onClick={toggleModal}>
          Where do I find this?
        </div>

        <Modal
          aria-labelledby="find-federal-tax-id-title"
          aria-describedby="find-federal-tax-id-option1"
          open={open}
          onClose={toggleModal}
          className="mui-modal"
        >
          <div data-testid="fed-tax-modal" style={getModalStyle()} className={classes.paper}>
            <div className="modal-close-icon" data-testid="fed-tax-modal-close" onClick={toggleModal}>
              <CloseIcon fill="#ffffff" width="30" height="30" />
            </div>
            <Typography variant="h6" id="find-federal-tax-id-title">
              If you lost or misplaced your EIN, you can retrieve it in the following ways:
            </Typography>
            <Typography variant="body1" id="find-federal-tax-id-option1">
              Reference the original notice issued by the IRS when you received your EIN or call the IRS Business &amp;
              Specialty Tax Line at <a href="tel:+18008294933">1-800-829-4933</a>.
            </Typography>
            <Typography variant="body1" id="find-federal-tax-id-option2">
              If you used it to open a bank account or get a license, contact these organizations they should be able to
              retrieve your number.
            </Typography>
            <Typography variant="body1" id="find-federal-tax-id-option3">
              Find an old tax return. Your EIN should be on it.
            </Typography>
          </div>
        </Modal>
      </Stack>
    </Stack>
  );
};

const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: '300px',
    backgroundColor: '#fff',
    padding: '30px',
    outline: 'none',
    borderRadius: '15px',
  },
});

const FedTaxIdWrapped = withStyles(styles)(FedTaxId);

export default FedTaxIdWrapped;
