import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter, useLocation } from 'react-router-dom';
import { backendBaseUrl } from '@utils/platformBasedInfo';
import BankConnect from '@components/Apply/Bank/Connect';
import { formValueSelector, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import CompleteIcon from '_icons/CompleteIcon';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { useAuth0 } from '@auth0/auth0-react';
import { setTemp } from '@store/actions';
import { Button } from 'shared-components';

const ContinuePlaidConnectOnly = ({ loan_type, first, loan_id, loan_amount, setTemp, theme }) => {
  const dealId = useParams()?.dealId;
  const accountId = useParams()?.accountId;
  const mainContactId = useParams()?.mainContactId;
  const dispatch = useDispatch();
  const [saved, setSaved] = useState(false);
  const { isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    console.log('values:', { accountId, dealId, mainContactId });
    if (accountId) {
      dispatch(change('application', 'business_id', accountId));
    }
    if (dealId) {
      dispatch(change('application', 'loan_id', dealId));
    }
    if (mainContactId) {
      dispatch(change('application', `owner_1_id`, mainContactId));
    }
  }, []);

  return !saved ? (
    <div>
      <BankConnect
        {...{
          loan_id,
          loan_type,
          loan_amount,
          first,
          setTemp,
          history,
        }}
        showProcessingModal={() => {}}
        customConnectEndpoint={`${backendBaseUrl}/tools/plaid/connect-continue`}
        customLinkTokenEndpoint={`${backendBaseUrl}/tools/plaid/link-token-continue`}
        withLinkToOtherMethods={false}
        customOnConnect={() => {
          setSaved(true);
        }}
      />
    </div>
  ) : (
    <div className="app-section slide">
      <Stack gap={ELEMENTS_GAP} className="decision">
        <FormPageTopPart headingText="Congratulations!" icon={<CompleteIcon fill={theme.primary} />} />

        <div className="left">
          <p>Bank successfully connected.</p>
          <p>We will begin working on your request shortly.</p>
        </div>

        {isAuthenticated && (
          <Button
            variant="primary-green"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Logout
          </Button>
        )}
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    config: state.config,
    route_home: state.brand?.data?.route_home,
    user: state.user,
    loan_type: selector(state, 'loan_type'),
    theme: state.theme,
    first: selector(state, 'owner_1_first'),
    loan_id: selector(state, 'loan_id'),
    loan_amount: selector(state, 'loan_amount'),
  };
};

export const ContinuePlaidConnectOnlyContainer = connect(mapStateToProps, { setTemp })(
  withRouter(ContinuePlaidConnectOnly)
);

export default ContinuePlaidConnectOnlyContainer;
