import StorybookReduxWrapper from '../../../../.storybook/storybook-utils/StorybookWrapper';
import { App as Page } from '../../App';

// const meta: Meta<> = {
const meta = {
  title: '001-Apply Frontend/Entire App/Homepage',
  component: Page,
  argTypes: {},
  decorators: [
    StorybookReduxWrapper({
      routerProps: { initialEntries: ['/'] },
      user: {
        user: {
          loggedIn: true,
          id: '0037600000Pr6SdAAJ',
          accountId: '0017600000XFslZAAT',
          token:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMDE3NjAwMDAwWEZzbFpBQVQiLCJpYXQiOjE2ODI5NzIwMjAsImV4cCI6MTY4MzE0NDgyMH0.A1Ckqe6kT3D1iLLbYosbmZieD--LYcYZr9t1UvNpB94',
          expires: 33303137868000,
        },
        owners: [
          { mobile: '917-517-7576', last: 'Akdbn', id: '0037600000Pr6SdAAJ', first: 'Aksdb', email: 'test@test.com' },
        ],
        loan: false,
        error: false,
        business: {
          vendor_url: '',
          lead_source: '',
          business_years: 8,
          business_suite: '',
          business_revenue: '0',
          business_phone: '2345654323',
          business_name: 'Akdbs',
          business_id: '0017600000XFslZAAT',
        },
      },
    }),
  ],
};

export default meta;

export const Homepage = {
  args: {},
};
