import React from 'react';
import { Field } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { currency } from '_fields/normalizers';
import { parseCurrency } from '_fields/parsers';
import { required } from '_fields/validation';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

const Revenue = (props) => {
  return (
    <Stack gapVariant="head-section-to-content" className="slideWidth">
      <Helmet>
        <title>Annual Revenue - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="What is your annual gross revenue?" />

      <Stack gapVariant="input-to-input" alignItems="center" justifyItems="center" templateColumns="1fr">
        <Field
          name="business_revenue"
          component={newThemeInput}
          labelText="Annual Revenue*"
          parse={parseCurrency}
          validate={[required]}
          formatAsNumber
          autoFocus
          labelClassName="infoFormCustomLabel"
          data-testid="business_revenue"
        />
      </Stack>
    </Stack>
  );
};

export default Revenue;
