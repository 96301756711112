import StorybookReduxWrapper from '../../../../../.storybook/storybook-utils/StorybookWrapper';
import { App as Page } from '../../../App';

const meta = {
  title: '001-Apply Frontend/Entire App/steps/Step 3 - Basic Info',
  component: Page,
  argTypes: {},
  decorators: [StorybookReduxWrapper({ routerProps: { initialEntries: ['/app/info'] }, loanType: 'EQ' })],
};

export default meta;

export const Step3BasicInfo = {
  args: {},
};
