import './Bank.scss';
import React from 'react';
import { withRouter } from 'react-router-dom';
import BankConnect from '@components/Apply/Bank/Connect';
import BankUpload from '@components/Apply/Bank/Upload';
import BankEmail from '@components/Apply/Bank/Email';
import { useFlags } from 'launchdarkly-react-client-sdk';

let Bank = (props) => {
  const flags = useFlags();
  switch (props.match.params.property) {
    case 'connect':
      if (flags.plaidConnect || process.env.NODE_ENV === 'test' || window.IS_STORYBOOK === '1') {
        return <BankConnect {...props} />;
      } else {
        if (flags.applyStatementWebUploadEnabled || process.env.NODE_ENV === 'test' || window.IS_STORYBOOK === '1') {
          return <BankUpload {...props} />;
        } else {
          return <BankEmail {...props} />;
        }
      }
    case 'upload':
      if (flags.applyStatementWebUploadEnabled || process.env.NODE_ENV === 'test' || window.IS_STORYBOOK === '1') {
        return <BankUpload {...props} />;
      } else {
        return <BankEmail {...props} />;
      }
    case 'email':
      return <BankEmail {...props} />;
    default:
      return null;
  }
};

const BankContainer = withRouter(Bank);

export default BankContainer;
