import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field } from 'redux-form';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { getNaicsCodes } from '../../../../api/getNaicsCodes';
import { DataError } from '../../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { Loader, Stack } from 'shared-components';
import { selectDynamicInput } from '@components/_fields/inputs';
import { required } from '@components/_fields/validation';

// const preSortNaics = (list, naics) => {
//   list = Object.values(list);
//   if (naics) {
//     const idx = list.findIndex((obj) => obj.code === Number(naics));

//     if (idx !== -1) {
//       const selected = list[idx];
//       list.splice(idx, 1);
//       list.unshift(selected);
//     }
//   }

//   return list;
// };
// const results = preSortNaics(naicsResults || [], naics);

const Naics = ({ theme, naics, change }) => {
  const [isNaicsPreloaded, setIsNaicsPreloaded] = useState(false);
  const [{ data: naicsSearchResponse, loading: naicsSearchLoading, error: naicsSearchError }, triggerNaicsSearch] =
    getNaicsCodes();
  const naicsSearch = async (keyword) => {
    if (process.env.NODE_ENV === 'test') {
      return [{ value: '811198', text: '811198' }];
    }
    const naicsQueried = await triggerNaicsSearch(keyword);
    return naicsQueried.data?.map((naics) => {
      return {
        text: `(${naics.code}) ${naics.industry}`,
        value: naics.code?.toString(),
      };
    });
  };

  useEffect(() => {
    if (naicsSearchResponse) {
      if (!isNaicsPreloaded) {
        setIsNaicsPreloaded(true);
      }
    }
  }, [naicsSearchResponse]);

  useEffect(() => {
    const preLoadNaicsSearch = () => {
      naicsSearch(naics);
    };
    preLoadNaicsSearch();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      // TODO: dirty hack for unit test, as select is bugged in Jest environment.
      // TODO: not sure if needed after redesign (at this point tests werent updated yet)
      change('business_naics', '811198');
    }
  }, [naicsSearchResponse]);

  return (
    <Stack gapVariant="head-section-to-content" className="slideWidth">
      <Helmet>
        <title>Industry Code - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="Find Your Industry" addStandardMarginTop />
      <Stack gapVariant="input-to-input" justifyItems="center" templateColumns="1fr" fullWidth>
        {naicsSearchError && (
          <Alert severity="error">
            <AlertTitle>Failed search</AlertTitle>
            <DataError msg="Unfortunately, naics search failed. Please try again." error={naicsSearchError} />
          </Alert>
        )}
        {naics && !isNaicsPreloaded ? (
          <Loader />
        ) : (
          <Field
            name="business_naics"
            component={selectDynamicInput}
            queryOptions={naicsSearch}
            placeholderButton={'Select Industry Code'}
            placeholderInput="Search for Industry Code"
            popOverContentProps={{
              className: 'max-w-[368px]',
            }}
            buttonProps={{
              className: 'max-w-[368px] w-full overflow-hidden',
            }}
            commandEmptyProps={{
              children: 'No results found. Please try again.',
            }}
            defaultValue={naics ? naics : undefined}
            validate={[required]}
            commandItemProps={{
              onSelect: (item) => {
                if (!item) {
                  change('business_naics', '');
                  change('naics_keyword', '');
                }
              },
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const selector = formValueSelector('application');
const mapStateToProps = (state) => {
  return {
    naics: selector(state, 'business_naics'),
    keyword: selector(state, 'naics_keyword'),
  };
};

const NaicsWrapped = connect(mapStateToProps)(Naics);

export default NaicsWrapped;
