import type { Meta } from '@storybook/react';
import { Button } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Button/Pagination',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const PaginationActive = () => <Button variant="pagination-active">?</Button>;
export const PaginationInactive = () => <Button variant="pagination-inactive">?</Button>;
export const PaginationActiveExample2 = () => <Button variant="pagination-active">X</Button>;
export const PaginationInactiveExample2 = () => <Button variant="pagination-inactive">X</Button>;
export const PaginationActiveExample3 = () => <Button variant="pagination-active">1</Button>;
export const PaginationInactiveExample3 = () => <Button variant="pagination-inactive">2</Button>;
export const PaginationActiveExample4 = () => <Button variant="pagination-active">12</Button>;
export const PaginationInactiveExample4 = () => <Button variant="pagination-inactive">99</Button>;
