import type { Meta } from '@storybook/react';
import { Button } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Button/Steps',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const Completed = () => <Button variant="steps-completed">01</Button>;
export const Current = () => <Button variant="steps-current">02</Button>;
export const Incomplete = () => <Button variant="steps-incomplete">03</Button>;
export const Steps = () => (
  <div className="flex gap-4 space-2">
    <Button variant="steps-completed">01</Button>
    <Button variant="steps-completed">02</Button>
    <Button variant="steps-completed">03</Button>
    <Button variant="steps-current" disabled>
      04
    </Button>
    <Button variant="steps-incomplete">05</Button>
    <Button variant="steps-incomplete">06</Button>
    <Button variant="steps-incomplete">07</Button>
    <Button variant="steps-incomplete">08</Button>
  </div>
);
