import { RootState } from '@store/reducers';
import { formValueSelector } from 'redux-form';

const appSelector = formValueSelector('application');

export const MAX_SANE_NUMBER_OF_OWNERS = 20; // Anyone with more than 20 big owners is probably better to contact us directly

export const getNumberOfOwners = (state: RootState) => {
  const maxOwners = MAX_SANE_NUMBER_OF_OWNERS;
  let numberOfOwners = 1;

  for (let i = 1; i <= maxOwners; i++) {
    if (
      appSelector(state, `owner_${i}_id`) ||
      appSelector(state, `owner_${i}_street`) ||
      appSelector(state, `owner_${i}_suite`) ||
      appSelector(state, `owner_${i}_city`) ||
      appSelector(state, `owner_${i}_state`) ||
      appSelector(state, `owner_${i}_zip`) ||
      appSelector(state, `owner_${i}_first`) ||
      appSelector(state, `owner_${i}_last`) ||
      appSelector(state, `owner_${i}_ssn`) ||
      appSelector(state, `owner_${i}_mobile`) ||
      appSelector(state, `owner_${i}_dob_day`) ||
      appSelector(state, `owner_${i}_dob_month`) ||
      appSelector(state, `owner_${i}_dob_year`) ||
      appSelector(state, `owner_${i}_title`) ||
      appSelector(state, `owner_${i}_ownership`) ||
      appSelector(state, `owner_${i}_email`)
    ) {
      numberOfOwners = i;
    }
  }

  return numberOfOwners;
};

export const getNumberOfOwnersFromFormValues = (values: { [a: string]: any }) => {
  const maxOwners = MAX_SANE_NUMBER_OF_OWNERS;
  let numberOfOwners = 1;

  for (let i = 1; i <= maxOwners; i++) {
    if (
      values[`owner_${i}_id`] ||
      values[`owner_${i}_street`] ||
      values[`owner_${i}_suite`] ||
      values[`owner_${i}_city`] ||
      values[`owner_${i}_state`] ||
      values[`owner_${i}_zip`] ||
      values[`owner_${i}_first`] ||
      values[`owner_${i}_last`] ||
      values[`owner_${i}_ssn`] ||
      values[`owner_${i}_mobile`] ||
      values[`owner_${i}_dob_day`] ||
      values[`owner_${i}_dob_month`] ||
      values[`owner_${i}_dob_year`] ||
      values[`owner_${i}_title`] ||
      values[`owner_${i}_ownership`] ||
      values[`owner_${i}_email`]
    ) {
      numberOfOwners = i;
    }
  }

  return numberOfOwners;
};
