export const getTemp = (offersLoaded = true) => {
  return {
    offersLoaded: offersLoaded,
    offers: {
      loanOffers: [
        {
          uuid: '12',
          originator: {
            images: [{ url: 'https://media.tento.co/apply/partners/brands/md/logo.png' }],
            name: 'Lender 1',
            disclaimer: 'Lender 1 disclaimer text',
          },
          maxAmount: 5000,
          termLength: 36,
          termUnit: 'month',
          minApr: 5,
          aprType: 'fixed',
          url: 'https://lender1.com',
        },
        {
          uuid: '23',
          originator: {
            images: [{ url: 'https://media.tento.co/apply/partners/brands/ase/logo.png' }],
            name: 'Lender 1',
            disclaimer: 'Lender 2 disclaimer text test',
          },
          maxAmount: 5500,
          termLength: 24,
          termUnit: 'month',
          minApr: 5,
          aprType: 'fixed',
          url: 'https://lender1.com',
        },
      ],
    },
    pendingOffers: {
      pendingResponses: [
        {
          partner: {
            uuid: '1234',
            imageUrl: 'https://media.tento.co/apply/partners/brands/ase/logo.png',
          },
        },
        {
          partner: {
            uuid: '2345',
            imageUrl: 'https://media.tento.co/apply/partners/brands/md/logo.png',
          },
        },
        {
          partner: {
            uuid: '3456',
            imageUrl: 'https://media.tento.co/apply/partners/brands/farm/logo.png',
          },
        },
        {
          partner: {
            uuid: '4567',
            imageUrl: 'https://media.tento.co/apply/partners/brands/iron/logo.png',
          },
        },
      ],
    },
    offer: {
      loanType: 'eq',
      rate: 50000,
      terms: {
        12: {
          factor: 0.08,
          payments: 12,
        },
        24: {
          factor: 0.15,
          payments: 24,
        },
      },
      monthly: true,
    },
  };
};
