import React from 'react';
import { Spinner } from '../Spinner/Spinner';
import { contactEmail } from '../../utils/platformBasedInfo';

export const BrandLoadingScreen = ({ failed }) => (
  <div
    style={{
      display: 'flex',
      width: '100vw',
      flexDirection: 'column',
      marginTop: 64,
      paddingLeft: 200,
      paddingRight: 200,
      gap: 32,
      boxSizing: 'border-box',
    }}
  >
    {failed ? (
      <div>
        <span>
          We apologize for the inconvenience, but it seems we're experiencing difficulties loading the necessary
          configuration data for our application. Please ensure you have a stable internet connection and try again. If
          the issue persists, please contact our support team for further assistance at{' '}
        </span>
        <a href={`mailto:${contactEmail}`}>{contactEmail}.</a>
        <p>Thank you for your patience and understanding.</p>
      </div>
    ) : (
      <>
        <p>Loading brand...</p>
        <div style={{ margin: 'auto' }}>
          <Spinner />
        </div>
      </>
    )}
  </div>
);
