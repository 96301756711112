import React from 'react';
import { Field } from 'redux-form';
import { muiCustomSelect } from '_fields/inputs';
import { required } from '_fields/validation';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

const Structure = (props) => {
  const structure = ['Corporation', 'LLC', 'Partnership', 'Sole Proprietorship'];

  return (
    <Stack gapVariant="head-section-to-content" className="slideWidth">
      <Helmet>
        <title>Business Structure - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="How is your business structured?" />
      <Stack gapVariant="input-to-input" alignItems="center" justifyItems="center" templateColumns="1fr">
        <Field
          name="business_structure"
          component={muiCustomSelect}
          validate={[required]}
          data-testid={`structure_radio_input`}
          selectValueProps={{ placeholder: 'Select Structure' }}
          options={Object.values(structure).map((option) => ({ value: option, text: option }))}
        />
      </Stack>
    </Stack>
  );
};

export default Structure;
