import React, { FC } from 'react';
import { DealsType } from 'src/js/types';
import { currency } from '@components/_fields/normalizers';
import { APPLY_INCOMPLETE_STAGES, ApplicationCard, List, LoanStage, Stack, Text } from 'shared-components';

const padZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const PickDealTable: FC<{
  deals: DealsType[];
  onDealPicked: ({ id }: { id: DealsType['id'] }) => void;
  onNewDealPicked: () => void;
  isLoading: boolean;
}> = ({ deals, onDealPicked, isLoading, onNewDealPicked }) => {
  return (
    <>
      <List containerProps={{ className: 'justify-center' }} itemContainerProps={{ className: 'm-[5px]' }}>
        <ApplicationCard
          stage={LoanStage.ApplyStarted}
          number={undefined}
          label={''}
          amount="Start a new application"
          buttonProps={{
            onClick: () => onNewDealPicked(),
            variant: 'cta-form',
            className: 'bg-spark hover:bg-sparkHover',
          }}
          textBody={
            undefined
            // <Stack gapPx="10px" alignItems="center" justifyContent="center">
            //   <Text variant="body-general" className="text-center">
            //     Start a brand new loan application.
            //   </Text>
            // </Stack>
          }
        />
        {deals
          .filter((deal) => deal.stage === LoanStage.ApplyStarted)
          .map((deal, index) => (
            <ApplicationCard
              stage={deal.stage ?? 'ApplyStarted'}
              number={padZero(index + 1)}
              amount={currency(deal.amount)}
              label={deal.amount ? 'Amount' : 'Empty Application'}
              headlineProps={{
                className: 'mt-[0.8rem]',
              }}
              buttonProps={{
                onClick: () => onDealPicked(deal),
                isLoading: isLoading,
                ...(APPLY_INCOMPLETE_STAGES.includes(deal.stage ?? '')
                  ? {}
                  : { disabled: true, children: 'SUBMITTED' }),
                variant: 'cta-form',
                className: 'bg-spark hover:bg-sparkHover',
              }}
              textBody={
                <Stack gapPx="10px" alignItems="center" justifyContent="center">
                  {deal.type && (
                    <Text variant="body-general" className="text-center">
                      {deal.type === 'WC' ? 'Working Capital' : deal.type === 'EQ' ? 'Equipment Loan' : ''}
                    </Text>
                  )}
                  {deal.termLength && (
                    <Text variant="body-general" className="text-center">
                      {deal.termLength}{' '}
                      {deal.termUnit === 'month'
                        ? 'Month'
                        : deal.termUnit === 'year'
                        ? 'Year'
                        : deal.termUnit === 'day'
                        ? 'Day'
                        : 'Month'}{' '}
                      Term
                    </Text>
                  )}
                </Stack>
              }
            />
          ))}
      </List>
    </>
  );
};
