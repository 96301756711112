import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';
import { UseAxiosResult } from 'axios-hooks';
import { useEnrichWithCommonValues } from './enrichWithCommonValues';

export const ENDPOINT_PLAID_LINK_TOKEN = `${backendBaseUrl}/tools/plaid/link-token`;

export const plaidLinkToken = (): UseAxiosResult => {
  const { enrich } = useEnrichWithCommonValues();
  const [{ data, loading, error }, update] = useAxios(
    {
      url: ENDPOINT_PLAID_LINK_TOKEN,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );

  return [{ data, loading, error }, enrich(update), () => {}];
};
