import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { setTemp } from '@store/actions';
import { Button, Stack, StepCard } from 'shared-components';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { PlaidConnectCardContainer } from './PlaidConnectCard';
import { SkipConnectCard } from './SkipConnectCard';
import { useResponsiveBreakpoints } from 'shared-components';

const BankConnect = ({
  showProcessingModal,
  loan_id,
  loan_amount,
  first,
  vendor_name,
  callApi,
  dataLayerPush,
  setTemp,
  history,
  match,
  user,
  nextRoute,
  ...other
}) => {
  const responsiveBreakpoints = useResponsiveBreakpoints();
  return (
    <>
      <Helmet>
        <title>Connect Bank Account - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart
          headingText="Connect Your Bank Account"
          subHeadingText="To be able to give you the quickest pre-qualification, we'll need the last 4 months of bank statements from your business."
        />
        <Stack flow="column" gapPx="10px">
          <Stack alignItems="start" justifyContent={responsiveBreakpoints.isDesktop ? 'end' : 'center'}>
            <PlaidConnectCardContainer
              {...{
                showProcessingModal,
                loan_id,
                loan_amount,
                first,
                vendor_name,
                dataLayerPush,
                setTemp,
                history,
                match,
                user,
                callApi,
                nextRoute,
                ...other,
              }}
            />
          </Stack>
          {responsiveBreakpoints.isDesktop && (
            <Stack alignItems="start" justifyItems="start" justifyContent="start" className="relative">
              <div>
                <Button
                  variant="cta-small-primary"
                  className="absolute top-[140px] left-[-30px] p-0 w-[50px] h-[50px] z-10 font-condensed hover:bg-spark pointer-events-none"
                  disabled
                >
                  OR
                </Button>
              </div>
              <SkipConnectCard
                nextRoute={nextRoute}
                match={match}
                loan_id={loan_id}
                first={first}
                dataLayerPush={dataLayerPush}
                history={history}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    first: selector(state, 'owner_1_first'),
    loan_id: selector(state, 'loan_id'),
    loan_amount: selector(state, 'loan_amount'),
    theme: state.theme,
    user: state.user,
    vendor_name: state.brand?.data?.vendor_name,
    business_id: selector(state, 'business_id'),
    contact_id: selector(state, 'owner_1_id'),
    mobile: selector(state, 'owner_1_mobile'),
    email: selector(state, 'owner_1_email'),
  };
};
export const BankConnectContainer = connect(mapStateToProps, { setTemp })(withRouter(BankConnect));
