import type { Meta } from '@storybook/react';
import { Container } from 'shared-components';
import React, { useState } from 'react';
import { CardProps } from 'shared-components';
import { Button } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Container',
  component: Page as any,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const ContainerItem = () => {
  const [containerType, setContainerType] = useState<CardProps['type']>('default');
  const [gradientApply, setGradientApply] = useState<boolean>(false);
  return (
    <div className="w-[1140px] h-[824px]">
      <Container type={containerType} applyGradient={gradientApply}>
        {containerType !== 'current-spark' && (
          <Button
            variant="secondary"
            onClick={() => {
              setContainerType('current-spark');
            }}
          >
            Change To Current
          </Button>
        )}
        {containerType !== 'default' && (
          <Button
            variant="secondary"
            onClick={() => {
              setContainerType('default');
            }}
          >
            Change To Default
          </Button>
        )}
        {containerType !== 'completed-moss' && (
          <Button
            variant="secondary"
            onClick={() => {
              setContainerType('completed-moss');
            }}
          >
            Change To Completed
          </Button>
        )}
        <Button variant="primary-green" onClick={() => setGradientApply(!gradientApply)}>
          {gradientApply ? 'Remove Gradient' : 'Apply Gradient'}
        </Button>
      </Container>
    </div>
  );
};
export const Default = () => <Container type="default" withFaq={false} />;
export const Current = () => <Container type="current-spark" className="h-[300px]" withFaq={false} />;
export const CurrentFaq = () => (
  <Container
    type="current-spark"
    className="h-[300px]"
    withFaq
    faq={{
      heading: 'Frequently Asked Questions',
      questions: [
        {
          question: 'How does the application work?',
          answer:
            'There are no credit checks, lengthy paperwork, or personal guarantees. All you have to do is connect your bank information using Plaid or provide 4 months of bank statements through our secure portal, tell us a few details about yourself and your business and you’ll receive your offer within minutes.',
        },
        {
          question: 'How much capital can I access?',
          answer:
            'Up to 250k. In some cases more depending on your business performance. We offer a range of products to suit your business needs. Our team will work with you to find the best solution for your business.',
        },
        {
          question: 'What are the costs and fees to use Tento?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec',
        },
      ],
    }}
  />
);
export const Completed = () => <Container type="completed-moss" withFaq={false} />;
export const Gradient = () => <Container type="default" className="h-[300px]" withFaq={false} applyGradient />;
