import '@styles/styles.scss';
import '../../src/js/components/Apply/Decision/Decision.scss';
import React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { MemoryRouter as Router } from 'react-router-dom';
import { loadDefaultVendor } from '@store/actions';
import ErrorBoundary from '@components/_shared/Error/Error';
import { getStoreForStorybook } from '../../src/js/redux/store';
import { setUser } from '@store/actions';
import { change } from 'redux-form';
import { BrandLoadingScreen } from '../../src/js/components/Brand/BrandLoadingScreen';
import { setBrand } from '../../src/js/redux/actions';
import brandData from '../../src/js/api/mocks/brand.json';
import { setCssThemeVariables } from '../../src/js/utils/setCssThemeVariables';
import { Auth0MockProvider } from './MockAuth0Provider';

const StorybookReduxWrapperInner = ({ Story, props, user, loanType }) => {
  const dispatch = useDispatch();
  dispatch(setBrand(brandData));
  const brand = useSelector((state) => state.brand.data);

  dispatch(loadDefaultVendor());

  const loanTypeSafe = Array.isArray(loanType) ? loanType : loanType ? [loanType] : 'WC';
  dispatch(change('application', 'loan_type', loanTypeSafe));
  if (user) {
    dispatch(setUser(user));
  }
  if (brandData.theme) {
    setCssThemeVariables(brandData.theme);
  }

  return <>{brand?.vendor_id ? <Story /> : <BrandLoadingScreen />}</>;
};

// export const StorybookReduxWrapper = (Story: StoryFn, props: unknown) => {
export const StorybookReduxWrapper =
  ({ routerProps = {}, initialReduxState = {}, user = undefined, loanType }) =>
  (Story, props) => {
    window.IS_STORYBOOK = '1';

    return (
      <Auth0MockProvider>
        <Provider store={getStoreForStorybook(initialReduxState)} serverState={initialReduxState}>
          <Router basename="/apply" {...(routerProps || {})}>
            <ErrorBoundary>
              <StorybookReduxWrapperInner Story={Story} props={props} user={user} loanType={loanType} />
            </ErrorBoundary>
          </Router>
        </Provider>
      </Auth0MockProvider>
    );
  };

export default StorybookReduxWrapper;
