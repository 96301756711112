import React from 'react';

const CompleteIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" aria-labelledby="title">
      <title id="title">Complete Icon</title>
      <path
        fill={props.fill}
        d="M24.56,0A24.56,24.56,0,1,0,49.11,24.56,24.59,24.59,0,0,0,24.56,0Zm0,47.78A23.23,23.23,0,1,1,47.78,24.56,23.25,23.25,0,0,1,24.56,47.78Z"
      />
      <path
        fill={props.fill}
        d="M16.31,33c-1.1-.08-2.23-.18-2.46-1.5-.18-1.07.55-1.68,1.56-2.17-1.15-.21-2.27-.38-2.26-1.77s1-1.73,2.25-2.11L15,25.3a1.54,1.54,0,0,1-1-1.56,1.72,1.72,0,0,1,1.23-1.63,15.91,15.91,0,0,1,2.69-.46c.88-.09,1.77-.06,2.66-.14s1.05-.3.88-1.3a26.74,26.74,0,0,1-.57-4.52,5.5,5.5,0,0,1,.93-2.58,1.33,1.33,0,0,1,1.78-.48,1.31,1.31,0,0,1,.69,1.62,5.7,5.7,0,0,0,1,4.19A17.77,17.77,0,0,0,30,23.5a1.31,1.31,0,0,1,.59,1.17q0,4.21,0,8.43A1.28,1.28,0,0,1,30,34.32a15.65,15.65,0,0,1-7.91,2.28,27.77,27.77,0,0,1-4.74-.17,3,3,0,0,1-1.68-1.07C15.11,34.76,15.38,34.05,16.31,33Z"
      />
      <path fill={props.fill} d="M31.89,23.16H36V34.59H31.89Z" />
    </svg>
  );
};

export default CompleteIcon;
