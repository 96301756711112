import type { Meta } from '@storybook/react';
import { Slider } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Form/Slider',
  component: Page as any,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const SliderComponent = () => (
  <div className="w-[368px] min-h-[200px] p-[40px]">
    <Slider defaultValue={[50000]} max={250000} min={10000} step={1000} />
  </div>
);
