import { backendBaseUrl } from '@utils/platformBasedInfo';
import { useAxios } from './axiosInstance';
import { UseAxiosResult } from 'axios-hooks';

export const getNaicsCodes = (): UseAxiosResult => {
  const [{ data, loading, error }, update] = useAxios(
    {
      withCredentials: true,
      method: 'GET',
    },
    { manual: true }
  );

  return [
    { data, loading, error },
    (keyword) =>
      update({
        url: `${backendBaseUrl}/tools/naics?q=${keyword}`,
      }),
    () => {},
  ];
};
