import { ArrowRight } from '@components/_icons/ArrowRight';
import { cn } from '@utils/cn';
import React, { FC, PropsWithChildren } from 'react';
import { Link as RRLink } from 'react-router-dom';

export const Link: FC<
  PropsWithChildren<
    { to: string } & Omit<
      React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
      'ref'
    >
  >
> = ({ children, to, ...other }) => (
  <RRLink
    to={to}
    {...other}
    className={cn(
      'arrow-link text-black text-[18px] leading-[27px] font-normal no-underline hover:underline',
      other.className
    )}
    style={{
      color: 'var(--text-charcoal)',
      textDecoration: 'none',
      ...other.style,
    }}
  >
    {children} <ArrowRight />
  </RRLink>
);
