import React from 'react';
import { setTemp } from '@store/actions';
import { connect } from 'react-redux';

const Sign = ({ setTemp, history }) => {
  // grab the event returned from DocuSign
  const url = new URL(window.location.href);
  const event = url.searchParams.get('event');

  // save the event result for usage later in application
  setTemp({ signingResult: event });

  // route them to appropriate component
  if (event === 'signing_complete') {
    history.push('/app/agreement/upload');
  } else {
    history.push('/app/agreement/problem');
  }

  //return <p>event returned: {event}</p>
  return null;
};

const mapStateToProps = (state) => {
  return {
    temp: state.temp,
  };
};

export default connect(mapStateToProps, { setTemp })(Sign);
