export const calcEstimatedPayment = (amount, termLength, offer) => {
  if (offer.loanType === 'EQ') {
    return estPaymentByApr(amount, termLength, offer.rate);
  } else if (offer.loanType === 'WC') {
    return estPaymentByFactor(amount, termLength, offer);
  } else {
    return false;
  }
};

export const estPaymentByApr = (amount, termLength, rate) => {
  const monthlyRate = Number(rate) / 100 / 12;
  const term = Number(termLength);

  return (monthlyRate + monthlyRate / (Math.pow(1 + monthlyRate, term) - 1)) * amount;
};

export const estPaymentByFactor = (amount, termLength, offer) => {
  const factor = offer.terms[termLength].factor;
  const numPayments = offer.monthly ? Number(termLength) : offer.terms[termLength].payments;
  const payment = Number((amount * factor) / numPayments);
  const interest = (amount * factor - amount) / (numPayments * 7);

  return {
    factor,
    numPayments,
    payment,
    interest,
  };
};
