import { z } from 'zod';

// Regular expression to validate SSN structure and basic valid patterns.
const SSNPattern = /^(?:(?!000|666|9\d{2})\d{3}-(?!00)\d{2}|\*{3}-\*{2})-(?!0000)\d{4}$/;
const ForbiddenSSNs = [
  '123-45-6789',
  '987-65-4321',
  '000-00-0000',
  '123-12-3123',
  '111-22-3333',
  '999-88-7777',
  '234-56-7890',
  '098-76-5432',
  '111-11-1110',
  '111-11-1112',
  '111-11-1113',
  '111-11-1114',
  '111-11-1115',
  '111-11-1116',
  '111-11-1117',
  '111-11-1118',
  '111-11-1119',
  '222-22-2220',
  '222-22-2222',
  '222-22-2223',
  '222-22-2224',
  '222-22-2225',
  '222-22-2226',
  '222-22-2227',
  '222-22-2228',
  '222-22-2229',
  '333-33-3330',
  '333-33-3332',
  '333-33-3333',
  '333-33-3334',
  '333-33-3335',
  '333-33-3336',
  '333-33-3337',
  '333-33-3338',
  '333-33-3339',
];

export const SSNSchema = z.string().refine(
  (val) => {
    // Check against the regex for basic SSN format and forbidden patterns.
    if (!SSNPattern.test(val)) return false;
    if (ForbiddenSSNs.includes(val)) return false;

    // Further check to prevent SSNs that consist entirely of the same number.
    const sanitizedVal = val.replace(/-/g, ''); // Remove dashes for simplicity.
    const uniqueDigits = new Set(sanitizedVal.split(''));

    return uniqueDigits.size > 1; // There must be more than one unique digit.
  },
  {
    message: 'Invalid SSN: format, restricted patterns, or repeated digits.',
  }
);
