import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, withRouter, useLocation } from 'react-router-dom';
import ContinueByDealId from './ContinueByDealId.jsx';

const ContinueByDealIdContainerInner = ({ match, nextRoute, dispatch, config, user, route_home }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('le');
  const redirectTo = queryParams.get('next');
  const dealId = useParams()?.dealId;

  return <ContinueByDealId dealId={dealId} route_home={route_home} email={email} user={user} redirectTo={redirectTo} />;
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    route_home: state.brand?.data?.route_home,
    user: state.user,
  };
};

export const ContinueByDealIdContainer = connect(mapStateToProps)(withRouter(ContinueByDealIdContainerInner));

export default ContinueByDealIdContainer;
