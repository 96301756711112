import './Content.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { checkUser } from '@utils/reduxUtils';
import queryString from 'query-string';
import { setSocureDeviceId } from '../../redux/actions';
import devicer from '../../../../external-libs/devicer.min.js';
import { platformName } from '../../utils/platformBasedInfo';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@components/typography/Link';
import { currency } from '@components/_fields/normalizers';
import { Text, Stack, StepCard, Embed, Conduit, Growth, useResponsiveBreakpoints } from 'shared-components';
import { useHistory } from 'react-router-dom';
import { formValueSelector, reset } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useApplyWizardContext } from '../../context/ApplyWizardContext';
import { STEP_AMOUNT } from '@components/ApplyWizard/ApplyWizardSteps';

export const ApplyEngageExpand = ({ user, loanId, content }) => {
  const history = useHistory();
  const breakpoints = useResponsiveBreakpoints();
  const dispatch = useDispatch();
  const wizardContext = useApplyWizardContext();

  return (
    <Stack
      flow="column"
      gap={0}
      alignItems="center"
      justifyContent="center"
      templateColumns={breakpoints.isMobile ? '280px' : '230px 280px 230px'}
    >
      {!breakpoints.isMobile && (
        <StepCard
          type="completed"
          headline="Engage"
          number="01"
          textBody={content?.home?.boxOneText ?? 'Funding tailored directly to your business, within platform.'}
          icon={<Embed fill="var(--color-spark)" stroke="var(--color-charcoal)" />}
          mainContainerProps={{
            className: 'justify-between',
          }}
          cardProps={{
            size: 'small',
            className: 'border-none rounded-r-none !text-dawn',
            initial: { opacity: 0, top: '50px' },
            animate: { opacity: 1, top: 0 },
            transition: { duration: 0.5, delay: 1 },
          }}
          headlineProps={{ className: '!text-dawn' }}
          textBodyProps={{ className: 'text-dawn' }}
          numberProps={{ className: '!text-dawn' }}
        />
      )}
      <StepCard
        type="current"
        headline="Apply"
        number="02"
        textBody={content?.home?.boxTwoText ?? 'Ready to grow? Get instant access to funding.'}
        icon={<Conduit vector1Fill="var(--color-spark)" />}
        button="Apply Now"
        mainContainerProps={{
          className: 'justify-between',
        }}
        buttonProps={{
          className: 'font-condensed padding-[1rem]',
          onClick: () => {
            if (user.loggedIn && loanId) {
              // If user is logged in and has a loanId, we want to continue the existing application.
            } else {
              dispatch(reset('application'));
              dispatch(change('application', 'loan_amount', 150000));
              dispatch(change('application', 'loan_terms', 60));
              dispatch(change('application', 'owner_1_email', user.loggedIn ? user.email : ''));
              wizardContext.resetFarthestStep();
            }
            wizardContext.changeStepToFirst();
          },
        }}
        cardProps={{
          className: 'border-none py-[20px]',
          initial: { opacity: 0, top: '50px' },
          animate: { opacity: 1, top: 0 },
          transition: { duration: 0.5, delay: 1 },
        }}
        numberProps={{ style: { top: '24px' } }}
      />

      {!breakpoints.isMobile && (
        <StepCard
          type="completed"
          headline="Expand"
          number="03"
          textBody={content?.home?.boxThreeText ?? 'Grow your business with the resources you need.'}
          mainContainerProps={{
            className: 'justify-between',
          }}
          icon={
            <Growth
              vector1Fill="var(--color-spark)"
              vector1Stroke="var(--color-charcoal)"
              stroke="var(--color-bg-dawn-2)"
            />
          }
          cardProps={{
            size: 'small',
            className: 'border-none rounded-l-none !text-dawn',
            initial: { opacity: 0, top: '50px' },
            animate: { opacity: 1, top: 0 },
            transition: { duration: 0.5, delay: 1 },
          }}
          headlineProps={{ className: '!text-dawn' }}
          textBodyProps={{ className: 'text-dawn' }}
          numberProps={{ className: '!text-dawn' }}
        />
      )}
    </Stack>
  );
};

const Home = ({
  business_name,
  loanAmount,
  loanId,
  user,
  match,
  history,
  dispatch,
  location,
  theme,
  change,
  content,
}) => {
  const { logout } = useAuth0();

  useEffect(() => {
    if (location && location.search) {
      const query = location.search;
      // save ga_info
      const queryObj = JSON.parse(JSON.stringify(queryString.parse(query)));
      if (queryObj.hasOwnProperty('utm_source')) {
        change('application', 'ga_info', JSON.stringify(queryObj));
      }
    }

    checkUser(user, match, dispatch, history, logout);

    if (process.env.STORYBOOK_TEST) return;
    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        endpoint: process.env.SOCURE_URL,
        userConsent: true,
        context: 'homepage',
      };
      devicer.run(deviceFPOptions, function (response) {
        dispatch(setSocureDeviceId(response.sessionId));
      });
    }
  }, []);

  return (
    <Stack gapVariant="head-section-to-content" textAlign="center">
      <Stack gapVariant="headline-to-subheadline" textAlign="center">
        <div>
          <div className="max-w-[580px] mx-auto">
            <Text variant="headline-lg">
              {content?.home?.title ?? 'Financial Wellness Tools to Grow Your Business'}
            </Text>
          </div>
        </div>
        <div>
          <Text variant="subheadline-lg">
            {content?.home?.subtitle ??
              'Our mission is to save you time and give you the resources to grow your business with confidence.'}
          </Text>
        </div>
      </Stack>
      {business_name && (
        <Link to="/app/amount" className="hover:font-bold">
          <Text variant="cta-general" className="hover:font-bold">
            Continue application ({currency(loanAmount)})
          </Text>
        </Link>
      )}
      <ApplyEngageExpand user={user} loanId={loanId} content={content} />
    </Stack>
  );
};
const appSelector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    user: state.user,
    theme: state.theme,
    loanAmount: appSelector(state, 'loan_amount'),
    loanId: appSelector(state, 'loan_id'),
    businessName: appSelector(state, 'business_name'),
    content: state.brand?.data?.content,
  };
};

const HomeConnected = connect(mapStateToProps, (dispatch) => ({
  change,
  dispatch,
}))(Home);

export const HomeWrapped = withRouter(HomeConnected);

export default HomeWrapped;
