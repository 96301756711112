import { MAX_SANE_NUMBER_OF_OWNERS } from '@components/Apply/OwnersMoreDetailsAboutYou/getNumberOfOwners';
import { EntireDealFormattedResponse } from 'src/js/types';

const padZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const addOwnersToPost = ({
  post,
  form,
}: {
  post: EntireDealFormattedResponse;
  form: any;
}): EntireDealFormattedResponse => {
  const newPost = { ...post };

  const maxOwners = MAX_SANE_NUMBER_OF_OWNERS;

  if (!newPost.business) {
    newPost.business = {};
  }

  if (!newPost.business.contacts) {
    newPost.business.contacts = [];
  }

  for (let idx = 1; idx <= maxOwners; idx++) {
    if (form[`owner_${idx}_id`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].id = form[`owner_${idx}_id`];
    }
    if (form[`owner_${idx}_first`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].firstName = form[`owner_${idx}_first`];
    }
    if (form[`owner_${idx}_last`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].lastName = form[`owner_${idx}_last`];
    }
    if (form[`owner_${idx}_mobile`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].phoneMobile = form[`owner_${idx}_mobile`];
    }
    if (form[`owner_${idx}_email`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].email = form[`owner_${idx}_email`];
    }
    if (form[`owner_${idx}_dob`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].dob = form[`owner_${idx}_dob`];
    }

    if (form[`owner_${idx}_dob_day`] && form[`owner_${idx}_dob_month`] && form[`owner_${idx}_dob_year`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      const day = padZero(form[`owner_${idx}_dob_day`]);
      const month = padZero(form[`owner_${idx}_dob_month`]);
      const year = form[`owner_${idx}_dob_year`];
      const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
      newPost.business.contacts[idx - 1].dob = date.toISOString();
    }

    if (form[`owner_${idx}_title`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].title = form[`owner_${idx}_title`];
    }

    if (form[`owner_${idx}_ownership`]) {
      if (!newPost.business.contacts[idx - 1]) newPost.business.contacts[idx - 1] = {};
      newPost.business.contacts[idx - 1].ownerPercentage = Number(form[`owner_${idx}_ownership`]);
    }

    if (idx === 1 && form.business_street && form.home_based === 'true') {
      newPost.business.contacts[idx - 1].home_based = true;
      newPost.business.contacts[idx - 1] = {
        ...newPost.business.contacts[idx - 1],
        address: {
          address1: form.business_street,
          address2: form.business_suite,
          city: form.business_city,
          state: form.business_state,
          postalCode: form.business_zip,
        },
      };
    } else if (form[`owner_${idx}_street`]) {
      newPost.business.contacts[idx - 1] = {
        ...newPost.business.contacts[idx - 1],
        address: {
          address1: form[`owner_${idx}_street`],
          address2: form[`owner_${idx}_suite`],
          city: form[`owner_${idx}_city`],
          state: form[`owner_${idx}_state`],
          postalCode: form[`owner_${idx}_zip`],
        },
      };
    }

    if (form[`owner_${idx}_ssn`]) {
      newPost.business.contacts[idx - 1].ssn = form[`owner_${idx}_ssn`];
    }

    if (form[`owner_${idx}_dob`]) {
      newPost.business.contacts[idx - 1].dob = form[`owner_${idx}_dob`];
    }
  }
  return newPost;
};
