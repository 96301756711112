import React, { FC } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Toggle } from '_fields/inputs';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Helmet } from 'react-helmet';
import { Stack, Text } from 'shared-components';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import {
  ApplyWizardSteps,
  STEP_TYPE_OF_FUNDING,
  STEP_WHEN_FUNDING_NEEDED,
} from '@components/ApplyWizard/ApplyWizardSteps';
import { useForm } from 'react-hook-form';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

type TypeOfFundingProps = {
  handleSubmit: () => void;
  invalid: boolean;
  whenFundingNeededDefaultValue?: string;
  saving?: boolean;
};

const WhenFundingNeededWrapper2: FC<TypeOfFundingProps> = ({
  handleSubmit,
  invalid,
  whenFundingNeededDefaultValue,
  saving: savingOnSubmit,
}) => {
  const form = useForm();
  const { saving } = useApplyWizardContext();

  return (
    <div>
      <Helmet>
        <title>Aproximate Timing</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="When do you think you'll need funding?" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapPx="30px" justifyItems="center" templateColumns="1fr" fullWidth>
                <Text variant="subheadline-lg" className="text-center">
                  Aproximate Timing
                </Text>
                <Field
                  component={Toggle}
                  className="w1024:grid-cols-4"
                  name="when_funding_needed"
                  defaultValue={whenFundingNeededDefaultValue}
                  buttons={[
                    { text: `Immediately`, value: 'immediately' },
                    { text: `Within 10 days`, value: 'within-10-days' },
                    { text: `Within 1 month`, value: 'within-1-month' },
                    { text: `30+ days`, value: 'within-more-than-30-days' },
                  ]}
                />
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={invalid}
                  saving={saving || savingOnSubmit}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const WhenFundingNeededWrapper1 = reduxForm<TypeOfFundingProps, TypeOfFundingProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_WHEN_FUNDING_NEEDED].zodSchema),
})(WhenFundingNeededWrapper2);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    whenFundingNeededDefaultValue: appSelector(state, 'when_funding_needed'),
  };
};

export const WhenFundingNeeded = withOnSubmit(
  connect(mapStateToProps)(WhenFundingNeededWrapper1),
  STEP_WHEN_FUNDING_NEEDED
);
