import React from 'react';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Button, Stack } from 'shared-components';

const HomeBased = ({ change, nextRoute }) => {
  const setHomeBasedYes = () => {
    change('home_based', true);
    nextRoute();
  };

  const setHomeBasedNo = () => {
    change('home_based', false);
    nextRoute();
  };

  return (
    <Stack gapVariant="head-section-to-content" className="slideWidth">
      <Helmet>
        <title>Home based? - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="Is your business home based?" subHeadingText="" marginTop={30} />
      <div className="flex justify-center gap-[8px] home-based-button-wrap align-center">
        <Button type="button" variant="secondary" onClick={setHomeBasedYes} data-testid="home_based_yes">
          Yes
        </Button>

        <Button type="button" variant="secondary" onClick={setHomeBasedNo} data-testid="home_based_no">
          No
        </Button>
      </div>
    </Stack>
  );
};

export default HomeBased;
