import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { companyName, contactEmail } from '../../../utils/platformBasedInfo';
import { Helmet } from 'react-helmet';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from 'shared-components';

const Letter = (props) => {
  const { first, fico } = props;
  const todaysDate = new Date(Date.now()).toLocaleString().split(', ')[0];

  return (
    <div className="app-section slide decisionLetter">
      <Helmet>
        <title>Decision Denied - Equifax Score - Your Loan Application</title>
      </Helmet>
      <div className="decision left">
        <Stack gap={ELEMENTS_GAP} className="decision">
          <p>{todaysDate}</p>

          <p>
            <strong>Dear {first || 'Customer'},</strong>
          </p>
          <p>
            After very carefully reviewing the application, we are sorry to advise you that we cannot approve the loan
            request.
          </p>
          <p>
            The consumer reporting agency contacted that provided information that influenced our decision in whole or
            in part was:
          </p>
          <p>
            Equifax
            <br />
            P.O. Box 740256
            <br />
            Atlanta, GA 30374
            <br />
            866-640-2273
            <br />
            www.equifax.com
          </p>
          {fico && (
            <>
              <p>
                We also obtained your credit score from this consumer reporting agency and used it in making our credit
                decision. Your credit score is a number that reflects the information in your credit report. Your credit
                score can change, depending on how the information in your credit report changes.
              </p>
              <p>
                <strong>
                  Your credit score: {fico || '---'}
                  <br />
                  Date of Credit Report Pull: {todaysDate}
                  <br />
                  Scores range from 300 to 850
                </strong>
              </p>
            </>
          )}
          <p>
            The reporting agency played no part in our decision and is unable to supply specific reasons why we have
            denied credit to you. You have a right under the Fair Credit Reporting Act to know the information contained
            in your credit file at the consumer reporting agency. You also have a right to a free copy of your report
            from the reporting agency, if you request it no later than 60 days after you receive this notice. In
            addition, if you find that any information contained in a report you receive is inaccurate or incomplete,
            you have the right to dispute the matter with the reporting agency. Any questions regarding such information
            should be directed to Equifax.
          </p>
          <p>Please contact tento X Inc. at the address shown below if you have any questions about this notice:</p>
          <p>
            tento X Inc
            <br />
            ATTN: Marketing
            <br />
            1660 Hotel Circle N #215
            <br />
            San Diego CA 92108
            <br />
            Email: <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
          </p>
          <p>
            Notice: The federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit
            applicants on the basis of race, color, religion, national origin, sex, marital status, age (provided the
            applicant has the capacity to enter into a binding contract); because all or part of the applicant's income
            derives from any public assistance program; or because the applicant has in good faith exercised any right
            under the Consumer Credit Protection Act. The federal agency that administers compliance with this law
            concerning finance company creditors is the Federal Trade Commission, Equal Credit Opportunity, Washington,
            DC 20580.
          </p>
          <p>
            <strong>Ohio Applicants:</strong> The Ohio laws against discrimination require that all creditors make
            credit equally available to all creditors worthy customers, and that credit reporting agencies maintain
            separate credit histories on each individual upon request. The Ohio civil rights commission administers
            compliance with this law.
          </p>
          <p>
            <strong>
              Best regards,
              <br />
              The {capitalizeFirstLetter(companyName)} Team
            </strong>
          </p>

          <Link to="/app/decision/decline" className="pb-[20px]">
            View decision again
          </Link>
        </Stack>
      </div>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    first: selector(state, 'owner_1_first'),
  };
};

export default connect(mapStateToProps)(Letter);
