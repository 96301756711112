import type { Meta } from '@storybook/react';
import { Button } from 'shared-components';
import { TextAndButtonGroup } from 'shared-components';
import { ArrowRight as ArrowRightIcon } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Compositions/TextAndButtonGroup',
  component: Page as any,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const Add = () => (
  <TextAndButtonGroup buttonContent="+" text="Add additional owners?" onClick={() => alert('It Works!')} />
);
export const Remove = () => (
  <TextAndButtonGroup buttonContent="-" text="Remove owner" onClick={() => alert('It Works!')} />
);
export const Icon = () => (
  <TextAndButtonGroup
    buttonContent={<ArrowRightIcon className="h-[20px]" />}
    text="Go right"
    onClick={() => alert('It Works!')}
  />
);
