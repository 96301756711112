import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { ModalManager } from 'react-dynamic-modal';
import { DoneModal } from '@components/_shared/Modals/Modals';
import CompleteIcon from '_icons/CompleteIcon';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Button } from 'shared-components';

const Done = ({ loanType, temp, media, theme, dataLayerPush, ...other }) => {
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';
  const loanText = loanTypeSafe === 'EQ' ? 'equipment financing' : 'working capital';
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const getHeader = (loanType, signingResult) => {
    let loanTypeText;

    if (signingResult === 'signing_complete') {
      loanTypeText = 'signed loan agreement';
    } else {
      loanTypeText = loanType === 'EQ' ? 'equipment financing application' : 'working capital application';
    }

    return loanTypeText;
  };

  const getMessage = (loanType, haveStatements, signingResult) => {
    let message;

    if (signingResult === 'signing_complete') {
      message = 'We are processing your request and will be in touch regarding funding confirmation.';
    } else {
      if (loanType === 'WC' && haveStatements) {
        message =
          'Your application requires further review. This process typically takes 1-2 business days. We’ll reach out to you once more information is available. Thank you!';
      } else {
        message = 'We will begin working on your request shortly, and you will hear from us within 1-2 days.';
      }
    }

    return message;
  };

  useEffect(() => {
    const { haveStatements, signingResult } = temp;

    ModalManager.open(
      <DoneModal
        header={getHeader(loanTypeSafe, signingResult)}
        message={getMessage(loanTypeSafe, haveStatements, signingResult)}
        {...{ loanType: loanTypeSafe, temp, media, theme, dataLayerPush, ...other }}
      />
    );
    () => {
      ModalManager.close();
    };
  }, []);

  dataLayerPush('apply-done', {});
  analytics.track('Apply Form - Done', {});

  return (
    <div className="app-section slide">
      <Stack gap={ELEMENTS_GAP} className="decision">
        <FormPageTopPart headingText="Congratulations!" icon={<CompleteIcon fill={theme.primary} />} />

        <div className="left">
          <p>Your {loanText} application is complete!</p>
          <p>We will begin working on your request shortly, and you should expect to hear from us within 1-2 days.</p>
        </div>

        {isAuthenticated && (
          <Button
            variant="primary-white"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Logout
          </Button>
        )}
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    amount: selector(state, 'loan_amount'),
    loanType: selector(state, 'loan_type'),
    tib: selector(state, 'business_years'),
    theme: state.theme,
    temp: state.temp,
    media: state.brand?.data?.media,
  };
};

export default connect(mapStateToProps)(withRouter(Done));
