import { useAxios } from './axiosInstance';

export const getLoaneeDeal = () =>
  useAxios(
    {
      withCredentials: true,
      method: 'GET',
    },
    { manual: true }
  );
