/* eslint-disable react/no-unescaped-entities */
import React, { PropsWithChildren } from 'react';
import { Text } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Typography/CSS',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
};

export default meta;

export const HeadlineLarge = () => (
  <Text variant="headline-lg">
    color: var(--color-charcoal); <br />
    font-size: 45px; <br />
    font-weight: 400; <br />
    line-height: 50px; /* 111.111% */ <br />
    font-family: var(--font-extended); <br />
  </Text>
);
export const SubheadlineLarge = () => (
  <Text variant="subheadline-lg">
    --text-subheadline-lg-color: var(--color-charcoal); <br />
    --text-subheadline-lg-font-size: 30px; <br />
    --text-subheadline-lg-font-weight: 400; <br />
    --text-subheadline-lg-line-height: 35px; /* 116.667% */ <br />
    --text-subheadline-lg-font-family: var(--font-standard); <br />
  </Text>
);
export const HeadlineSmall = () => (
  <Text variant="headline-sm">
    --text-headline-sm-color: var(--color-charcoal); <br />
    --text-headline-sm-font-size: 30px; <br />
    --text-headline-sm-font-weight: 400; <br />
    --text-headline-sm-line-height: 35px; /* 116.667% */ <br />
    --text-headline-sm-font-family: var(--font-extended); <br />
  </Text>
);
export const SubheadlineSmall = () => (
  <Text variant="subheadline-sm">
    --text-subheadline-sm-color: var(--color-charcoal); <br />
    --text-subheadline-sm-alignment: center; <br />
    --text-subheadline-sm-font-size: 20px; <br />
    --text-subheadline-sm-font-weight: 400; <br />
    --text-subheadline-sm-line-height: 25px; /* 125% */ <br />
    --text-subheadline-sm-font-family: var(--font-standard); <br />
  </Text>
);
export const BodyGeneral = () => (
  <Text variant="body-general">
    --text-body-general-color: var(--color-charcoal); <br />
    --text-body-general-font-size: 18px; <br />
    --text-body-general-font-weight: 400; <br />
    --text-body-general-line-height: 22px; /* 122.222% */ <br />
    --text-body-general-font-family: var(--font-standard); <br />
  </Text>
);
export const DetailNumbers = () => (
  <Text variant="detail-numbers">
    --text-detail-numbers-color: var(--color-charcoal); <br />
    --text-detail-numbers-font-size: 16px; <br />
    --text-detail-numbers-font-weight: 400; <br />
    --text-detail-numbers-line-height: 18px; /* 112.5% */ <br />
    --text-detail-numbers-font-family: var(--font-condensed); <br />
  </Text>
);
export const LabelInfo = () => (
  <Text variant="label-info">
    --text-label-info-color: var(--color-charcoal); <br />
    --text-label-info-font-size: 18px; <br />
    --text-label-info-font-weight: 400; <br />
    --text-label-info-line-height: 22px; /* 122.222% */ <br />
    --text-label-info-font-family: var(--font-extended); <br />
  </Text>
);
export const BodyFormActive = () => (
  <Text variant="body-form-active">
    --text-body-form-active-color: var(--color-charcoal); <br />
    --text-body-form-active-font-size: 18px; <br />
    --text-body-form-active-font-weight: 400; <br />
    --text-body-form-active-line-height: 22px; /* 122.222% */ <br />
    --text-body-form-active-font-family: var(--font-standard); <br />
  </Text>
);
export const CtaGeneral = () => (
  <Text variant="cta-general">
    --text-cta-general-color: var(--color-charcoal); <br />
    --text-cta-general-font-size: 18px; <br />
    --text-cta-general-font-weight: 400; <br />
    --text-cta-general-line-height: 22px; /* 122.222% */ <br />
    --text-cta-general-font-family: var(--font-condensed); <br />
  </Text>
);
export const BodyFormInactive = () => (
  <Text variant="body-form-inactive">
    --text-body-form-inactive-color: var(--color-charcoal); <br />
    --text-body-form-inactive-font-size: 18px; <br />
    --text-body-form-inactive-font-weight: 400; <br />
    --text-body-form-inactive-font-style: italic; <br />
    --text-body-form-inactive-line-height: 22px; /* 122.222% */ <br />
    --text-body-form-inactive-font-family: var(--font-condensed); <br />
  </Text>
);
export const LabelNumbers = () => (
  <Text variant="label-numbers">
    --text-label-numbers-color: var(--color-charcoal); <br />
    --text-label-numbers-font-size: 16px; <br />
    --text-label-numbers-font-weight: 400; <br />
    --text-label-numbers-line-height: 18px; /* 112.5% */ <br />
    --text-label-numbers-font-family: var(--font-standard); <br />
  </Text>
);
export const CtaTextNavCard = () => (
  <Text variant="cta-nav-card" className="pr-8 uppercase">
    --text-cta-nav-card-color: var(--color-charcoal); <br />
    --text-cta-nav-card-font-size: 18px; <br />
    --text-cta-nav-card-font-weight: 400; <br />
    --text-cta-nav-card-line-height: 22px; /* 112.5% */ <br />
    --text-cta-nav-card-font-family: var(--font-condensed); <br />
  </Text>
);
