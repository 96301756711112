import type { Meta } from '@storybook/react';
import { ToggleGroup } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Compositions/Form/ButtonToggleGroup',
  component: Page,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const ButtonToggleGroup = () => (
  <ToggleGroup
    type="single"
    name="toggle-example"
    defaultValue="24"
    buttons={[
      { text: '6mo.', value: '6' },
      { text: '24mo.', value: '24' },
      { text: '32mo.', value: '32' },
    ]}
  />
);
