import React from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '_icons/CloseIcon';
import { FormPageTopPart } from '../FormPageTopPart/FormPageTopPart';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import { Paragraph } from '@components/typography/Paragraph';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Helmet } from 'react-helmet';

const Decline = (props) => {
  return (
    <div className="app-section slide">
      <Helmet>
        <title>Declined - Your Loan Application</title>
      </Helmet>
      <Stack gap={ELEMENTS_GAP} className="decision">
        <FormPageTopPart
          headingText="Declined"
          subHeadingText="Unfortunately, we are unable to approve your request for a loan at this time."
        />

        <Stack gap={ELEMENTS_GAP} className="left">
          <Paragraph>We consider a number of factors when evaluating loan applications.</Paragraph>

          <Paragraph>Unfortunately, your application was not a match with our criteria.</Paragraph>

          <Paragraph>
            We sincerely appreciate your interest in choosing tento X Inc as your lending provider, and we invite you to
            submit another application in the future, should circumstances change.
          </Paragraph>

          <Paragraph className="font-bold">Some reasons why we may decline an applicant include:</Paragraph>
          <Paragraph className="flex items-center text-left">
            <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
            Insufficient time in business for the product or amount requested
          </Paragraph>
          <Paragraph className="flex items-center text-left">
            <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
            Low FICO score
          </Paragraph>
          <Paragraph className="flex items-center text-left">
            <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
            Derogatory personal/business credit
          </Paragraph>
          <Paragraph className="flex items-center text-left">
            <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
            Inability to obtain a credit report
          </Paragraph>
          <Paragraph className="flex items-center text-left">
            <RadioButtonUncheckedIcon fontSize="small" className="mr-0.5" />
            Unable to verify identity
          </Paragraph>

          <Link to="/app/decision/denial-letter">More Info</Link>
        </Stack>
      </Stack>
    </div>
  );
};

export default Decline;
