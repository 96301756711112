import { reset } from 'redux-form';
import { logout, clearTemp, refreshUser } from '@store/actions';
import { loadDefaultVendor } from '@store/actions';

export const handleSignOut = (dispatch) => {
  dispatch(logout());
  dispatch(reset('application'));
  dispatch(reset('login'));
  dispatch(loadDefaultVendor(true));
  dispatch(clearTemp());
};

export const checkUser = (user, match, dispatch, history, logoutAuth0) => {
  const now = new Date();
  const day = 86400000; // milliseconds in a day;

  if (user.expires < now.valueOf()) {
    switch (match.url) {
      case '/':
      case '/app/amount':
      case '/app/equipment':
      case '/app/info':
        dispatch(logout());
        // User can be here not logged in anyway.
        break;
      default:
        dispatch(logout());
        logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
    }
  } else if (user.expires - now.valueOf() < day) {
    dispatch(refreshUser(user));
  }
};
