import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Address from '@components/_shared/Address/Address';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

let AddressBusiness = (props) => {
  const address = {
    street: props.street,
    suite: props.suite,
    city: props.city,
    state: props.state,
    zip: props.zip,
  };

  return (
    <Stack gapVariant="head-section-to-content" className="slideWidth">
      <Helmet>
        <title>Business Address - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart
        headingText="What is your business address?"
        subHeadingText="Look up address or enter manually"
      />
      <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
        <Address
          address={address}
          change={props.change}
          theme={props.theme}
          loanType={props.loanType}
          showDefaultManualAddress={address.city?.length > 0 && address.street?.length > 0 && address.state?.length > 0}
        />
      </Stack>
    </Stack>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state, ownProps) => {
  return {
    street: selector(state, 'business_street'),
    suite: selector(state, 'business_suite'),
    city: selector(state, 'business_city'),
    state: selector(state, 'business_state'),
    zip: selector(state, 'business_zip'),
  };
};

AddressBusiness = connect(mapStateToProps)(AddressBusiness);

export default AddressBusiness;
