import type { Meta } from '@storybook/react';
import { ApplyContainer } from 'shared-components';
import React, { useState } from 'react';
import { Button } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Compositions/ApplyContainer',
  component: Page as any,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const Apply = () => (
  <div className="bg-dawn flex flex-col">
    <div className="h-[68px] bg-dawn flex items-center justify-center">HEADER SPACE</div>
    <ApplyContainer
      withFaq
      faq={{
        heading: 'Frequently Asked Questions',
        questions: [
          {
            question: 'How does the application work?',
            answer:
              'There are no credit checks, lengthy paperwork, or personal guarantees. All you have to do is connect your bank information using Plaid or provide 4 months of bank statements through our secure portal, tell us a few details about yourself and your business and you’ll receive your offer within minutes.',
          },
          {
            question: 'How much capital can I access?',
            answer:
              'Up to 250k. In some cases more depending on your business performance. We offer a range of products to suit your business needs. Our team will work with you to find the best solution for your business.',
          },
          {
            question: 'What are the costs and fees to use Tento?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec',
          },
        ],
      }}
    >
      <div className="h-[500px]">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
          eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur.
        </div>
        <Button variant="cta-active">Random Button</Button>
        <div>
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </div>
      </div>
    </ApplyContainer>
  </div>
);
