import type { Meta } from '@storybook/react';
import { Button } from 'shared-components';
import { Card } from 'shared-components';
import { ArrowRight } from 'shared-components';
import { Stack } from 'shared-components';
import { Text } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Card',
  component: Page as any,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const Default = () => <Card type="default">Default</Card>;
export const Completed = () => <Card type="current-spark">Current Spark</Card>;
export const Current = () => <Card type="completed-moss">Completed Moss</Card>;
export const DefaultSmall = () => (
  <Card type="default" size="small">
    Default Small
  </Card>
);
export const CompletedSmall = () => (
  <Card type="current-spark" size="small">
    Current Spark Small
  </Card>
);
export const CurrentSmall = () => (
  <Card type="completed-moss" size="small">
    Completed Moss Small
  </Card>
);
export const Wide = () => (
  <Card size="wide" className="flex">
    <div className="flex flex-col justify-between w-full">
      <Stack flow="row" justifyContent="space-between" justifyItems="stretch">
        <Text variant="headline-sm">Ready to grow?</Text>
        <Text variant="headline-sm">Submit your application.</Text>
      </Stack>
      <div className="max-w-[415px]">
        <Text variant="body-general">
          Once submitted, your application will be reviewed and you will hear from us within 1-2 business days.
        </Text>
      </div>
      <Stack flow="column" justifyContent="space-between" justifyItems="stretch">
        <Button variant="primary-green">Primary</Button>
        <Button variant="cta-small-secondary" className="p-0 w-[50px] h-[50px]">
          <ArrowRight />
        </Button>
      </Stack>
    </div>
  </Card>
);
