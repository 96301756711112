import { EntireDealFormattedResponse } from 'src/js/types';
import { RefetchFunction, UseAxiosResult } from 'axios-hooks';
import { AxiosRequestConfig } from 'axios';
import { useGetApplicationIds } from './useGetApplicationIds';

export const useEnrichWithCommonValues = () => {
  const extraIds = useGetApplicationIds();
  return {
    enrich: (update: UseAxiosResult[1]) => {
      const execute: RefetchFunction<any, any> = async (additionalData) => {
        // Merging extraIds with additionalData
        if (typeof additionalData === 'object' && additionalData !== null) {
          const contacts =
            (additionalData as AxiosRequestConfig<any>).data?.business?.contacts ??
            (additionalData as AxiosRequestConfig<any>).data?.owners ??
            [];
          const mergedData: EntireDealFormattedResponse = {
            ...extraIds,
            ...((additionalData as AxiosRequestConfig<any>)?.data ?? {}),
            ...((additionalData as AxiosRequestConfig<any>)?.data?.loanRequest || extraIds?.loan_id
              ? {
                  loanRequest: {
                    ...(extraIds?.loan_id ? { id: extraIds?.loan_id } : {}),
                    ...((additionalData as AxiosRequestConfig<any>)?.data?.loanRequest ?? {}),
                  },
                }
              : {}),
            business:
              (contacts && contacts.length > 0) ||
              (extraIds?.business &&
                Object.keys(extraIds?.business).length > 0 &&
                Object.values(extraIds?.business).some((v) => !!v)) ||
              ((additionalData as AxiosRequestConfig<any>)?.data?.business &&
                Object.keys((additionalData as AxiosRequestConfig<any>)?.data?.business).length > 0)
                ? {
                    ...(extraIds?.business ?? {}),
                    ...((additionalData as AxiosRequestConfig<any>)?.data?.business ?? {}),
                    ...(contacts && contacts.length > 0
                      ? {
                          contacts:
                            (additionalData as AxiosRequestConfig<any>).data?.business?.contacts ??
                            (additionalData as AxiosRequestConfig<any>).data?.owners ??
                            [],
                        }
                      : {}),
                  }
                : undefined,
          };

          return await update({
            ...((additionalData as AxiosRequestConfig<any>) ?? {}),
            data: mergedData,
          });
        } else {
          return await update({
            data: extraIds,
          });
        }
      };
      return execute;
    },
  };
};
