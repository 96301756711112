import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { getLoaneeDeal } from '../../../api/getLoaneeDeal';
import { getLoaneeDeals } from '../../../api/getLoaneeDeals';
import { backendBaseUrl } from '../../../utils/platformBasedInfo';
import { Spinner } from '@components/Spinner/Spinner';
import { change } from 'redux-form';
import { prefillReduxStateFromDealResponse } from '@components/Apply/SelectApplication/prefillReduxStateFromDealResponse';
import { setUser } from '@store/actions';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

const QueryDealAndRedirectToForm = ({ deal, routes, config, redirectTo, reduxUserSaved }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { changeStepToFirstNotValid } = useApplyWizardContext();

  useEffect(() => {
    if (deal) {
      const accountId = deal?.business?.id;
      const dealId = deal?.id ?? deal?.loanRequest?.id;
      const data = {
        user: {
          ...reduxUserSaved,
          accountId: deal?.business?.id,
        },
      };
      prefillReduxStateFromDealResponse({
        dispatch,
        config,
        user: data.user,
        deal: deal,
      });
      dispatch(setUser(data));
      dispatch(change('application', 'business_id', accountId));
      dispatch(change('application', 'loan_id', dealId));

      if (redirectTo) {
        if (redirectTo === 'latest') {
          changeStepToFirstNotValid();
        } else {
          history.push(redirectTo);
        }
        return;
      }

      const index = routes.findIndex((route) => route.includes('verify/login'));
      if (index !== -1) {
        history.push(`/app/${routes[0]}`);
      } else {
        history.push(`/`);
      }
    }
  }, [deal, history]);

  return (
    <div className="app-section slide">
      <h1>Found application {deal?.id ?? deal?.loanRequest?.id}</h1>
      <div>Redirecting you to the form. Keep tight...</div>
      <Spinner />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    reduxUserSaved: state.user,
    finalInAppValues: state.form.application?.values,
    routes: state.brand?.data?.routes,
  };
};

export const QueryDealAndRedirectToFormContainer = connect(mapStateToProps)(QueryDealAndRedirectToForm);

const ContinueByDealIdWithUser = (props) => {
  const auth0 = useAuth0();
  const dealId = props.dealId;
  const [{ data: dealsResponse, loading: dealsLoading, error: dealsError }, getDeals] = getLoaneeDeals();
  const [{ data: entireDealDataResponse, loading: entireDealLoading, error: entireDealError }, getEntireDeal] =
    getLoaneeDeal();
  const redirectTo = props.redirectTo;

  useEffect(() => {
    getDeals();
  }, []);

  useEffect(() => {
    if (dealId === null || dealId === undefined) {
      return;
    }

    getEntireDeal({
      url: `${backendBaseUrl}/deals/loanee/${dealId}`,
    });
  }, [dealId]);
  if (dealsResponse && entireDealDataResponse && !entireDealError && !dealsError) {
    return <QueryDealAndRedirectToFormContainer deal={entireDealDataResponse} redirectTo={redirectTo} />;
  }
  if (dealsResponse && entireDealError) {
    return (
      <div className="app-section slide">
        <h1>Application {dealId}</h1>
        You cannot continue filling this application because you ({auth0.user.email}) don't have access to it.
      </div>
    );
  }
  if (!dealsResponse && dealsError) {
    return (
      <div className="app-section slide">
        There was a technical issue while trying to fetch the application. Please try again later.
      </div>
    );
  }

  return (
    <div className="app-section slide">
      <Spinner />
    </div>
  );
};

const ContinueByDealId = (props) => {
  const auth0 = useAuth0();
  const dealId = props.dealId;
  const email = props.email;
  const redirectTo = props.redirectTo;
  if (!dealId) {
    return <Redirect to={props.route_home} />;
  }
  if (!auth0.isLoading && auth0.isAuthenticated) {
    return <ContinueByDealIdWithUser {...props} />;
  }
  if (!auth0.isLoading && !auth0.isAuthenticated) {
    auth0.loginWithRedirect({
      authorizationParams: {
        login_hint: email ?? '',
      },
      appState: {
        returnTo: `/app/verify/login`,
        redirectTo: `/app/continue/${dealId}/?email=${email}&next=${redirectTo}`,
      },
    });
  }
  return null;
};

export default ContinueByDealId;
