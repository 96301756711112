import type { Meta } from '@storybook/react';
import { CheckboxButton } from 'shared-components';
import { Checkbox } from 'shared-components';
import { App as Page } from '../../App';

const meta = {
  title: '001-Apply Frontend/DesignSystemLibrary/Atoms/Form/Checkbox',
  component: Page as any,
  parameters: {
    layout: 'fullscreen',
  },
  tags: ['autodocs'],
} satisfies Meta<typeof Page>;

export default meta;

export const CheckboxButtonItem = () => <CheckboxButton name="checkbox-example" />;
export const CheckboxItem = () => <Checkbox name="checkbox-example">I agree to the terms and conditions</Checkbox>;
export const CheckboxChecked = () => (
  <Checkbox name="checkbox-example" checked>
    I agree to the terms and conditions
  </Checkbox>
);
