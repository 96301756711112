import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withRouter } from 'react-router-dom';
import convertKeysToCamelCase from '@utils/convertKeysToCamelCase';
import Owners from './Owners';
import './Owners.scss';
import devicer from '../../../../../external-libs/devicer.min.js';
import { backendBaseUrl, platformName } from '../../../utils/platformBasedInfo';

import { updateLoaneeDeal } from '../../../api/updateLoaneeDeal';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { maskSsn } from '@components/_fields/normalizers';

const OwnersContainer = ({ match, nextRoute, callApi, vendor_name, dispatch, dataLayerPush, loanType, ...other }) => {
  const [{ data: dealUpdateResponse, loading: dealUpdateLoading, error: dealUpdateError }, updateOpportunity] =
    updateLoaneeDeal();
  const [form, setForm] = useState({});
  useEffect(() => {
    if (process.env.STORYBOOK_TEST) return;

    if (platformName === 'tento') {
      var deviceFPOptions = {
        publicKey: process.env.SOCURE_PUBLIC_KEY,
        endpoint: process.env.SOCURE_URL,
        userConsent: true,
        context: 'transaction',
      };
      devicer.run(deviceFPOptions, function (response) {});
    }
  }, []);

  useEffect(() => {
    if (dealUpdateResponse) {
      const { idx, property } = match.params;
      // check for new owner id
      if (
        dealUpdateResponse?.contactIds?.length > 0 &&
        dealUpdateResponse.contactIds.find((c) => c.email === form[`owner_${idx}_email`])
      ) {
        // compose field key
        const fieldKey = `owner_${match.params.idx}_id`;
        // set new owner id
        dispatch(
          change(
            'application',
            fieldKey,
            dealUpdateResponse.contactIds.find((c) => c.email === form[`owner_${idx}_email`]).id
          )
        );
      }

      if (property === 'identity') {
        // purposefully using `form` object instead of pos t here
        dataLayerPush('submit-application', form);
        const segmentForm = convertKeysToCamelCase(form);
        analytics.track('Apply Form - Submit Application', segmentForm);
      }

      nextRoute();
    }
  }, [dealUpdateResponse]);

  const saveOwner = async (form) => {
    const { idx, property } = match.params;
    setForm(form);

    const post = {
      route: property,
      owners: [
        {
          id: form[`owner_${idx}_id`],
          contactId: form[`owner_${idx}_id`],
          main: Number(idx) === 1 ? true : false,
        },
      ],
    };

    switch (post.route) {
      case 'info':
        post.owners[0].firstName = form[`owner_${idx}_first`];
        post.owners[0].lastName = form[`owner_${idx}_last`];
        post.owners[0].phoneMobile = form[`owner_${idx}_mobile`];
        post.owners[0].email = form[`owner_${idx}_email`];
        break;

      case 'ownership':
        post.owners[0].title = form[`owner_${idx}_title`];
        post.owners[0].ownerPercentage = Number(form[`owner_${idx}_ownership`]);
        if (idx === '1') {
          post.owners[0].home_based = form.home_based;
          post.owners[0] = {
            ...post.owners[0],
            address: {
              address1: form.business_street,
              address2: form.business_suite,
              city: form.business_city,
              state: form.business_state,
              postalCode: form.business_zip,
            },
          };
        }
        break;

      case 'address':
        post.owners[0] = {
          ...post.owners[0],
          address1: form[`owner_${idx}_street`],
          address2: form[`owner_${idx}_suite`],
          city: form[`owner_${idx}_city`],
          state: form[`owner_${idx}_state`],
          postalCode: form[`owner_${idx}_zip`],
        };
        break;

      case 'identity':
        post.owners[0].ssn = form[`owner_${idx}_ssn`];
        post.owners[0].dob = form[`owner_${idx}_dob`];
        // used to load credit decision and determine if we should skip credit check
        post.owners[0].loan_type = form.loan_type;
        post.owners[0].amount = form.loan_amount;
        break;

      case 'add':
        nextRoute();
        return;
    }

    await updateOpportunity({ data: post });
    if (post.route === 'identity') {
      dispatch(change('application', `owner_${idx}_ssn`, maskSsn(form[`owner_${idx}_ssn`], true, true)));
    }

    const pageEventProps = {
      brand: {
        brandId: props?.brand_params?.id || '',
        brandName: props?.brand_params?.name || '',
        partnerId: props?.brand_params?.partner_id || '',
        partnerName: props?.brand_params?.partner_name || '',
      },
    };

    const segmentPost = convertKeysToCamelCase(post);
    analytics.track(`Apply Form - Owners ${property}`, { ...segmentPost, ...pageEventProps });
  };

  return (
    <>
      {dealUpdateError && (
        <Alert severity="error">
          <AlertTitle>Failed to save</AlertTitle>
          <DataError msg="Unfortunately, we couldn't save your update. Please try again" error={dealUpdateError} />
        </Alert>
      )}
      <Owners
        onSubmit={saveOwner}
        loanType={loanType}
        {...{
          match,
          nextRoute,
          callApi,
          vendor_name,
          dispatch,
          dataLayerPush,
          loanType,
          ...other,
          saving: other?.saving || dealUpdateLoading,
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    vendor_name: state.brand?.data?.vendor_name,
  };
};

const OwnersWrapped = connect(mapStateToProps)(withRouter(OwnersContainer));

export default OwnersWrapped;
