import { backendBaseUrl } from '../utils/platformBasedInfo';
import { useAxios } from './axiosInstance';

export const ENDPOINT_POST_CONTACT = `${backendBaseUrl}/contacts`;

export const postLoaneeContact = () =>
  useAxios(
    {
      url: ENDPOINT_POST_CONTACT,
      withCredentials: true,
      method: 'POST',
    },
    { manual: true }
  );
