import React, { FC } from 'react';
import { reduxForm, Field } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import { normFedTaxId } from '_fields/normalizers';
import { currency } from '_fields/normalizers';
import { parseCurrency } from '_fields/parsers';
import { ApplyWizardSteps, STEP_BUSINESS_FURTHER_DETAILS } from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';

type FurtherBusinessDetailsProps = {
  handleSubmit: () => void;
  invalid: boolean;
  years?: number;
  saving?: boolean;
};

const FurtherBusinessDetailsWrapper2: FC<FurtherBusinessDetailsProps> = ({
  handleSubmit,
  invalid,
  saving: savingOnSubmit,
}) => {
  const form = useForm();
  const { saving } = useApplyWizardContext();

  return (
    <div>
      <Helmet>
        <title>Further Business Details</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="Further Business Details" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  data-testid="fed-tax-id"
                  name="business_ein"
                  component={newThemeInput}
                  type="tel"
                  label="Tax ID"
                  placeholder="Federal Tax ID* (XX-XXXXXXX)"
                  formatAsFederalTaxId
                  autoFocus
                />
                <Field
                  name="business_revenue"
                  component={newThemeInput}
                  labelText="Annual Gross Revenue*"
                  parse={parseCurrency}
                  formatAsNumber
                  labelClassName="infoFormCustomLabel"
                  data-testid="business_revenue"
                />
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={invalid}
                  saving={saving || savingOnSubmit}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const FurtherBusinessDetailsWrapper1 = reduxForm<FurtherBusinessDetailsProps, FurtherBusinessDetailsProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_BUSINESS_FURTHER_DETAILS].zodSchema),
})(FurtherBusinessDetailsWrapper2);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    years: appSelector(state, 'business_years'),
  };
};

export const FurtherBusinessDetails = withOnSubmit(
  connect(mapStateToProps)(FurtherBusinessDetailsWrapper1),
  STEP_BUSINESS_FURTHER_DETAILS
);
